import { Suspense, useEffect, useRef,useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ClientPage from "./Components/Questionnair/ClientPage";
import { ToastContainer } from "react-toastify";
import AdminPage from "./Components/AdminPage/AdminPage";
import AccessDenied from "./Components/AccessDenied";
import axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { baseURL, urls } from "./ConfigFiles/Urls";
import { updateMessages } from "./Redux/Action";
import { useDispatch, useSelector } from "react-redux";


const App = () => {
  
  const [localTime, setLocalTime] = useState(new Date());
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const alldata = useRef(null);
  alldata.current = messages;

  const loggedInUserId = useRef(null);
  const dateTime=useRef()
  dateTime.current=localTime
  loggedInUserId.current = sessionStorage?.getItem("userId");
  
  const chartGetById = async () => {
    let jsonObjects = { userId: sessionStorage?.getItem("userId") };
    let headers = { "Content-Type": "application/json", Authorization: "Bearer " + sessionStorage.getItem("token")};

    try {
      let res = await axios.post(baseURL + urls?.chat?.chatGetById,{ jsonObjects },{ headers });
      if (res?.data?.status == true) {
        dispatch(updateMessages(res.data.data || []));
      } else {
        dispatch(updateMessages([]));
      }
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    sessionStorage?.getItem("userId")> 0 && chartGetById()
  }, [loggedInUserId.current]);



  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(new Date());
    }, 1000);

    return  () =>sessionStorage.getItem("userId")&& clearInterval(interval);
  }, []);
 
  useEffect(() => {
    if (sessionStorage?.getItem("userId") > 0) {
     
      let connection = new HubConnectionBuilder().withUrl("http://allistercareapi.hitechhealth.us/chatHub").build();
          connection.start().then(function () {
          connection.on("ReceiveChat", (chatMessage) => {
        
          chatMessage?.map((v)=>{
            v["createdDate"]=dateTime?.current
          })
      
            if (chatMessage?.[0]?.toUser == sessionStorage?.getItem("userId")) {
              const updatedChat = [...alldata?.current];
              updatedChat.push(chatMessage[0]);
              dispatch(updateMessages(updatedChat));
            }
           });
        })
        .catch(function (err) { return err } );
    }
    
  }, [loggedInUserId.current]);


  
  useEffect(() => {
    let timeoutId;
    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
       
        
        sessionStorage.clear()
        localStorage.clear()
      },  30* 60 *1000); // 30 min time out
    };
   
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keypress', resetTimeout);
   
   sessionStorage.getItem("userId")&& resetTimeout();
   
    return () => {
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keypress', resetTimeout);
    };
  }, []);


  return (
    <>
    
      <ToastContainer />
      <Suspense fallback={() => <div>Loading ....</div>}>
        <Router>
          <Routes>
            <Route path="/*" element={<ClientPage />}></Route>
            <Route path="/admin/*" element={<AdminPage />} />
            <Route path="/accessdeniedpage" element={<AccessDenied />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
