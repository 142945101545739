import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { useFormValidation } from "../../Validations/useFormValidations";
import {
  disableFun,
  getList,
  mulitplePhoneNumberValue,
  save,
} from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";

const PatientsDetailsEditModal = (props) => {
  const [carrierList, setCarrierList] = useState([]);
  const [physicianList, setPhysicianList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
 

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  const submit = async () => {
    data["userId"] = props?.show;

    let body = {
      screeningId: props?.show?.screeningId,
     
      roleId: props?.show?.roleId,
      facilityId: data.facilityId,
      physicianId: data.physicianId,
      carrierId: data.carrierId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipCode: data?.zipCode,
      email: data?.email,
      NCDate: data?.NCDate,
      description: data?.description,
      phone: data?.phone,
    };
    let jsonObjects = body;
    let res = await save(urls?.signup?.clientSignUp, { jsonObjects });
    if (res?.data?.status) {
      props?.updateList(res);
      props?.onHide();
    }
  };

  const getCarrierList = async () => {
    let jsonObjects = {
      facilityId: data?.facilityId,
      type: "Carrier",
    };
    let res = await getList(urls?.screening?.getFacilityDropDown, {
      jsonObjects,
    });

    setCarrierList(res?.data?.data);
  };
  const getPhysicianList = async () => {
    let jsonObjects = {
      facilityId: data?.facilityId,
      type: "Physician",
    };
    let res = await getList(urls?.screening?.getFacilityDropDown, {
      jsonObjects,
    });
    setPhysicianList(res?.data?.data);
  };

  const getFacilityList = async () => {
    let jsonObjects = {
      facilityId: data?.facilityId,
      type: "Facility",
    };
    let res = await getList(urls?.screening?.getFacilityDropDown, {
      jsonObjects,
    });
    setFacilityList(res?.data?.data);
  };

  const {
    data,
    errors,
    handleChange,
    handleSubmit,
    handlePhoneChange,
    handleEmailChange,
    addObject,
    handleDateChange,
    handleCapitalChange,
    handleNumberChange,
  } = useFormValidation({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: {
      email: {
        required: {
          value: true,
          message: "Please enter your email id",
        },
        pattern: {
          value: /(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

          message: "Please enter a valid email id",
        },
      },
      firstName: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
      lastName: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
    },
    submit: submit,
  });

  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };

  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "form-control bg-white border border-danger"
      : "form-control border bg-white ";
  };

  useEffect(() => {
    if (data?.facilityId && data?.facilityId != 0) {
      getPhysicianList();
      getCarrierList();
      getFacilityList();
    }
  }, [data?.facilityId]);

  useEffect(() => {
    let obj = Object.assign(props?.show, props?.show?.answers[0]);

    addObject(obj);
  }, [props?.show]);

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div id={"div"} style={{ marginBottom: "1rem" }}>
        <Modal.Header style={{ background: "#F7F7F7" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Patient Details
          </Modal.Title>

          <div className="d-flex">
            
            <div className="pointer" onClick={props?.onHide}>
              <RxCross2 size={25} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row m-1">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label className="">
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  className={emailErrorColor("firstName")}
                  placeholder="First Name"
                  name="firstName"
                  value={dataValue(data?.firstName)}
                  onChange={handleCapitalChange("firstName")}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label className="">
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  className={emailErrorColor("lastName")}
                  placeholder="Last Name"
                  name="lastName"
                  value={dataValue(data?.lastName)}
                  onChange={handleCapitalChange("lastName")}
                />
              </div>
              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                <label className="">
                  Email<span className="text-danger">*</span>
                </label>
                <br />
                <input
                  type="mail"
                  className={emailErrorColor("email")}
                  placeholder="Email"
                  name="email"
                  disabled
                  onChange={handleEmailChange("email")}
                  value={dataValue(data?.email)}
                />
              </div>
              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                <label className="">Phone</label>
                <br />
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Phone"
                  name="phone"
                  onChange={handlePhoneChange("phone")}
                  // value={dataValue(data?.phone)}
                  value={mulitplePhoneNumberValue(data?.phone)}
                />
              </div>

              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                <label className="">Zip</label>
                <br />
                <input
                  typeof="text"
                  onChange={handleNumberChange("zipCode")}
                  name="zipCode"
                  value={dataValue(data.zipCode)}
                  type="text"
                  maxLength={5}
                  className="form-control bg-white"
                  placeholder="Zip"
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <label className="">Last Colonoscopy</label>

                <div className="row">
                  <select
                   
                    disabled="true"
                    className={
                      disableFun("2")
                        ? "col form-select ms-3 p-2 "
                        : " col form-select ms-3 p-2 "
                    }
                    placeholder="LC month"
                    value={data?.month1}
                    name="month1"
                    onChange={handleChange("month1")}
                  >
                    <option>Select</option>
                    {months?.map((v) => {
                      return <option value={v}>{v} </option>;
                    })}
                  </select>
                  <select
                    
                    disabled="true"
                    className=" col form-select border  rounded ms-2 p-2"
                    placeholder="LC month"
                    value={data?.completecolonoscopyperformed}
                    name="completecolonoscopyperformed"
                    onChange={handleChange("completecolonoscopyperformed")}
                  >
                    <option>Select</option>
                    {years?.map((v) => {
                      return <option value={v}>{v} </option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label className="">Next Colonoscopy</label>
                <DatePicker
                 
                  disabled="true"
                  className={
                    disableFun("2")
                      ? "form-control "
                      : "form-control "
                  }
                  selected={data?.submittedDate&&data?.submittedDate!="-"&&data?.NCDate&&data?.NCDate!="-"? new Date(data?.NCDate) : ""}
                  onChange={(e) => {
                    handleDateChange(e, "NCDate");
                  }}
                  autoComplete="off"
                  name="NCDate"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  popperClassName="react-datepicker-popper"
                  showMonthDropdown
                  showYearDropdown
                  style={{ fontSize: "20px" }}
                  dropdownMode="select"
                />
              </div>




              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                <label className="">Select Facility</label>
                <select
                  className="form-select"
                  value={dataValue(data.facilityId)}
                  name="FacilityId"
                  disabled={disableFun("2", "3")}
                  onChange={handleChange("facilityId")}
                >
                  <option value={""}>Select Facility</option>
                  {facilityList &&
                    facilityList?.length > 0 &&
                    facilityList?.map((e, i) => {
                      return (
                        <option key={i} value={e.FacilityId}>
                          {e.facilityName}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                <label className="">Select Carrier </label>
                <select
                  className="form-select"
                  value={dataValue(data.carrierId)}
                  name="carrierId"
                  // disabled={disableFun("2")}
                  onChange={handleChange("carrierId")}
                >
                  <option value={""}>Select Carrier</option>
                  {carrierList &&
                    carrierList?.length > 0 &&
                    carrierList?.map((e, i) => {
                      return (
                        <option key={i} value={e.carrierId}>
                          {e.carrierName}
                        </option>
                      );
                    })}
                </select>
              </div>
              {sessionStorage.getItem("roleId") !== "2" && (
                <div className="col-xl-6 col-lg-6  col-md-6 col-sm-12">
                  <label className="">Select Physician </label>

                  <select
                    className="form-select"
                    value={dataValue(data.physicianId)}
                    name="physicianId"
                    disabled={disableFun("2")}
                    onChange={handleChange("physicianId")}
                  >
                    <option value={""}>Select Physician</option>
                    {physicianList &&
                      physicianList?.length > 0 &&
                      physicianList?.map((e, i) => {
                        return (
                          <option key={i} value={e.physicianId}>
                            {e.physicianName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

              {/* <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <label>Description</label>
              <textarea
                className="form-control bg-white"
                placeholder="Description"
                name="description"
                type="text"
                value={dataValue(data.description)}
                onChange={handleChange("description")}
              />
            </div> */}
            </div>
            <hr className="col-md-12 border" />
            <div className="d-flex justify-content-end">
              <button className="float-right border border-white btn-save">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PatientsDetailsEditModal;
