import React from 'react'
import {  logOutFunction } from '../../ConfigFiles/SharedFunctions'

export const AdminAuthentication = (props) => {
    let token=sessionStorage.getItem("token")
    return token!=null?props?.children:logOutFunction()
}



// export const AdminAuthentication = (props) => {
//     let token=sessionStorage.getItem("token")
//     return token!=null?props?.children:logOutFunction()
// }

