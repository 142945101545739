import React,{useEffect, useRef, useState,useLayoutEffect} from 'react'
import { FaRegUserCircle } from 'react-icons/fa'
import { chatServices, countmsg, disableFun, getList, notify } from '../ConfigFiles/SharedFunctions'
import { urls } from '../ConfigFiles/Urls'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { updateMessages } from '../Redux/Action'
import { useFormValidation } from '../Validations/useFormValidations'
import { BiSend } from 'react-icons/bi'
import ScrollToBottom from "react-scroll-to-bottom";
import { IoArrowBackOutline } from "react-icons/io5";
const MobileChat = ({chatuser,submit,userId,userName,client,setid,setSearch}) => {
    const dispatch = useDispatch();
    let messages = useSelector((state) => state.messages);
   
    
    const chatContainerRef = useRef(null);
  
      const sentMessage = async () => {
        let jsonObjects = {
          chatDescription: data?.message,
          fromUser: sessionStorage.getItem("userId"),
          toUser: userId,
        };
        if (userId && userId != undefined) {
          try {
            if (data?.message != undefined && data?.message != "") {
              let res = await chatServices(urls?.chat?.saveChat, jsonObjects);
              if (res?.data?.status == true) {
                const updatedChat = [
                  ...messages?.map((e) => ({
                    ...e,
                    isRead: `${userId == e?.fromUser &&
                      sessionStorage.getItem("userId") == e?.toUser
                      ? "Yes"
                      : e?.isRead
                      }`,
                  })),
                ];
                updatedChat.push(jsonObjects);
                dispatch(updateMessages(updatedChat));
                setV({ message: "" });
              }
            } else {
              return [];
            }
          } catch (e) {
            return [];
          }
        } else {
          
          const roleId = sessionStorage.getItem("roleId");
          if (roleId === "2") {
            notify(false, "Please select a facility admin");
          } else if (roleId === "3") {
            notify(false, "Please select a patient");
          } else {
            notify(false, "Please select patient or facility admin");
          }
        }
      }
      const { data, handleChange, handleSubmit, setV } = useFormValidation({
        
        submit: sentMessage
      });
      
     
    
      const scrollToBottom = () => {
        if (chatContainerRef.current) {
          const chatContainer = chatContainerRef.current;
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      };
    
      useLayoutEffect(() => {
        scrollToBottom();
      }, [messages]);
      
  return (
    <div className="p-2 px-3 bg-light marginTop">
      
     
      { userId==""&&
  <div className='position-sticky fixed-top  bg-light mt-2 px-1' style={{ zIndex: "10" }}>
  <h5  className='ms-1 'style={{marginTop: "13px"}}>Chat</h5>
      <input
        type="search"
        className="col-md-12  ms-1 form-control bg-white  bg-white mb-2" 
        placeholder={ disableFun("3") ? "Search Patients" : "Search Facility"}
        onChange={(e) => setSearch(e.target?.value)}
      />
      
      </div>
      }
       
       {

       userId==""&& chatuser&&chatuser?.length>0&&chatuser?.map((v)=>{
        
            return (
              <>
              
                <div className="border p-3 rounded d-flex  justify-content-between pointer bg-light"onClick={() => client(v)}>
                
                <div>
                  <label className="font-weight-bold custom-label">
                    <FaRegUserCircle className="spinprofile me-1 " size={30} />
                    {v?.userName}
                  </label>
                  {/* <p className="mb-0 px-4">{messages?.filter((v)=>v?.isRead=="No").slice(-1)[0]?.chatDescription}</p> */}
                </div>
                <div className=" align-items-center">
                <span className="text-muted" style={{ fontSize: "12px" }}>
                   
                    {/* {messages?.filter((v)=>v?.isRead=="No").slice(-1)[0]?.createdDate&&moment(new Date(messages?.filter((v)=>v?.isRead=="No").slice(-1)[0]?.createdDate)).format("hh:mm A")} */}
                  </span>
{              countmsg(messages, v?.userId)&&    <span
                    className="bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: "25px", height: "25px", marginLeft: "5px" }}
                  >
                    <span style={{ fontSize: "10px" }}>{countmsg(messages, v?.userId)}</span>
                  </span>}
                </div>
              </div>
              </>
            )
        })
       }
        {userId&&
        (
            <>
            <div className='pointer bg-light p-1'><IoArrowBackOutline onClick={()=>setid("")} size={20}/> <span className='text_Color'><FaRegUserCircle className="spinprofile me-1 " size={30}/>{userName}</span></div>
            <ScrollToBottom className='' >
        <div  ref={chatContainerRef}
                     className=''
                     style={{ maxHeight: "490px", overflowY:"auto" }}
                     >
                     {userId && messages?.filter((v) =>
                       v?.fromUser == userId || v?.toUser == userId
                     )
                       ?.map((v, index) => {
                         return (
                           <>
                             <div
                               className={` d-flex gap-2 m-1 ${v?.fromUser == userId
                                 ? "justify-content-start"
                                 : " justify-content-end "
                                 } `}
                             >
                               <div
                                 key={index}
                                 className={
                                   sessionStorage.getItem("userId") !=
                                     v?.fromUser
                                     ? " border bg-white px-1 "
                                     : "btn-chat   px-1"
                                 }
                                 style={{
                                   borderRadius: "5px",
                                   wordWrap: "break-word",
                                   wordBreak: "break-all"
                                 }}
                               >
                                 {v?.chatDescription != "" && v?.chatDescription}
                                 <span className="text-secondary " style={{ fontSize: "small", display: "block" }}>
                                   {moment(v?.createdDate).format("MM/DD/YYYY, hh:mm A")}


                                 </span>
                               </div>
                             </div>
                           </>
                         );
                       })}
                   </div>
                </ScrollToBottom>
                <form onSubmit={handleSubmit} className='row position-fixed bottom-0 col-12 mb-1 bg-light p-1'>
                <div
                      className={ "col-9 " }
                      style={{ color: "#C6CACA" }}
                    >
                      <input
                      autoComplete='off'
                        className="form-control bg-white border py-2"
                        name="message"
                        placeholder="Message..."
                        value={data?.message}
                        onChange={handleChange("message")}
                      ></input>
                    </div>
                   <button
                      className="col-2  chat-btn  border pointer py-1 border-info  rounded chart-bg h-25"
                      disabled={data?.message?false:true}
                      style={{
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <BiSend size={27} className="text-primary" />
                      {/* <span className="px-1">Send</span> */}
                    </button>
                </form></>
        )}
   
  </div>
  )
}

export default MobileChat