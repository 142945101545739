import React, { useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useFormValidation } from '../../../Validations/useFormValidations';

const NotInterest = (props) => {
    const submit=()=>{
props?.submit(data)
    }
    const {
        data,
        handleChange,
        handleSubmit,
        
        addObject
      } = useFormValidation({
        initialValues: {
          answertype: "",
          notInterestedReason:"",
         
        },
        validationSchema: {
          answertype: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          notInterestedReason: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
         
        },
        submit: submit,
      });
      useEffect(()=>{
addObject({answertype:props?.type?.answertype})
      },[props?.type])
     
  return (
    
    <form onSubmit={handleSubmit} className='px-2'>
      <div className='row'>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        {props?.answerType()}
        </div>

{props?.type?.answertype=="Not Interested"&&<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label>Not Interested Reason<span className='text-danger'>*</span> </label>
                <textarea
                  className="form-control bg-white"
                  placeholder="Not Interested Reason"
                  rows={2}
                  value={data.notInterestedReason}
                  name="notInterestedReason"
                  onChange={handleChange("notInterestedReason")}
                />
              </div>}
    </div>
    <Modal.Footer className='mt-2'>
        <button className='float-right btn-save border border-white'>Save</button>
        </Modal.Footer>
    </form>
    
  )
}

export default NotInterest