import { useEffect, useState } from "react";
import { Modal, NavLink} from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import { RxCross2} from "react-icons/rx";
import { getList } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import { useSelector } from "react-redux";

const QRCodeModal = (props) => {
  let patientData = useSelector((state) => state.patientData)
 
  const [img, setImg] = useState();

  const getQRCode = async () => {
    let jsonObjects = {
      qrCodeText: "https://app.allistercare.com/dashboard",
      // qrCodeText: "http://allistercare.hitechhealth.us/dashboard",
    //  qrCodeText: "http://localhost:3000/dashboard",
      facilityId: props?.show?.facilityId?props?.show?.facilityId:props?.show,
    };
    let res = await getList(urls?.screening.qrCode, { jsonObjects });
    setImg(res);
   
    sessionStorage?.setItem("QRCODE", res?.data?.data.fileContents);
  };
  
  
 
  useEffect(()=>{
    getQRCode()
  },[])
  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">QR Code </Modal.Title>
       
        <div>
          <div className="pointer" onClick={props?.onHide}>
            <RxCross2 size={25} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
       
          <div className="d-flex flex-column align-items-center">
          <h5 className="border rounded p-2" style={{background: "#9fd6f5",}}>{img?.data?.facilityName}</h5>
            <img
              src={`data:image/png;base64,${img?.data?.data?.fileContents}`}
              className="h-75 qrcodemobile col-md-6"
              alt="QR Code"
            />
            <div >
              <a className="btn-save d-flex align-items-center justify-content-center" href={`data:image/png;base64,${img?.data?.data?.fileContents}`} download  ><BiDownload title="Download" size={25} /></a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QRCodeModal;
