import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormValidation } from "../../../Validations/useFormValidations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getById,
  mulitplePhoneNumberValue,
} from "../../../ConfigFiles/SharedFunctions";
import { urls } from "../../../ConfigFiles/Urls";
const ContactedInPerson = (props) => {
  const [physicianList, setPhysicianList] = useState([]);
  const getPhysicianList = async () => {
    let jsonObjects = {
      facilityId: props?.type?.facilityId,
      type: "Physician",
    };
    let res = await getById(urls?.screening?.getFacilityDropDown, {
      jsonObjects,
    });
    setPhysicianList(res);
  };
  const submit = () => {
    data["physicianName"] =
      physicianList?.length > 0 &&
      physicianList?.filter((v) => v?.physicianId == data?.physicianId)[0]
        ?.physicianName;
    props?.submit(data);
  };
  const { data, handleChange, handleSubmit, handleDateChange, addObject } =
    useFormValidation({
      initialValues: {
        answertype: "",
        physicianId: "",
        phone: "",
      },
      validationSchema: {
        answertype: {
          required: {
            value: true,
            message: "Please enter your firstName",
          },
        },
        physicianId: {
          required: {
            value: true,
            message: "Please enter your firstName",
          },
        },
        phone: {
          required: {
            value: true,
            message: "Please enter your Carrier Abbrevation",
          },
          minlength: {
            value: 10,
            message: "Please enter 10 digit phone",
          },
          maxlength: {
            value: 10,
            message: "Please enter 10 digit phone",
          },
        },
      },
      submit: submit,
    });
  useEffect(() => {
    addObject({
      answertype: props?.type?.answertype,
      phone: "",
      physicianId: "0",
    });
  }, [props?.type]);
  useEffect(() => {
    if (props?.type?.facilityId) {
      getPhysicianList();
    }
  }, [props?.type?.facilityId]);
  const dataValue = (value) => {
    return value ? value : "";
  };
  useEffect(() => {
    addObject({
      answertype: props?.type?.answertype,
      phone: physicianList?.filter(
        (v) => v?.physicianId == data?.physicianId
      )[0]?.phone,
    });
  }, [data?.physicianId]);
  return (
    <form onSubmit={handleSubmit} className="px-2">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          {props?.answerType()}
        </div>

        <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
          <label className="">
            Referred to Physician<span className="text-danger">*</span>{" "}
          </label>

          <select
            className="form-select"
            value={dataValue(data.physicianId)}
            name="physicianId"
            disabled={sessionStorage.getItem("roleId") == "2"}
            onChange={handleChange("physicianId")}
          >
            <option value={""}>Select Physician</option>
            {physicianList &&
              physicianList?.map((e, i) => {
                return (
                  <option key={i} value={e.physicianId}>
                    {e.physicianName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
          <label className="">
            Phone<span className="text-danger">*</span>
          </label>
          <br />
          <input
            type="text"
            className="form-control"
            placeholder="Phone"
            maxLength={10}
            name="phone"
            disabled="true"
            value={mulitplePhoneNumberValue(data?.phone)}
          />
        </div>
      </div>
      <Modal.Footer className='mt-2'>
        <button className="float-right btn-save border border-white">
          Save
        </button>
      </Modal.Footer>
    </form>
  );
};

export default ContactedInPerson;
