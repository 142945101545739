import React from 'react'
import { Routes,Route } from 'react-router-dom'
import Navbar from '../../SharedComponents/Navbar'
import { AdminAuthentication } from './AdminAuthentication'
import RoutingAdmin from '../RoutingAdmin'
import AdminLogIn from '../AdminLogIn'

const AdminPage = () => {
  return (
   <>
   
   <Routes>
   <Route path='/*'element={<AdminLogIn title={"Admin Login"}/>}></Route>
    <Route path="/all/*" element={<AdminAuthentication><Navbar><RoutingAdmin /></Navbar></AdminAuthentication>} ></Route>
    <Route path="*" element={< AdminLogIn/>}/>
   </Routes>
   </>
  )
}

export default AdminPage