import React, { useEffect, useState } from "react";
import { useFormValidation } from "../../Validations/useFormValidations";
import { useNavigate, useParams } from "react-router";
import { IoIosArrowBack } from "react-icons/io";
import { urls } from "../../ConfigFiles/Urls";
import {
  disableFun,
  getList,
  mulitplePhoneNumberValue,
} from "../../ConfigFiles/SharedFunctions";
import Quiz from "../Questionnair/Quiz";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { NavLink } from "react-router-dom";
import PatientsDetailsPrint from "./PatientsDetailsPrint";

const QuestionsDetailsView = () => {
  const navigate = useNavigate();
  const { screeningId } = useParams();
  const [patientsAnswersList, setPatientsAnswersList] = useState({});
  const [objKeys, setObjKeys] = useState([]);
  const [queKeys, setQueKeys] = useState([]);
  const [print, setPrint] = useState(false);
  const [NCDate, setNCDate] = useState({});

  

  const downloadPdf = async (divName) => {
    const input = document.getElementById(divName);
      html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation: "p",
      });
      const imgProps = doc.getImageProperties(imgData);
      const docWidth = doc.internal.pageSize.getWidth();
      const docHeight = (imgProps.height * docWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, docWidth, docHeight);
      doc.save(`Patient Details.pdf`);
      setPrint(false);
    });
  };
  useEffect(() => {
    if (print) {
      downloadPdf("div");
      setPrint(false);
    }
  }, [print]);

  const getPatientsAnswersList = async () => {
    let body = {
      screeningId: screeningId,
    };
    let jsonObjects = body;
    let res = await getList(urls?.screening?.submitPatientDetails, {
      jsonObjects,
    });
    setPatientsAnswersList(res?.data?.data[0]);
    setNCDate(res?.data?.data[0]?.answers[0]);
  };

  const { data, handleSubmit } = useFormValidation({
    initialValues: {},
  });
  const {} = Quiz();

  const listQues = (key) => {
    const array = Quiz();

    return (
      <div className="row">
        {patientsAnswersList?.answers
          ?.filter((val) => val[key]?.length > 0)
          ?.map((v, index) => {
            let string = array[key]?.["subString"]
              ? array[key]?.["subString"]
              : "";

            let string2 = array[key]?.["subString2"]
              ? array[key]?.["subString2"]
              : "";
            let string3 = array[key]?.["subString3"]
              ? array[key]?.["subString3"]
              : "";
            let string4 = array[key]?.["subString4"]
              ? array[key]?.["subString4"]
              : "";
            let string5 = array[key]?.["subString5"]
              ? array[key]?.["subString5"]
              : "";

            return (
              <div class=" row mt-1">
                <strong className="" key={index}>
                  {array[key]?.["question"] +
                    string +
                    " " +
                    string2 +
                    " " +
                    string3 +
                    " " +
                    string4 +
                    " " +
                    string5}
                </strong>

                {array[key]["months"] && (
                  <lable className="answers col-auto mt-2">
                    {v[array[key]["months"]]}
                  </lable>
                )}
                {Array.isArray(v[key]) ? (
                  v[key]?.map((ele) => {
                    return (
                      <label className="answers col-auto mt-2">{ele}</label>
                    );
                  })
                ) : (
                  <label className="answers mt-2 col-auto">{v[key]}</label>
                )}
              </div>
            );
          })}
      </div>
    );
  };
  const objects = [Quiz()];
  useEffect(() => {
    if (patientsAnswersList?.answers?.length > 0) {
      let objectKeys = Object?.keys(objects[0]);
      let ques =
        patientsAnswersList?.answers &&
        Object?.keys(patientsAnswersList?.answers[0]);
      setQueKeys(ques);
      setObjKeys(objectKeys);
    }
  }, [patientsAnswersList]);
  useEffect(() => {
    if (screeningId) {
      getPatientsAnswersList();
    }
  }, [screeningId]);

  return (
    <div className="px-3 py-2">
      <div class="card-header ">
        <div className=" d-flex gap-2">
          <div className="col-6">{/* <h5>Questionnaire</h5> */}</div>
          <div className="col-6 d-flex justify-content-end gap-2">
            <div
              className="px-2 btn-save border col-auto  "
              title="Back to dashboard"
              onClick={() => {
                navigate(
                  disableFun("2") ? `/dashboard` : `/admin/all/patients`
                );
              }}
            >
              Back
            </div>
            <div
              className="btn-save px-2 border col-auto"
              title="Print"
              style={{ cursor: "pointer" }}
              onClick={() => setPrint(true)}
            >
              Print
            </div>
          </div>
        </div>
      </div>
      <div >
        <div className="main-header mb-2 ms-3 ">Questionnaire </div>
        <div class="card mt-2">
          <div class="card-body">
            <div className="row d-flex">
              <div className="col-md-2 ">
                <h5 className="">Patient</h5>
                <div className="">{patientsAnswersList?.userName}</div>
              </div>
              <div className="col-md-3 mt-1">
                <h5>Contact Details</h5>
                <div className=" ">
                  {patientsAnswersList?.email
                    ? patientsAnswersList?.email
                    : "-"}{" "}
                  <br></br>
                  {patientsAnswersList?.phone
                    ? mulitplePhoneNumberValue(patientsAnswersList?.phone)
                    : "-"}
                </div>
              </div>
              <div className="col-md-2 mt-1">
                <h5>Submission Date</h5>
                <div className="">
                  {patientsAnswersList?.submittedDate !== "-"
                    ? moment(patientsAnswersList?.submittedDate).format(
                        "MM/DD/YYYY hh:mm A"
                      )
                    : "-"}
                </div>
              </div>
              <div className="col-md-2 mt-1">
                <h5>NC Date</h5>
                <div className="">
                  {NCDate?.NCDate &&
                  patientsAnswersList?.submittedDate !== "-" &&
                  (patientsAnswersList?.answers[0]?.cancerremoved != "No" ||
                    patientsAnswersList?.answers[0]?.cancerremoved?.length ==
                      "0")
                    ? moment(NCDate?.NCDate)?.format("YYYY MMM")
                    : "-"}
                </div>
              </div>
              <div className="col-md-3 mt-1">
                <h5>Connection Status</h5>
                <button
                  className={
                    "light-blue w-auto rounded border mb-3  px-4 text-white"
                  }
                >
                  {patientsAnswersList?.status}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tabpage-backgroundqa mt-1 ">
          <form onSubmit={handleSubmit}>
            <div className="p-3 row ">
              {disableFun("2") &&
              patientsAnswersList?.answers &&
              patientsAnswersList?.submittedDate !== "-" &&
              patientsAnswersList?.answers[0]?.cancerremoved == "No" ? (
                <div className="text-center ">
                  <h4>
                    Your next colonoscopy should be no later than - Unable to
                    calculate -
                  </h4>

                  <h6>
                    You should consult with an expert in colon cancer care, as
                    soon as possible
                  </h6>
                </div>
              ) : (
                disableFun("2") &&
                NCDate?.NCDate &&
                patientsAnswersList?.submittedDate !== "-" && (
                  <div className="text-center ">
                    <h4>
                      Information for {patientsAnswersList?.firstName} to review
                      with a Physician:
                    </h4>

                    <h6>
                      Your next colonoscopy should be no later than{" "}
                      {moment(NCDate?.NCDate)?.format("MMMM YYYY")}. You have{" "}
                      {NCDate?.Order == "14" || NCDate?.Order == "13" ? (
                        <span className={"font-weight-bold "}>AVERAGE</span>
                      ) : (
                        <span className={"font-weight-bold text-danger"}>
                          INCREASED
                        </span>
                      )}{" "}
                      risk of colon cancer
                    </h6>
                  </div>
                )
              )}
              {objKeys &&
                objKeys?.length > 0 &&
                objKeys
                  ?.filter((val) => queKeys?.includes(val))
                  ?.map((v) => {
                    return <div>{listQues(v)}</div>;
                  })}
            </div>
            {/* <div className="d-flex justify-content-end "></div> */}
            <div className="h-0 p-0 m-0" style={{ height: "0vh" }}>
            {print&&<PatientsDetailsPrint NCDate={NCDate} listQues={listQues} objKeys={objKeys} queKeys={queKeys}
      patientsAnswersList={patientsAnswersList} />}






            </div>
          </form>
        </div>
      </div>

    </div>
  );
};

export default QuestionsDetailsView;
