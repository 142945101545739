import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Carousel from "react-bootstrap/Carousel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Logo from "../../Image/logo-c0d9bcbe312d8af35fbe10e083e8d653.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  getList,
  notify,
  questionsSave,
  save,
} from "../../ConfigFiles/SharedFunctions";
import { urls, basefileURL } from "../../ConfigFiles/Urls";
import Quiz from "./Quiz";
import moment from "moment";
import GuestSignUpModal from "./GuestSignUpModal";


const QuestionsList = (props) => {
  const { facilityId,screeningId} = useParams();
 
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [patientsAnswersList, setPatientsAnswersList] = useState([]);
  const [data, setData] = useState({});
  const [update, setUpdate] = useState({});
  const [objKeys, setObjKeys] = useState([]);
  const [queKeys, setQueKeys] = useState([]);
  const [signup, setSignup] = useState("");
  const [facilityImages, setFacilityImages] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const [confirm, setconfirm] = useState(true);
  const years = [];

  const currentYear = new Date().getFullYear();
  for (
    let year = currentYear;
    year >= (data?.born == undefined ? 1900 : data?.born);
    year--
  ) {
    years.push(year);
  }
  const years1 = [];
  for (let year = currentYear; year >= 1900; year--) {
    years1.push(year);
  }


  
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const selectedMonth = data?.months;
  const indexOfSelectedMonth = selectedMonth
    ? months.indexOf(selectedMonth)
    : -1;
  const filterMonths =
    indexOfSelectedMonth !== -1 &&
    (data?.born == data?.completecolonoscopyperformed ||
      data?.born == data?.removethecancer)
      ? data?.born == data?.born && months.slice(indexOfSelectedMonth)
      : months;

  const renderTooltip = (array, i) => {
    let obj = array?.filter(({}, index) => index == i)[0];

    if (obj?.label) {
      return (
        <Tooltip id="button-tooltip" className="">
          <div>{obj?.label}</div>
        </Tooltip>
      );
    } else {
      return <div id="button-tooltip"></div>;
    }
  };

  const renderQuestionTooltip = (tooltip) => (
    <Tooltip id="button-tooltip-2" className="" {...tooltip}>
      <div>{tooltip}</div>
    </Tooltip>
  );
  const subString = (object) => {
    let sub = object?.subString != undefined ? object?.subString : "";

    let obj = (
      <div>
        {object?.question + " "}
        <span data-tooltip-id="my-tooltip-2">
          <OverlayTrigger
            placement="bottom"
            overlay={renderQuestionTooltip(object?.toolTip)}
          >
            <span className="link-hover">{sub}</span>
          </OverlayTrigger>
          {object?.subString2}
          {object?.subString3}
          {object?.subString4}
          {object?.subString5}
        </span>
      </div>
    );
    return obj;
  };

  const {
    born,
    evaluatedbyacolonoscopy,
    completecolonoscopy,
    completecolonoscopyperformed,
    colonorrectalcancerorpolyps,
    polypsorcancerofthecolonorrectum,
    morethanonefirstdegreebloodrelative,
    leaveblank,
    orethanoneseconddegreebloodrelative,
    eitherpolypsorcancerofthecolonorrectum,
    diagnosedwithanyofthefollowingconditions,
    cancerremoved,
    cancerremovedno,
    polypscompletelycompletelyremoved,
    removethecancer,
    duringoraftersurgery,
    growthsortumorsatyourlastcompletecolonoscopy,
    cancerinvolvetherectum,
    completecolonoscopyaftercancerremoval,
    morethan10,
    describeyourpolyps,
    adenomatouspolypsfound,
    hyperplasticpolyps,
    morehyperplasticpolyps,
    removalofasessileadenoma,
    UlcerativeColitisorChrohnsDisease,
    nextcolonoscopy,
    finalsubmit,
  } = Quiz();
  const listQues = (key) => {
    const array = Quiz();

    return (
      <div className="row">
        {[data]
          ?.filter((val) => val[key]?.length > 0)
          ?.map((v, index) => {
            let string = array[key]?.["subString"]
              ? array[key]?.["subString"]
              : "";

            let string2 = array[key]?.["subString2"]
              ? array[key]?.["subString2"]
              : "";
            let string3 = array[key]?.["subString3"]
              ? array[key]?.["subString3"]
              : "";
            let string4 = array[key]?.["subString4"]
              ? array[key]?.["subString4"]
              : "";
            let string5 = array[key]?.["subString5"]
              ? array[key]?.["subString5"]
              : "";

            return (
              <div className=" row ">
                <strong className="" key={index}>
                  {array[key]?.["question"] +
                    string +
                    string2 +
                    string3 +
                    string4 +
                    string5}
                </strong>
                {array[key]["months"] && (
                  <lable className="answers1 col-md-auto">{v[array[key]["months"]]}</lable>
                )}
                {Array.isArray(v[key]) ? (
                  v[key]?.map((ele) => {
                    return <label className="answers1 col-md-auto">{ele}</label>;
                  })
                ) : (
                  <label className="answers1 col-md-auto">{v[key]}</label>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  useEffect(() => {
    const objects = Quiz();
    if (data) {
      let objectKeys = Object?.keys(objects);
      let ques = data && Object?.keys(data);

      setQueKeys(ques);
      setObjKeys(objectKeys);
    }
  }, [data]);
  const addYearsToDate = (year, month, numberOfYearsToAdd) => {
    var v = months.indexOf(month) + 1;
  
    var date = new Date(year, v, 0);
    date.setFullYear(date.getFullYear() + numberOfYearsToAdd);

    return date != "Invalid date" ? moment(date)?.format("MM/DD/YYYY") : "";
  };
  const addMonthsToDate = (year, month, numberOfmonthsToAdd) => {
    var date = new Date(year, months.indexOf(month), 1);
    date.setMonth(date.getMonth() + numberOfmonthsToAdd);

    return date && date != "Invalid date"
      ? moment(date)?.format("MM/DD/YYYY")
      : "";
  };
  
  const setFormula = (object) => {
    if (object != undefined && object != {}) {
      if (!object?.evaluatedbyacolonoscopy?.includes("None of the above")) {
        if (object?.completecolonoscopy == "No") {
          data["formula1X"] = moment(new Date()).format("MM/DD/YYYY");
        } else {
          data["formula1X"] = "";
        }
      }
      if (
        object?.morethanonefirstdegreebloodrelative == "Yes" ||
        object?.morethanonefirstdegreebloodrelative == "No" ||
        object?.morethanonefirstdegreebloodrelative == "Unsure"
      ) {
        if (object?.leaveblank < 50) {
          data["formula1B"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != ""
              ? 5
              : parseInt(data?.leaveblank) - 10
              // data?.leaveblank!=""&&parseInt(data?.leaveblank) - 10
          );
        } else {
          data["formula1B"] = "";
        }
      }
      if (object?.morethanonefirstdegreebloodrelative == "Yes") {
        if (object?.leaveblank >= 50) {
          data["formula1A"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 5 : 40
          );
        } else {
          data["formula1A"] = "";
        }
      }

      if (
        object?.morethanonefirstdegreebloodrelative == "No" ||
        object?.morethanonefirstdegreebloodrelative == "Unsure"
      ) {
        if (object?.leaveblank > 50 && object?.leaveblank <= 60) {
          data["formula1A"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 5 : 40
          );
        } else {
          data["formula1A"] = "";
        }
      }
      if (
        object?.morethanonefirstdegreebloodrelative == "No" ||
        object?.morethanonefirstdegreebloodrelative == "Unsure"
      ) {
        if (object?.leaveblank > 60) {
          data["formula1C"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 10 : 40
          );
        } else {
          data["formula1C"] = "";
        }
      }
      if (object?.removethecancer) {
        if (object?.duringoraftersurgery == "No") {
          data["formula3A"] = addMonthsToDate(
            data?.removethecancer,
            data?.month2,
            3
          );
        } else {
          data["formula3A"] = "";
        }
      }
      if (object?.duringoraftersurgery == "Yes") {
        if (object?.growthsortumorsatyourlastcompletecolonoscopy == "Yes") {
          data["formula3B"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : moment(new Date()).format("YYYY"),
            data?.month1 != ""
              ? data?.month1
              : 
              // getPreviousMonth(moment(new Date()), 'YYYY-MM-DD'),
              moment(new Date()).format("MMMM"),
            1
          );
        } else {
          data["formula3B"] = "";
        }
      }

      if (
        object?.cancerinvolvetherectum == "Yes" ||
        object?.cancerinvolvetherectum == "No" ||
        object?.polypscompletelycompletelyremoved == "No" ||
        object?.polypscompletelycompletelyremoved == "Unsure" ||
        object?.polypscompletelycompletelyremoved == "Yes" ||
        // object?.completecolonoscopyaftercancerremoval == "No" ||
        object?.morethan10 == "Unsure" ||
        object?.morethan10 == "No"
      ) {
       
        if (
          object?.completecolonoscopyaftercancerremoval == "No" ||
          object?.morethan10 == "Yes" ||
          (object?.describeyourpolyps?.includes("Any single villous adenoma")||
          object?.describeyourpolyps?.includes("3 or more small (less than 1 cm) adenomatous polyps")||
          object?.describeyourpolyps?.includes("Any large polyp (over 1 cm)"))
        ) {
          data["formula3C"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : moment(new Date()).format("YYYY"),
            data?.month1 != ""
              ? data?.month1
              :
              //  getPreviousMonth(moment(new Date()), 'YYYY-MM-DD'),
              moment(new Date()).format("MMMM"),
            3
          );
        } 
        else if (
          object?.completecolonoscopyaftercancerremoval == "Yes" ||
          object?.adenomatouspolypsfound == "Yes"
        ) {
          data["formula3D"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : moment(new Date()).format("YYYY"),
            data?.month1 != ""
              ? data?.month1
              : 
              // getPreviousMonth(moment(new Date()), 'YYYY-MM-DD'),
              moment(new Date()).format("MMMM"),
            5
          );
        } 
        else {
          data["formula3C"] = "";
          data["formula3D"] = "";
        }
      }
      if (
        (!object?.eitherpolypsorcancerofthecolonorrectum?.includes("Cancer") &&
          !object?.eitherpolypsorcancerofthecolonorrectum?.includes(
            "Neither"
          )) ||
        object?.morehyperplasticpolyps == "Yes"
      ) {
        if (
          object?.polypscompletelycompletelyremoved == "No" ||
          object?.polypscompletelycompletelyremoved == "Unsure" ||
          object?.removalofasessileadenoma == "Yes"
        ) {
          data["formula4D"] = addMonthsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : moment(new Date()).format("YYYY"),
            data?.month1 != ""
              ? data?.month1
              // : getPreviousMonth(moment(new Date()), 'YYYY-MM-DD'),
              :moment(new Date()).format("MMMM"),
            2
          );
        } else {
          data["formula4D"] = "";
        }
      }
      if (
        object?.adenomatouspolypsfound == "No" ||
        object?.adenomatouspolypsfound == "Unsure"
      ) {
        if (object?.hyperplasticpolyps == "Yes") {
          data["formula5A"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 10 : 45
          );
        } else {
          data["formula5A"] = "";
        }
      }
      if (
        object?.completecolonoscopyaftercancerremoval == "No" ||
        object?.completecolonoscopyaftercancerremoval == "Yes" ||
        object?.cancerinvolvetherectum == "No" ||
        object?.cancerinvolvetherectum == "Yes" ||
        object?.morethan10 == "Yes" ||
        !object?.describeyourpolyps?.includes("None of the above") ||
        object?.adenomatouspolypsfound == "Yes" ||
        object?.hyperplasticpolyps == "Yes" ||
        object?.morehyperplasticpolyps == "Yes" ||
        object?.removalofasessileadenoma == "No"
      ) {
        if (
          object?.diagnosedwithanyofthefollowingconditions?.includes(
            "None of the above"
          )
        ) {
          data["formula5A5"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 10 : 45
          );
        } else {
          data["formula5A5"] = "";
        }
      }
      if (
        object?.completecolonoscopyaftercancerremoval == "No" ||
        object?.completecolonoscopyaftercancerremoval == "Yes" ||
        object?.cancerinvolvetherectum == "No" ||
        object?.cancerinvolvetherectum == "Yes" ||
        object?.morethan10 == "Yes" ||
        !object?.describeyourpolyps?.includes("None of the above") ||
        object?.adenomatouspolypsfound == "Yes" ||
        object?.hyperplasticpolyps == "Yes" ||
        object?.morehyperplasticpolyps == "Yes" ||
        object?.removalofasessileadenoma == "No"
      ) {
        if (
          (data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
          ) &&
            !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
              "Hereditary Nonpolyposis Colon Cancer (HNPCC)"
            ) &&
            data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
              "Familial Adenomatous Polyposis Syndrome (FAP)"
            )) ||
          (data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
          ) &&
            data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
              "Hereditary Nonpolyposis Colon Cancer (HNPCC)"
            ) &&
            data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
              "Familial Adenomatous Polyposis Syndrome (FAP)"
            )) ||
          (!data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
          ) &&
            data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
              "Familial Adenomatous Polyposis Syndrome (FAP)"
            ))
        ) {
          data["formula5B5"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 1 : 10
          );
        } else {
          data["formula5B5"] = "";
        }
      }
      if (
        (object?.diagnosedwithanyofthefollowingconditions?.includes(
          "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
        ) &&
          object?.diagnosedwithanyofthefollowingconditions?.includes(
            "Hereditary Nonpolyposis Colon Cancer (HNPCC)"
          ) &&
          !object?.diagnosedwithanyofthefollowingconditions?.includes(
            "Familial Adenomatous Polyposis Syndrome (FAP)"
          )) ||
        (object?.diagnosedwithanyofthefollowingconditions?.includes(
          "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
        ) &&
          !object?.diagnosedwithanyofthefollowingconditions?.includes(
            "Familial Adenomatous Polyposis Syndrome (FAP)"
          ))
      ) {
        if (object?.UlcerativeColitisorChrohnsDisease) {
          data["formula5D5"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != ""
              ? 1
              : parseInt(data?.UlcerativeColitisorChrohnsDisease) + 8
          );
        } else {
          data["formula5D5"] = "";
        }
      }
      if (object?.adenomatouspolypsfound == "No") {
        if (object?.hyperplasticpolyps == "Yes") {
          data["formula5A"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 10 : 45
          );
        } else {
          data["formula5A"] = "";
        }
      }
      {
        if (
          !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
          ) &&
          !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "None of the above"
          ) &&
          !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Familial Adenomatous Polyposis Syndrome (FAP)"
          ) &&
          data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
            "Hereditary Nonpolyposis Colon Cancer (HNPCC)"
          )
        ) {
          data["formula5C5"] = addYearsToDate(
            data?.completecolonoscopyperformed != ""
              ? data?.completecolonoscopyperformed
              : data?.born,
            data?.month1 != "" ? data?.month1 : data?.months,
            data?.completecolonoscopyperformed != "" ? 1 : 20
          );
        } else {
          data["formula5C5"] = "";
        }
      }
    }
  };
  
  useEffect(() => {
    let d = [
      born,
      
      ...(data?.["born"]
      ?
      [evaluatedbyacolonoscopy]
      : []),
      ...(data?.["evaluatedbyacolonoscopy"]?.includes("Constipation")||data?.["evaluatedbyacolonoscopy"]?.includes("Abdominal Pain")||
      data?.["evaluatedbyacolonoscopy"]?.includes("Blood in stool, on toilet paper, and/or bowl?")||
      data?.["evaluatedbyacolonoscopy"]?.includes("Weight loss")||
      data?.["evaluatedbyacolonoscopy"]?.includes("None of the above")||
      data?.["evaluatedbyacolonoscopy"]?.includes("Anemia")
        ? [completecolonoscopy]
        : []),
      ...(data?.["completecolonoscopy"] == "Yes"
        ? [completecolonoscopyperformed]
        : []),
      ...(data?.["completecolonoscopy"] == "No" ||
      data?.["completecolonoscopyperformed"]
        ? [colonorrectalcancerorpolyps]
        : []),
      // ----------------------------------------------1st A from-------------------------------------------------------------------------------
      ...(data?.["colonorrectalcancerorpolyps"] == "Yes"
        ? [polypsorcancerofthecolonorrectum]
        : []),
      ...(data?.["colonorrectalcancerorpolyps"] == "Yes" &&
      data?.["polypsorcancerofthecolonorrectum"] == "Yes"
        ? [morethanonefirstdegreebloodrelative]
        : []),
      ...(data?.["colonorrectalcancerorpolyps"] == "Yes" &&
      (data?.["morethanonefirstdegreebloodrelative"] == "Yes" ||
        data?.["morethanonefirstdegreebloodrelative"] == "No" ||
        data?.["morethanonefirstdegreebloodrelative"] == "Unsure")
        ? [leaveblank]
        : []),
      ...(data?.["colonorrectalcancerorpolyps"] == "Yes" &&
      (data?.["polypsorcancerofthecolonorrectum"] == "No" ||
        data?.["polypsorcancerofthecolonorrectum"] == "Unsure")
        ? [orethanoneseconddegreebloodrelative]
        : []),
      // ----------------------------------------------------2nd form---------------------------------------------------------------------
      ...(data?.["leaveblank"] != undefined ||
      data?.["leaveblank"] != "" ||
      data?.["colonorrectalcancerorpolyps"] == "No" ||
      !data?.["polypsorcancerofthecolonorrectum"] == "Yes"
        ? [eitherpolypsorcancerofthecolonorrectum]
        : []),
      ...(data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes("Neither")
        ? [diagnosedwithanyofthefollowingconditions]
        : []),
      ...((data?.["colonorrectalcancerorpolyps"] == "No" &&
        data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes("Polyps") &&
        data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes("Cancer")) ||
      data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes("Cancer")
        ? [cancerremoved]
        : []),

      ...(data?.["cancerremoved"] == "Yes" ? [removethecancer] : []),
      ...(data?.["cancerremoved"] == "No" ? [cancerremovedno] : []),
      ...(data?.["cancerremoved"] == "Yes" && data?.["removethecancer"]
        ? [duringoraftersurgery]
        : []),
      ...(!data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes(
        "Neither"
      ) && !data?.["eitherpolypsorcancerofthecolonorrectum"]?.includes("Cancer")
        ? [polypscompletelycompletelyremoved]
        : []),
      //     // ----------------------------------------------------3rd form start------------------------------------------------------------------------
      ...(data?.["duringoraftersurgery"] == "Yes"
        ? [growthsortumorsatyourlastcompletecolonoscopy]
        : []),
      ...(data?.["duringoraftersurgery"] == "No" ||
      (data?.["growthsortumorsatyourlastcompletecolonoscopy"] == "Yes" &&
        data?.["duringoraftersurgery"] == "Yes") ||
      (data?.["growthsortumorsatyourlastcompletecolonoscopy"] == "No" &&
        data?.["duringoraftersurgery"] == "Yes")
        ? [cancerinvolvetherectum]
        : []),
      ...((data?.["growthsortumorsatyourlastcompletecolonoscopy"] == "No" &&
        data?.["cancerinvolvetherectum"] == "No" &&
        data?.["duringoraftersurgery"] == "Yes") ||
      (data?.["growthsortumorsatyourlastcompletecolonoscopy"] == "No" &&
        data?.["cancerinvolvetherectum"] == "Yes" &&
        data?.["duringoraftersurgery"] == "Yes")
        ? [completecolonoscopyaftercancerremoval]
        : []),
      // -----------------------------------------------------------------------------------------------------------------------------------
      ...(data?.["polypscompletelycompletelyremoved"] == "Yes" ||
      data?.["polypscompletelycompletelyremoved"] == "No" ||
      data?.["polypscompletelycompletelyremoved"] == "Unsure"
        ? [morethan10]
        : []),
      ...(data?.["morethan10"] == "No" || data?.["morethan10"] == "Unsure"
        ? [describeyourpolyps]
        : []),
      ...(data?.["describeyourpolyps"]?.includes("None of the above")
        ? [adenomatouspolypsfound]
        : []),
      ...(data?.["adenomatouspolypsfound"] == "No" ||
      data?.["adenomatouspolypsfound"] == "Unsure"
        ? [hyperplasticpolyps]
        : []),
      ...(data?.["hyperplasticpolyps"] == "No" ||
      data?.["hyperplasticpolyps"] == "Unsure"
        ? [morehyperplasticpolyps]
        : []),
      ...(data?.["morehyperplasticpolyps"] == "No"
        ? [removalofasessileadenoma]
        : []),
      ...(data?.["morethan10"] == "Yes" ||
      data?.["describeyourpolyps"]?.includes(
        "3 or more small (less than 1 cm) adenomatous polyps"
      ) ||
      data?.["describeyourpolyps"]?.includes("Any large polyp (over 1 cm)") ||
      data?.["describeyourpolyps"]?.includes("Any single villous adenoma") ||
      data?.["adenomatouspolypsfound"] == "Yes" ||
      data?.["hyperplasticpolyps"] == "Yes" ||
      data?.["removalofasessileadenoma"] == "Yes" ||
      data?.["removalofasessileadenoma"] == "No" ||
      data?.["describeyourpolyps"]?.includes(
        "I don't know what types or number of polyps were found"
      ) ||
      data?.["completecolonoscopyaftercancerremoval"] == "Yes" ||
      data?.["completecolonoscopyaftercancerremoval"] == "No" ||
      data?.["cancerinvolvetherectum"] == "No" ||
      data?.["cancerinvolvetherectum"] == "Yes" ||
      data?.["morethan10"] == "No"||
      data?.["morehyperplasticpolyps"] == "Yes"
      
        ? [diagnosedwithanyofthefollowingconditions]
        : []),

      ...((data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
        "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
      ) &&
        data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
          "Hereditary Nonpolyposis Colon Cancer (HNPCC)"
        ) &&
        !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
          "Familial Adenomatous Polyposis Syndrome (FAP)"
        )) ||
      (data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
        "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease"
      ) &&
        !data?.["diagnosedwithanyofthefollowingconditions"]?.includes(
          "Familial Adenomatous Polyposis Syndrome (FAP)"
        ))
        ? [UlcerativeColitisorChrohnsDisease]
        : [finalsubmit]),
      ...(data?.["UlcerativeColitisorChrohnsDisease"] ? [finalsubmit] : []),
      // ----------------------------------------------------------------------------------------------------------------------------
    ];
    setQuestions(d);

    if (Object.keys(selectedQuestion)?.length == 0) {
      if (
        sessionStorage.getItem("key") != null &&
        sessionStorage.getItem("key") != "undefined" &&
        sessionStorage.getItem("screeningId")
      ) {
        let obj = JSON.parse(sessionStorage.getItem("key"));
        let obj2 = JSON.parse(sessionStorage.getItem("allQuestion"));
        let obj3 = JSON.parse(sessionStorage.getItem("data"));
        setSelectedQuestion(obj);
        setQuestions(obj2);
        setData(obj3);
      } else {
        setSelectedQuestion(d?.[0]);
      }
    }
    setFormula(data);
  }, [data, screeningId]);
  const reset = () => {
    setSelectedQuestion({});

    sessionStorage.removeItem("allQuestion");
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("data");
    setData({});
    saveQuestionaryData("reset");
  };

  const saveQuestionaryData = async (key) => {
    let body = {
      userId: userId != null ? userId : sessionStorage.getItem("guestUserId"),
      answers: key ? [] : [data],
      newQuestionary: "newQuestionary",
      facilityId: facilityId,
      screeningId: screeningId
        ? screeningId
        : sessionStorage.getItem("screeningId")
        ? sessionStorage.getItem("screeningId")
        : 0,
    };
    let jsonObjects = body;
    let res = await questionsSave(urls?.screening.questionScreening, {
      jsonObjects,
    });
    if (res.data?.status) {
      sessionStorage.setItem("screeningId", res?.data?.data);
      // setUpdate(res);
    }
  };

  const getPatientsAnswersList = async () => {
    let body = {
      screeningId: screeningId||sessionStorage.getItem("screeningId"),
    };
    let jsonObjects = body;
    let res = sessionStorage.getItem("new")!="addQuestion"&&await getList(urls?.screening?.submitPatientDetails, {
      jsonObjects,
    });

    if (res?.data?.status) {
      setPatientsAnswersList(res?.data?.data[0]?.answers[0]);

      sessionStorage.setItem("finalSubmit", res?.data?.data[0]?.finalSubmit);
      sessionStorage.setItem(
        "submittedDate",
        res?.data?.data[0]?.submittedDate
      );
    }
  };

  const finalsubmitList = async () => {
    let body = {
      screeningId: screeningId
        ? screeningId
        : sessionStorage.getItem("screeningId")
        ? sessionStorage.getItem("screeningId")
        : 0,
      finalSubmit: "Yes",
    };
    let jsonObjects = body;
    let res = await save(urls?.screening.qaFinalSubmit, { jsonObjects });
    if (res?.data?.status) {
      sessionStorage.setItem("finalSubmit", "Yes");
      setUpdate(res);
    }
  };

  const conditionalReturn = (d, value) => {
    let v;
    if (
      [
        "evaluatedbyacolonoscopy",
        "twoapositivediagnosedwithcolon",
        "oneAfirstdegreebloodrelatives",
        "eitherpolypsorcancerofthecolonorrectum",
        "diagnosedwithanyofthefollowingconditions",
        "describeyourpolyps",
      ]?.some((e) => e == d?.name)
    ) {
      if (
        [
          "None of the above",
          "I don't know what types or number of polyps were found",
          "Neither",
          "Unsure",
        ]?.some((e) => e == value)
      ) {
        v = [value];
      } else {
        v = data?.[d?.name]?.includes(value)
          ? data?.[d?.name]?.filter((v) => v != value && v != "")
          : [...(data?.[d?.name] || []), value];
        v = v?.filter((v) =>
          [
            "None of the above",
            "I don't know what types or number of polyps were found",
            "Neither",
            "Unsure",
          ]?.every((e) => e != v)
        );
      }
    } else {
      v = data?.[d?.name]?.includes(value)
        ? data?.[d?.name]?.filter((v) => v != value)
        : [...(data?.[d?.name] || []), value];
    }
    return v;
  };

  const returnValue = (d, value) => {
    switch (d?.type) {
      case "Date":
        return value;
      case "Date1":
        return value;
      case "cancerremovedno":
        return value;
      case "finalsubmit":
        return value;
      case "Radio":
        return value;
      case "input":
        return value;
      case "CheckBox":
        return conditionalReturn(d, value);
    }
  };

  const formChange = (d, name, value) => {
  
    let temp = {};
    d?.childKeys?.map((e) => {
      if (
        ["Date", "Date1", "Radio", "input", "CheckBox"]?.some(
          (s) => s == questions?.filter((v) => v?.name == e)?.[0]?.type
        )
      ) {
        temp[e] = "";
      } else {
        temp[e] = [];
      }
    });

    setData((prev) => ({
      ...prev,
      ...temp,
      [name]: returnValue(d, value),
    }));
  };


  const handleNumberChange = (d, name, value) => {
    let temp = {};
    d?.childKeys?.map((e) => {
      if (["input"]?.some(
          (s) => s == questions?.filter((v) => v?.name == e)?.[0]?.type
        )
      ) {
      
        temp[e] = "";
      } else {
        temp[e] = [];
      }
    });
    if (d && !value?.includes(".")) {
      let str = value.replace(/[^0-9\.]/g, "");
      if(name=="UlcerativeColitisorChrohnsDisease"){
        if (value <= data?.Age) {
          setData((prev) => ({
            ...prev,
            ...temp,
            [name]: returnValue(d, str),
          }));
        } else {
          notify(false, "please enter the age");
        }
      }
      else{
        setData((prev) => ({
          ...prev,
          ...temp,
          [name]: returnValue(d, str),
        }));
      }
    }
  };
  const next = () => {
    let index = questions?.findIndex((x) => x?.name == selectedQuestion?.name);

    if (!(index + 1 == questions?.length)) {
        let type = selectedQuestion?.type;
        let name = selectedQuestion?.name;
       
        let months = selectedQuestion?.months;
        let sin = ["Date", "Date1", "Radio", "input", "CheckBox"];
        let d = data[name];
        let m = data[months];

        if (
            (sin.includes(type) && d != "" && d != undefined && d != null && m != null && m != undefined) && 
            data[name]?.length > 0 && 
            data[months]?.length > 0
        ) {
            sessionStorage.setItem("allQuestion", JSON.stringify(questions));
            sessionStorage.setItem("key", JSON.stringify(questions[index + 1]));
            sessionStorage.setItem("data", JSON.stringify(data));

            setSelectedQuestion(questions[index + 1]);

            sessionStorage.getItem("finalSubmit") != "Yes" && saveQuestionaryData();
        } else if (
            ((type == "Radio" || (type == "input") || type == "CheckBox") && d != "" && d != undefined && d != null) && 
            data[name]?.length > 0
        ) {
            sessionStorage.setItem("allQuestion", JSON.stringify(questions));
            sessionStorage.setItem("key", JSON.stringify(questions[index + 1]));
            sessionStorage.setItem("data", JSON.stringify(data));

            setSelectedQuestion(questions[index + 1]);

            sessionStorage.getItem("finalSubmit") != "Yes" && saveQuestionaryData();
        }
      //   else if (type == "input"&&name=="leaveblank"
      // ) {
      //     sessionStorage.setItem("allQuestion", JSON.stringify(questions));
      //     sessionStorage.setItem("key", JSON.stringify(questions[index + 1]));
      //     sessionStorage.setItem("data", JSON.stringify(data));

      //     setSelectedQuestion(questions[index + 1]);

      //     sessionStorage.getItem("finalSubmit") != "Yes" && saveQuestionaryData();
      // }
         else {
            notify(false, "Please Select Your Answer");
        }
    }
};

  const prev = () => {
    let index = questions.findIndex((x) => x?.name == selectedQuestion?.name);

    if (index != 0) {
      sessionStorage.setItem("key", JSON.stringify(questions[index - 1]));
      sessionStorage.setItem("allQuestion", JSON.stringify(questions));
      sessionStorage.setItem("data", JSON.stringify(data));
      setSelectedQuestion(questions[index - 1]);
      setSignup("");
    }
  };
  
  const returnInput = (d) => {
    switch (d?.type) {
      case "Date":
        return (
          <div className="d-flex  qanswers pointer gap-2">
            <div className=" col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <select
                id="years"
                className="form-select"
                value={data?.[d?.name]}
                onChange={(e) => formChange(d, d["name"], e?.target?.value)}
              >
                <option value=""> Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <select
                id="months"
                className="form-select"
                value={data?.[d?.months]}
                onChange={(e) => formChange(d, d["months"], e?.target?.value)}
              >
                <option value="">Month</option>
                {filterMonths?.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      case "Date1":
        return (
          <div className="d-flex  qanswers pointer gap-2">
            <div className=" col-xl-2 col-lg-3 col-md-6 col-sm-12">
              <select
                id="years"
                className="form-select"
                value={data?.[d?.name]}
                onChange={(e) => formChange(d, d["name"], e?.target?.value)}
              >
                <option value=""> Year</option>
                {years1.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className=" col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <select
                id="months"
                className="form-select"
                value={data?.[d?.months]}
                onChange={(e) => formChange(d, d["months"], e?.target?.value)}
              >
                <option value="">Month</option>
                {filterMonths?.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      case "finalsubmit":
      
        return (
          <>
            <div
              className="rounded border qaanswersbox mobile_height   p-2"
              
            >
              <div className="font-weight-bold text-center m-0">
                Screening Results
              </div>
              {(signup=="colonoscopy"||sessionStorage?.getItem("roleId")=="2"||sessionStorage.getItem("finalSubmit") == "Yes")&&<div className="font-weight-normal text-center">
                Your next colonoscopy should be no later than{" "}
                <span className={
                  data?.Order == "14"||data?.Order == "13"?"font-weight-bold":"font-weight-bold text-danger"
                }
                // className="font-weight-bold"
                >
                  {data?.NCDate && moment(data?.NCDate).format("MMMM-YYYY")}{" "}
                </span>
                .
              </div>}
    
    {sessionStorage?.getItem("guestUserId")&&sessionStorage.getItem("signup")==null?
    <>
    {signup==""&&!sessionStorage.getItem("finalSubmit")&&
      <div>
      <div className="d-flex d-flex justify-content-center">Please signup to view the result. </div>
      
            <div className="d-flex d-flex justify-content-center gap-2">
                       
                     
                          <button
                            className="mobile_btn mt-4 p-2"
                            onClick={() => setSignup("colonoscopy")}
                          >
                            No
                          </button>
                          <button
                            className="mobile_btn mt-4  p-2"
                            onClick={() => setSignup("signup")}
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>}
                    
                </>
              : (
                <>
                  {objKeys &&
                    objKeys?.length > 0 &&
                    objKeys
                      ?.filter((val) => queKeys?.includes(val))
                      ?.map((v) => {
                        return <div>{listQues(v)}</div>;
                      })}
                </>
              )}
            </div>
          </>
        );
      case "cancerremovedno":
        return (
          <>
            <div className="page-background">
              <p className="font-weight-bold text-center m-0">
              Your next colonoscopy should be no later than - unable to calculate .
              </p>
              <p className="text-center">
              You should consult with an expert in colon cancer care, as soon as possible .
              </p>
            </div>
          </>
        );
      case "Select":
        return (
          <div className="d-flex qanswers gap-2">
            <div className="d-flex gap-2">
              {d?.option?.map((e, i) => (
                <div
                  key={i}
                  onChange={(e) => formChange(d, d["name"], e?.target?.value)}
                  className={`border p-2 pointer ${
                    data?.[d?.name]?.includes(e) ? " bg bg-info" : ""
                  }`}
                >
                  {e}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <button className="btn btn-danger" onClick={prev}>
                Prev
              </button>
              <button className="btn btn-success" onClick={next}>
                next
              </button>
            </div>
          </div>
        );
      case "MultiSelect":
        return (
          <div className="d-flex qanswers gap-2">
            <div className="d-flex gap-2">
              {d?.option?.map((e, i) => (
                <div
                  key={i}
                  onChange={(e) => formChange(d, d["name"], e)}
                  className={`border p-2 pointer ${
                    data?.[d?.name]?.includes(e) ? " bg bg-info" : ""
                  }`}
                >
                  {e}
                </div>
              ))}
            </div>
          </div>
        );
      case "CheckBox":
        return (
          <div className="">
            <div className="  gap-2">
              {d?.option?.map((e, i) => (
                <div className="d-flex ">
                  <div>
                    <input
                      type="checkbox"
                      value={e}
                      key={i}
                      id={i}
                      onChange={(e) =>
                        formChange(d, d["name"], e?.target?.value)
                      }
                      className={`border ms-2  checkbox p-2 pointer`}
                      checked={data?.[d?.name]?.includes(e)}
                    />
                  </div>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(d?.optionsToolTip, i)}
                  >
                    {/* <div className="ms-2 pointer"> {e}</div> */}
                    <div>
                      {" "}
                      <label className="ms-2 pointer qanswers " htmlFor={i}>
                        {e}
                      </label>
                    </div>
                  </OverlayTrigger>
                </div>
              ))}
            </div>
          </div>
        );
      case "input":
        return (
          <div className="d-flex qanswers gap-2">
            <div>
              <input
                value={data?.[d?.name]}
                onChange={(e) =>
                  handleNumberChange(d, d["name"], e?.target?.value)
                }
              ></input>
            </div>
          </div>
        );

      case "Radio":
        return (
          <div className="">
            <div className="  gap-2">
              {d?.option?.map((e, i) => (
                <div className="d-flex ">
                  <div>
                    <input
                      type="radio"
                      value={e}
                      key={i}
                      id={i}
                      onChange={(e) =>
                        formChange(d, d["name"], e?.target?.value)
                      }
                      className={`border mt-2 checkbox  p-2 pointer`}
                      checked={data?.[d?.name]?.includes(e)}
                    />
                  </div>
                  {/* <div className="ms-2 "> {e}</div> */}
                  <div>
                    {" "}
                    <label className="ms-2 pointer qanswers " htmlFor={i}>
                      {e}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
    }
  };
  useEffect(() => {
    const DOB = data?.born;
    const Age = moment(new Date()).format("YYYY") - DOB;
    data["Age"] = Age;
  }, [data?.born]);
  useEffect(() => {
    if (userId != null && userId != undefined) {
      setData(patientsAnswersList);
    }
  }, [patientsAnswersList]);
  useEffect(() => {
  (screeningId||sessionStorage.getItem("screeningId"))>0 && getPatientsAnswersList();
  }, []);
  useEffect(() => {
    const dateArray = [
      { name: data?.formula1X, order: "1" },
      { name: data?.formula1A, order: "11" },
      { name: data?.formula1B, order: "10" },
      { name: data?.formula1C, order: "12" },
      { name: data?.formula3A, order: "3" },
      { name: data?.formula3B, order: "4" },
      { name: data?.formula3C, order: "8" },
      { name: data?.formula3D, order: "9" },
      { name: data?.formula4D, order: "2" },
      { name: data?.formula5A, order: "14" },
      { name: data?.formula5A5, order: "13" },
      { name: data?.formula5B5, order: "5" },
      { name: data?.formula5C5, order: "7" },
      { name: data?.formula5D5, order: "6" },
    ];

    const nonEmptyItems = dateArray?.filter((item) => {
      return (
        item.name !== undefined && item.name !== null && item.name.length !== 0
      );
    });

    nonEmptyItems?.sort(
      (a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)
    );

    const topItem = nonEmptyItems[0];

    if (data && data != undefined) {
      data["NCDate"] = topItem?.name;
      data["Order"] = topItem?.order;
    }
  }, [data]);
 
  const getFacilityImages = async () => {
    let body = {
      facilityId: parseInt(sessionStorage.getItem("facility")),
    };
    let jsonObjects = body;
    let res = await getList(urls?.facility?.getFacilityImageList, {
      jsonObjects,
    });
    setFacilityImages(res?.data?.data);
  };
  useEffect(() => {
    getFacilityImages();
  }, []);
useEffect(()=>{
 
  if(sessionStorage.getItem("new")=="addQuestion"||screeningId>0){
    sessionStorage.removeItem("new");
    sessionStorage.removeItem("screeningId");
    
    setSelectedQuestion({});
   sessionStorage.removeItem("allQuestion");
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("data");
    setData({});
    sessionStorage.removeItem("finalSubmit")
    
   sessionStorage.removeItem("submittedDate");
   sessionStorage.removeItem("guestUserId");
   localStorage.removeItem("facilityId")
  
  }
},[sessionStorage.getItem("new")])


const cssValue=()=>{
  return sessionStorage?.getItem("guestUserId")?"btn-printheader mobile_btn text-secondary col-4 col-md-3":"btn-printheader mobile_btn text-secondary col-6 col-md-3 "
}
  return (
    <div className="row " >
      <div className="col-md-7 px-4">
        {/* <div>
          <img className="h-25 w-50 img-fluid p-2" src={Logo} alt="Logo" />
        </div> */}

        {selectedQuestion && (
          <div>
            <div className="text-header2 mt-1" variant="success">
              {subString(selectedQuestion)}
            </div>

            <fieldset disabled={sessionStorage.getItem("finalSubmit") == "Yes"}>
              <div className="qanswers mt-1">
                {returnInput(selectedQuestion)}
              </div>
            </fieldset>
           
          </div>
        )}
      </div>
      <div class="col-md-5 carosal_bg img_login py-4" style={{height:"90vh",}}>
        
        {facilityImages && facilityImages?.length > 0 && (
          <Carousel interval={12000} className="">
  {facilityImages.map((image, index) => {
    if (image?.fileName && image?.fileName !== "") {
      return (
        <Carousel.Item key={index}>
          <img
            className="d-block mx-auto"
            src={`${basefileURL}/FacilityImages/${image?.fileName}`}
            alt={`Slide ${index}`}
            style={{
              height: "400px",
              width: "100%",
            }}
          />
        </Carousel.Item>
      );
    }
    return null;
  })}
</Carousel>
        )}

        
      </div>
      <div className="w-100 bg-sm-light row position-fixed  bottom-0 mb-4 px-4 ">
      <div className="col-12 col-md-6  d-flex justify-content-start  gap-2">
              <button className={cssValue()} onClick={prev}>
                Back
              </button>
              {selectedQuestion?.type != "cancerremovedno" &&
              selectedQuestion?.type != "finalsubmit" ? (
                <button
                  className={cssValue()}
                  onClick={() => {
                    next();
                  }}
                >
                  Next
                </button>
              ) : (
                (signup == "colonoscopy" ||sessionStorage.getItem("signup")||
                  sessionStorage?.getItem("roleId") == "2" ||
                  sessionStorage.getItem("finalSubmit") == "Yes") && (
                  <button
                    title="Once Submit You Can't Edit And Reset"
                    className={
                      sessionStorage.getItem("finalSubmit") == "Yes"
                        ? "btn-printheader mobile_btn  bg-secondary text-white border text-secondary col-6 col-md-3 "
                        : "btn-printheader mobile_btn text-secondary col-6 col-md-3  "
                    }
                    disabled={sessionStorage.getItem("finalSubmit") == "Yes"}
                    onClick={finalsubmitList}
                  >
                    Finish
                  </button>
                )
              )}

              {!sessionStorage.getItem("userId") &&
                selectedQuestion?.type != "finalsubmit" &&sessionStorage.getItem("finalSubmit")==null&&sessionStorage.getItem("signup")==null&& (
                  <button
                    className={cssValue()}
                    onClick={() => setSignup("signup")}
                  >
                    Sign Up
                  </button>
                )}
            </div>

            <div className=" col-12 col-md-6  d-flex justify-content-center  gap-2  pt-2 ">
          <button
            disabled={sessionStorage.getItem("finalSubmit") == "Yes"}
            className={
              sessionStorage.getItem("finalSubmit") == "Yes"
                ? "btn-printheader mobile_btn bg-secondary text-white border col-6 col-md-3 ms-2  "
                : "btn-printheader mobile_btn text-secondary col-6 col-md-3 ms-2"
            }
            onClick={reset}
          >
            Reset All
          </button>

          {
            sessionStorage.getItem("finalSubmit") == "Yes"&&
            <div
              className="btn-printheader mobile_btn text-secondary col-6 col-md-3 "
              onClick={() => {
                navigate( sessionStorage.getItem("userId")?`/dashboard`:`/dashboard/${localStorage.getItem("facilityId")}`);
              }}
            >
              Dashboard
            </div>
          }
        </div>
<div>

</div>
      </div>

      {signup == "signup" && (
        <GuestSignUpModal show={signup} onHide={() => setSignup(false)} />
      )}
    </div>
  );
};

export default QuestionsList;
