import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormValidation } from "../../Validations/useFormValidations";
import { urls } from "../../ConfigFiles/Urls";
import hide from "../../Image/hide.png";
import view from "../../Image/view.png";
import signUp from "../../Image/login_page.png";
import {
  getList,
  mulitplePhoneNumberValue,
  notify,
  save,
} from "../../ConfigFiles/SharedFunctions";
import { Col, Container, Row } from "react-bootstrap";

const SignUp = () => {
  const navigate = useNavigate();

  const [confirmPassword, setConfirmPassword] = useState(true);

  const facilityId =
    localStorage?.getItem("facilityId") != null &&
    localStorage?.getItem("facilityId") != "undefined"
      ? localStorage?.getItem("facilityId")
      : 0;

  const signupasaguest = async () => {
    let body = {
      userId: 0,
      facilityId: facilityId,
    };

    let jsonObjects = body;
    if (facilityId != 0) {
      let res = await getList(urls?.signup?.signUpAsGuest, { jsonObjects });
      if (res?.data?.status == true) {
        sessionStorage.setItem("signAsGuest", res?.data?.status);
        sessionStorage.setItem("guestUserId", res?.data?.data);
        sessionStorage.setItem("facility", res?.data?.displayData);
        navigate("/terms");
      }
      // if (sessionStorage.getItem("signAsGuest") != null) {
      //   navigate("/terms");
      // }
      // else{
      //   notify(res?.data?.status, res?.data?.message);
      // }
    } else {
      notify(false, "Please scan facility QR code..");
    }
  };

  const submit = async () => {
    if (data?.password == data?.confirmPassword) {
      const { confirmPassword, ...bodyWithoutConfirmPassword } = {
        userId: 0,
        roleId: 2,
        facilityId:
          facilityId != null && facilityId != "undefined"
            ? String(facilityId)
            : 0,
        ...data,
      };
      if (facilityId != 0) {
        let jsonObjects = bodyWithoutConfirmPassword;

        let res = await save(urls.signup.clientSignUp, { jsonObjects });
        if (res?.data?.status == true) {
          navigate("/login");
        }
      } else {
        notify(false, "Please scan facility QR code..");
      }
    } else {
      notify(false, "Password and Confirm Password should be same");
    }
  };
  useEffect(() => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("allQuestion");
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("data");
  }, []);
  const changingPasswordtype2 = () => {
    setConfirmPassword(!confirmPassword);
  };

  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };
  const {
    data,
    errors,
    handleChange,
    handlePhoneChange,
    handleSubmit,
    handleCapitalChange,
  } = useFormValidation({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: {
      email: {
        required: {
          value: true,
          message: "Please enter your email",
        },
        pattern: {
          // value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
          value:
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Please enter a valid email id",
        },
      },
      password: {
        required: {
          value: true,
          message: "Please enter your password",
        },
      },
      confirmPassword: {
        required: {
          value: true,
          message: "Please enter your confirm password",
        },
      },
      firstName: {
        required: {
          value: true,
          message: "Please enter your confirm password",
        },
      },
      lastName: {
        required: {
          value: true,
          message: "Please enter your confirm password",
        },
      },
    },
    submit: submit,
  });

  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "login-input1 bg-white border border-danger"
      : "login-input1 border bg-white ";
  };

  return (
    <Container fluid style={{ background: "#F7F7F7", height: "100vh" }}>
      <Row>
        <Col lg={8} md={4} sm={4} className=" img_login ">
          <img src={signUp} className="img-fluid " />
        </Col>
        <Col className="p-4">
          <div className=" px-2 bg-white border_box  rounded ">
            <form onSubmit={handleSubmit} className="login-formpatient">
              <div className="signin-section ">
                <h4>SignUp to Your Account</h4>
                <p>Take charge of your health</p>
                <div className="row   justify-content-center ">
                  <div className="col-md-6">
                    <label className="">
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      className={emailErrorColor("firstName")}
                      placeholder="First Name"
                      name="firstName"
                      value={dataValue(data?.firstName)}
                      onChange={handleCapitalChange("firstName")}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="">
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      className={emailErrorColor("lastName")}
                      placeholder="Last Name"
                      name="lastName"
                      value={dataValue(data?.lastName)}
                      onChange={handleCapitalChange("lastName")}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="">Phone</label>
                    <br />
                    <input
                      type="text"
                      className="login-input1 bg-white"
                      placeholder="Phone"
                      name="phone"
                      onChange={handlePhoneChange("phone")}
                      value={mulitplePhoneNumberValue(data?.phone)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label className="">ZIP</label>
                    <br />
                    <input
                      type="text"
                      className="login-input1 bg-white"
                      placeholder="ZIP"
                      maxLength={5}
                      name="zipCode"
                      onChange={handlePhoneChange("zipCode")}
                      value={dataValue(data?.zipCode)}
                    />
                  </div>
                  <div className=" col-md-12 ">
                    <lable className for="email">
                      Email <span className="text-danger">*</span>
                    </lable>

                    <input
                      name="email"
                      id="email"
                      onChange={handleChange("email")}
                      placeholder="Email"
                      type="email"
                      className={emailErrorColor("email")}
                    />
                  </div>

                  <div className="col-md-12">
                    <lable className for="password">
                      Password<span className="text-danger">*</span>
                    </lable>
                    <div className="icon-group">
                      <input
                        name="password"
                        id="password"
                        onChange={handleChange("password")}
                        placeholder="Password"
                        type="password"
                        className={emailErrorColor("password")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <lable className for="confirmPassword">
                      Confirm Password<span className="text-danger">*</span>
                    </lable>
                    <div className="icon-group">
                      <input
                        name="confirmPassword"
                        id="confirmPassword"
                        onChange={handleChange("confirmPassword")}
                        placeholder="Confirm Password"
                        type={confirmPassword ? "password" : "text"}
                        className={emailErrorColor("confirmPassword")}
                      />

                      <span className="mt-4" style={{ marginRight: "95px" }}>
                        <a
                          className="passwordshowbtn"
                          onClick={() => changingPasswordtype2()}
                        >
                          <img
                            src={confirmPassword ? hide : view}
                            alt="hide"
                            className="password-toggle-icon"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex d-flex justify-content-center">
                  <button
                    type="submit"
                    class=" button-solid text-white pointer"
                  >
                    Sign Up
                  </button>
                  <div
                    class=" button-solid text-white pointer "
                    onClick={signupasaguest}
                    type="signupasaguest"
                  >
                    Continue as Guest
                  </div>
                </div>
                <p class="text-center">
                  Already a member? &nbsp;
                  <a
                    className="link-hover"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log in
                  </a>
                </p>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
