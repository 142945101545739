// export const baseURL = "http://allistercareapi.hitechhealth.us/api/";
// export const basefileURL = "http://allistercareapi.hitechhealth.us/assets";




export const baseURL = "https://api.allistercare.com/api/";
export const basefileURL = "https://api.allistercare.com/assets";



export const urls = {
  authenticate: {
    login: "Login/Login",
    createPassword: "",
    changePassword: "Login/ChangePassword",
  },

  signup: {
    signUpAsGuest: "SignUp/ContinueAsGuest",
    adminLogin: "SignUp/SignIn",
    clientSignUp: "SignUp/SignUp",
    facilityAdminList: "SignUp/GetFacilityAdminList",
    updateUserDetails:"SignUp/UpdateUserDetails",
    changePassword:"SignUp/ChangePassword"
  },

  screening: {
    questionScreening: "Screening/SaveScreening",
    submitPatientDetails: "Screening/SubmittedPatientDetails",
    qrCode: "Screening/GenerateQRCode",
    getFacilityDropDown: "Screening/GetDropDown",
    qaFinalSubmit: "Screening/FinalSubmit",
    updateStatus: "Screening/UpdateStatus",
    saveDisposition: "Screening/SaveDisposition",
    getDisposition: "Screening/GetDispositionData",
    saveMessage: "Screening/SaveMessage",
  },

  facility: {
    getFacilityList: "Facility/GetFacilityList",
    saveFacility: "Facility/SaveFacility",
    saveImages: "Facility/UploadImage",
    getFacilityImageList: "Facility/GetFacilityImage",
    deleteFacilityImage: "Facility/DeleteFacilityImage",
  },

  carrierandphysician: {
    saveCarrier: "CarrierandPhysician/SaveCarrier",
    savePhysician: "CarrierandPhysician/SavePhysician",
    getCarrier: "CarrierandPhysician/GetCarrierandPhysician",
    getPhysician: "CarrierandPhysician/GetCarrierandPhysician",
    physicianStatus: "CarrierandPhysician/UpdateStatus",
  },

  chat: {
    getChatUsers: "Chat/GetChatUsers",
    saveChat: "Chat/Save",
    chatGetById: "Chat/GetById",
    messageCount: "Chat/UnReadMessageCount",
    userCount: "Chat/UnreadUserChat",

  },
};
