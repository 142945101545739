import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RoutingConfig from "../RoutingConfig";
import WelcomeComponent from "../LandingPage/WelcomeDashboard";
import Header from "../../SharedComponents/Header";
import Story from "../LandingPage/Story";
import Login from "../LandingPage/Login";
import SignUp from "../LandingPage/SignUp";
import AllQuestions from "../AdminPage/AllQuestions";
import QuestionsList from "./QuestionsList";
import Chat from "../AdminPage/Chat";

import QuestinaryDetailView from "../AdminPage/QuestionsDetailsView"
import Authentication from "../PrivetRouting/Authentication";
import { PatientData } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../ConfigFiles/Urls";
import { getList } from "../../ConfigFiles/SharedFunctions";

const ClientPage = () => {
const dispatch =useDispatch()
const state=useSelector((state)=>state?.updateData)
  const getPatientsAnswersList = async () => {
    let body = {
      userId:
        sessionStorage.getItem("roleId") == 2
          ? sessionStorage.getItem("userId")
          : 0,
      facilityId: parseInt(sessionStorage.getItem("facility")),
    };
    let jsonObjects = body;
    let res = sessionStorage.getItem("roleId")&&parseInt(sessionStorage.getItem("facility"))&&await getList(urls?.screening?.submitPatientDetails, {
      jsonObjects,
    });
  
    
    dispatch(PatientData(res?.data?.data[0]))
  };
  useEffect(()=>{
getPatientsAnswersList()
  },[state])
  return (
    <>
      <Routes>
        <Route path="/" element={<Header/>}>
          <Route path="/" element={<WelcomeComponent />} />
          <Route path="/chat" element={<Authentication><Chat /></Authentication> } />
          <Route path="/dashboard" element={<Authentication> <AllQuestions /></Authentication>} />
          <Route path="/questionnaire_view/:screeningId" element={<Authentication><QuestinaryDetailView/></Authentication>}></Route>
          <Route path="/new_questionnaire" element={<Authentication><QuestionsList type={"new"} /></Authentication>} />
          <Route path="/questionnaire_edit/:facilityId/:screeningId" element={<Authentication><QuestionsList /></Authentication>} />
          <Route path="welcome" element={<WelcomeComponent />} />
          <Route path="dashboard/:userId" element={<WelcomeComponent />}    />
          <Route path="about" element={<Story />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="start" element={<SignUp />} />
        </Route>
        <Route path="/*" element={<RoutingConfig />}></Route>
      </Routes>
    </>
  );
};

export default ClientPage;
