import React, { useEffect } from "react";
import Logo from "../../src/Image/logo-c0d9bcbe312d8af35fbe10e083e8d653.png";
import LockIcon from "../../src/Image/AccessDeneid.png";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

const AccessDenied = () => {
  useEffect(()=>{
    sessionStorage.clear()
    localStorage.clear()
  },[])
  return (
    <>
      <Container>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="d-flex flex-column align-items-center">
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: "85px",
                width: "260px",
                padding: "10px",
              }}
            />
            <img src={LockIcon} alt="Lock" />
            <h4> Access Denied</h4>
            <h4>You are accessing an unauthorized page.</h4>
            <NavLink to={"/admin"} className="border rounded">
              Please go to login.
            </NavLink>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AccessDenied;
