import instance from "../Services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { baseURL } from "./Urls";
import { useSelector } from "react-redux";


export const decryPtiedLinks = () => {
  let links = sessionStorage.getItem("accessLinks");
  if (links && links != undefined && links != "") {
    var bytes = CryptoJS.AES.decrypt(links, "Schedyo123");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return [];
  }
};
export const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "600",
      borderRadius: "10%",
    },
  },
  rows: {
    style: {
      ":nth-of-type(even)": {
        backgroundColor: "#F7F7F7",
        borderBottom: "1px solid ##C0C0C0",
      },
      ":nth-of-type(odd)": {
        backgroundColor: "#F7F7F7",
        borderBottom: "1px solid ##C0C0C0",
      },
      ":hover": {
        borderBottom: "none",
      },
    },
  },
  headCells: {
    style: {
      fontSize: "14px",
      fontWeight: "550",
      backgroundColor: "#F7F7F7",
      color: "#11142D",
      hover: "#6f7eed",
      marginLeft: "-12px",
      borderBottom: "1px solid black",
    },
  },

  cells: {
    style: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#212529",
      marginLeft: "-12px",
      borderBottom: "none",
    },
  },
  pagination: {
    style: {
      fontSize: "16px",
      color: "black",
      backgroundColor: "#F7F7F7",
    },
  },
};






export const CheckLink = (linkName) => {
  return decryPtiedLinks()?.includes(linkName);
};


export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "";
  }
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  if (numericPhoneNumber.length === 10) {
    return `(${numericPhoneNumber.slice(0, 3)}) ${numericPhoneNumber.slice(
      3,
      6
    )}-${numericPhoneNumber.slice(6)}`;
  } else {
    return phoneNumber;
  }
};
export const chatServices=async(url,jsonObjects)=>{
  let headers = {"Content-Type": "application/json",Authorization: "Bearer " + sessionStorage.getItem("token"),};
try{
  let res = await axios.post( baseURL + url,{ jsonObjects }, { headers } );
  if (res?.data?.status == true) {
      return res
    } else {
      return []
    }
}
catch(e){
return e
}
}
export const add = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    return res;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const countmsg=(messages,userId)=>{
  
let count=messages?.filter((e) => e?.fromUser ==userId && e?.isRead == "No")?.length

return count>0?count:""
}
export const allmsgcount=(messages,userId)=>{
  let count=messages?.filter((e) => e?.toUser ==userId && e?.isRead == "No")?.length

return count>0?count:""
}
export const jwtDecodedData = () => {
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") != ""
  ) {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};
const phoneFormat = (phoneNumber) => {
  return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
}
export const mulitplePhoneNumberValue = (value) => {
  return (value && value != '') ? phoneFormat(value) : ""
}

export const notify = (status, msg) => {
  if (status) {
    toast.success(msg);
    return <ToastContainer />;
  } else {
    toast.error(msg);
    return <ToastContainer />;
  }
};

export const notifytwo = (status, msg) => {
  if (status) {
    toast.error(msg);
    return <ToastContainer />;
  } 
};

export const logOutFunction = () => {

  switch(sessionStorage.getItem("roleId")){
    case "2":return window.location.pathname = "/", sessionStorage.clear(),localStorage.clear();
    default: return window.location.pathname = "/admin", sessionStorage.clear(),localStorage.clear();
  }
 
};
export const accessLinks=(name)=>{
  const array=['Allistercare','Facility','Facility Users','Carriers','Physician','Uploads']
  switch(sessionStorage.getItem("roleId")){
    case "1" :return (array?.filter((v)=>v).includes(name))//super admin
    case "2" :return (array?.filter((v)=>v=="Allistercare").includes(name))//patient
    case "3" :return (array?.filter((v)=>v=="Allistercare"||v=="Carriers"||v=="Physician"||v=="Uploads").includes(name))//facility admin
    default :return array
  }

}
export const checkAccess=(status)=>{
switch(status){
  case false : return sessionStorage.getItem("roleId")!=2? logOutFunction():clearSession()
 default :return status
}
}
export const disableFun=(roleId,roleThree)=>{
return sessionStorage.getItem("roleId")==roleId || sessionStorage.getItem("roleId")==roleThree?true:false
}
export const clearSession = () => {
  
  sessionStorage.clear();
  localStorage.clear();

  window.location.pathname = "/";
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if(res?.data?.status&&res?.data?.data){
      return res;
    }
    else{
      return []
    }
    
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const getFalseList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == false && Array.isArray(res?.data?.data)) {
      return res.data.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const questionsSave = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    return res;
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const saveFalse = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status === false) {
      return { status: false, data: res?.data };
    } else {
      return { status: true, data: res?.data };
    }
  } catch (e) {
    // console.log(e);
    return {};
  }
};
export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && Object.keys(res?.data?.data)?.length > 0) {
      return res.data.data;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const sortingTable = (a, b, key) => {
  const nameA = a?.[key]?.toUpperCase();
  const nameB = b?.[key]?.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};


