import React, {useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useFormValidation } from '../../../Validations/useFormValidations';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getById } from '../../../ConfigFiles/SharedFunctions';
import { urls } from '../../../ConfigFiles/Urls';
const AppointmentSet = (props) => {
    const [physicianList, setPhysicianList] = useState([]);
    const getPhysicianList = async () => {
      let jsonObjects = {
        facilityId: props?.type?.facilityId,
        type: "Physician",
      };
      let res = await getById(urls?.screening?.getFacilityDropDown, {jsonObjects});
      setPhysicianList(res);
    };
    const submit=()=>{
        data["physicianName"]=physicianList?.length>0  && physicianList?.filter((v) => v?.physicianId == data?.physicianId)[0]?.physicianName
props?.submit(data)
    }
    const {data, handleChange,handleSubmit,handleDateChange, addObject} = useFormValidation({
        initialValues: {
          answertype: "",
          physicianId:"",
          appointmentDate:"",
          time:"",
        },
        validationSchema: {
          answertype: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          physicianId: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          appointmentDate: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          time: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
         
        },
        submit: submit,
      });
      useEffect(()=>{
addObject({answertype:props?.type?.answertype})
      },[props?.type])
      useEffect(()=>{
        if(props?.type?.facilityId){
            getPhysicianList()
        }
      },[props?.type?.facilityId])
      const dataValue=(value)=>{
return value ?value:""
      }
     
  return (
    
    <form onSubmit={handleSubmit} className='px-2'>
      <div className='row'>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        {props?.answerType()}
        </div>

        <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
                <label className="">Select Physician<span className='text-danger'>*</span> </label>

                <select
                  className="form-select"
                  value={dataValue(data.physicianId)}
                  name="physicianId"
                  // disabled={props.show>0}
                  disabled={
                    sessionStorage.getItem("roleId") == "2" 
                  }
                  onChange={handleChange("physicianId")}
                >
                  <option value={""}>Select Physician</option>
                  {physicianList&&physicianList?.map((e, i) => {
                    return (
                      <option key={i} value={e.physicianId}>
                        {e.physicianName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label>Appointment Date<span className='text-danger'>*</span> </label>
                <br />
                <DatePicker
                  className="form-control"
                  selected={
                    data?.appointmentDate && new Date(data?.appointmentDate)
                  }
                  minDate={new Date()}
                  // maxDate={new Date()}
                  onChange={(e) => {
                    handleDateChange(e, "appointmentDate");
                  }}
                  autoComplete="off"
                  name="appointmentDate"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  popperClassName="react-datepicker-popper"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="col-xl-6 col-lg-3 col-md-12 col-sm-12">
                <label>Time <span className='text-danger'>*</span></label>
                <input
                  type="time"
                  placeholder="Time"
                  className="form-control"
                  value={dataValue(data.time)}
                  name="time"
                  onChange={handleChange("time")}
                />
              </div>
    </div>
    <Modal.Footer>
        <button className='float-right btn-save border border-white'>Save</button>
        </Modal.Footer>
    </form>
    
  )
}

export default AppointmentSet