import { Modal, NavLink} from "react-bootstrap";
import { RxCross2} from "react-icons/rx";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormValidation } from "../../Validations/useFormValidations";
import { urls } from "../../ConfigFiles/Urls";
import hide from "../../Image/hide.png";
import view from "../../Image/view.png";
import { getList, mulitplePhoneNumberValue, notify, save } from "../../ConfigFiles/SharedFunctions";


const GuestSignUpModal = (props) => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [password, setPassword] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState(true);
  
    const facilityId = sessionStorage?.getItem("facility");
   
  const signUp=async()=>{
    data["roleId"]=2
    data["password"]=data?.password
    data["email"]=data?.email
    let jsonObjects=data
    let res = await getList(urls?.signup?.adminLogin, { jsonObjects });
    if (res.data?.status == true) {
      localStorage.removeItem("facilityId");
      sessionStorage.setItem("token", res?.data?.token);
      sessionStorage?.setItem("facility", res?.data?.data?.facilityId);
      sessionStorage.setItem("userId", res?.data?.data?.userId);
      sessionStorage.setItem("roleId", res?.data?.data?.roleId);
      sessionStorage.setItem("finalSubmit", res?.data?.data?.finalSubmit);
      sessionStorage.setItem("firstName", res?.data?.data?.firstName);
      sessionStorage.setItem("lastName", res?.data?.data?.lastName);
      sessionStorage.setItem("email", res?.data?.data?.email);
      sessionStorage.setItem("facilityName", res?.data?.data?.facilityName);
      sessionStorage.setItem("questionnaireAttempt", res?.data?.data?.questionnaireAttempt);
  }
  }
    const submit = async () => {
      if (data?.password == data?.confirmPassword) {
        const { confirmPassword, ...bodyWithoutConfirmPassword } = {
          userId: 0,
          roleId: 2,
          type:"Guest",
          screeningId: sessionStorage.getItem("screeningId"),
          facilityId:facilityId,
          ...data,
        };
  
        let jsonObjects = bodyWithoutConfirmPassword;
        let res = await save(urls.signup.clientSignUp, { jsonObjects });
        if (res?.data?.status == true) {
          sessionStorage?.setItem("signup","Yes")
          signUp()

          props?.onHide()
          
          
        }
      } else {
        notify(false, "Password and Confirm Password should be same");
      }
    };
  
    const changingPasswordtype = () => {
      setPassword(!password);
    };
    const changingPasswordtype2 = () => {
      setConfirmPassword(!confirmPassword);
    };
  
    const dataValue = (value) => {
      return value && value != "" ? value : "";
    };
    const {
      data,
      errors,
      handleChange,
      handlePhoneChange,
      handleSubmit,
      handleCapitalChange,
      setV,
    } = useFormValidation({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: {
        email: {
          required: {
            value: true,
            message: "Please enter your email id",
          },
          pattern: {
            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
            message: "Please enter a valid email",
          },
        },
        password: {
          required: {
            value: true,
            message: "Please enter your password",
          },
        },
        confirmPassword: {
            required: {
              value: true,
              message: "Please enter your confirm password",
            },
          },

          firstName: {
            required: {
              value: true,
              message: "Please enter your confirm password",
            },
          },

          lastName: {
            required: {
              value: true,
              message: "Please enter your confirm password",
            },
          },
      },
      submit: submit,
    });

    const emailErrorColor = (key) => {
        return errors && errors?.[key]
          ? "login-input1 bg-white border border-danger"
          : "login-input1 border bg-white ";
      };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter"><h4>Sign Up</h4>
               </Modal.Title>
       
        <div>
          <div className="pointer" onClick={props?.onHide}>
            <RxCross2 size={25} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
       
        <div className="bg-white  align-items-center justify-content-center">
          <div className="">
            <form onSubmit={handleSubmit} className="login-formpatient">
              <div className="signin-section">
                
                <div className="">
                  <div className="d-flex">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <label className="">First Name<span className="text-danger">*</span></label>
                      <input
                        // className="form-control bg-white"
                        className={emailErrorColor("firstName")}
                        placeholder="First Name"
                        name="firstName"
                        value={dataValue(data?.firstName)}
                        onChange={handleCapitalChange("firstName")}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ms-1">
                      <label className="">Last Name<span className="text-danger">*</span></label>
                      <input
                        // className="form-control bg-white "
                        className={emailErrorColor("lastName")}
                        placeholder="Last Name"
                        name="lastName"
                        value={dataValue(data?.lastName)}
                        onChange={handleCapitalChange("lastName")}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <label className="">Phone</label>
                    <br />
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder="Phone"
                    
                      name="phone"
                    
                      onChange={handlePhoneChange("phone")}
                value={mulitplePhoneNumberValue(data?.phone)}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ms-1">
                    <label className="">ZIP</label>
                    <br />
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder="ZIP"
                      maxLength={5}
                      name="zipCode"
                      onChange={handlePhoneChange("zipCode")}
                      value={dataValue(data?.zipCode)}
                    />
                  </div>
                  </div>
                 

                  <div className="  ">
                  <lable className for="email">
                    Email<span className="text-danger">*</span>
                  </lable>
                
                    <input
                      name="email"
                      id="email"
                      onChange={handleChange("email")}
                      placeholder="Email"
                      type="email"
                      className={emailErrorColor("email")}
                    />
                  </div>
                  {/* <span className=" ">
                    {errors && errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </span> */}
                </div>

                <div className="">
                  <lable className for="password">
                    Password<span className="text-danger">*</span>
                  </lable>
                  <div className="icon-group">
                    <input
                      name="password"
                      id="password"
                      onChange={handleChange("password")}
                      placeholder="Password"
                      type="password"
                      className={emailErrorColor("password")}
                    />
                    {/* <span
                      className="mt-3"
                      style={{ marginRight: "90px" }}
                    ></span> */}

                  </div>
                  {/* <span className=" ">
                    {errors && errors.password && (
                      <p className="text-danger">{errors.password}</p>
                    )}
                  </span> */}
                </div>
                <div className="">
                  <lable className for="confirmPassword">
                    Confirm Password<span className="text-danger">*</span>
                  </lable>
                  <div className="icon-group">
                    <input
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={handleChange("confirmPassword")}
                      placeholder="Confirm Password"
                      type={confirmPassword ? "password" : "text"}
                      className={emailErrorColor("confirmPassword")}
                    />

                    <span className="mt-4" style={{ marginRight: "5px",}}>
                      <a
                        className="passwordshowbtn"
                        onClick={() => changingPasswordtype2()}
                      >
                        <img
                          src={confirmPassword ? hide : view}
                          alt="hide"
                          className="password-toggle-icon"
                        />
                      </a>
                    </span>
                  </div>
                </div>
               
                <div className="d-flex d-flex justify-content-center">
                  <button
                    type="submit"
                    class=" button-solid text-white pointer"
                  >
                    Sign Up
                  </button>
                
                </div>
          
                {/* <p class="signin-links"><a class="font-accent" href="/terms">Terms</a>|<a class="font-accent" href="/privacy">Privacy</a></p> */}
              </div>
            </form>
          </div>
        </div>


         
        
      </Modal.Body>
    </Modal>
  );
};

export default GuestSignUpModal;
