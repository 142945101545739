import React, { useEffect, useRef, useState } from "react";
import Logo from "../Image/logo-c0d9bcbe312d8af35fbe10e083e8d653.png";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { allmsgcount, clearSession, countmsg, disableFun } from "../ConfigFiles/SharedFunctions";

import { IoChatboxSharp } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoIosMenu } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { BsFillQuestionDiamondFill } from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { BiEditAlt } from "react-icons/bi";
import PatientEditModal from "../Components/AdminPage/PatientEditModal";
const Header = () => {
  const [show, setShow] = useState(false)
  const [navArray, setnavArray] = useState([])
  const navigate = useNavigate()
  const [update, setUpdate] = useState([]);
  const [patientEdit, setPatientEdit] = useState("");
  let messages = useSelector((state) => state.messages);
  let patientData = useSelector((state) => state.patientData);
  const menu = [
    { name: "About", link: "about" },
    { name: "Start", link: sessionStorage?.getItem("guestUserId")&&sessionStorage.getItem("terms")=="Yes" ? "/allQuestionary" : "start" },
    { name: "Sign In", link: "login" },
    { name: "Sign Up", link: "signup" },
  ]
  const dashboard = [
    { name: "Dashboard", link: "dashboard", logo: <MdOutlineDashboardCustomize className="mt-1" /> },
    { name: "Chat", link: "chat", count: true, logo: <IoChatboxSharp className="mt-1" /> },
    { name: "Questionnaire", link: "terms", logo: <BsFillQuestionDiamondFill className="mt-1" /> },

  ]

  const location = useLocation();

  useEffect(() => {
    let menuval = sessionStorage?.getItem("guestUserId") ? menu?.filter((val) => val?.name != "Sign Up" && val?.name != "Sign In") : menu

    setnavArray(sessionStorage.getItem("token")? dashboard : menuval)

  }, [location.pathname]);
  
  const headerPart = () => {
    let firstName = sessionStorage.getItem("firstName");
    let lastName = sessionStorage.getItem("lastName");
    let email = sessionStorage.getItem("email");
    const fullName = firstName + " " + lastName;
    

    return (
      <>

        <div className="row">
          <div className="">
            <div className="d-inline-block text-start">
              <FaRegUserCircle className="spinprofile me-1 " title={fullName} size={25} />
             
                <span title={fullName}>{patientData?.userName?patientData?.userName:fullName}
                 {disableFun("2") && (
                  <BiEditAlt className="pointer text-info" title="Edit" onClick={() => setPatientEdit(true)} />
                )}
                </span>
              

              
              <br />

              {disableFun("2") && email !== "undefined" && email !== null && (
                <span className="font-weight-bold  mx-4" title={email}>{email}</span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
 
  return (
    <div >


      <Container className="border-0 border-bottom position-fixed  top-0" fluid style={{ background: "#F7F7F7",zIndex:"1000" }}>
        <Navbar  expand="lg" style={{ background: "#F7F7F7" }}>


          <NavLink className='col-2  bg-light ' to="/welcome"><img src={Logo} className=' mobile_img' /></NavLink>

          <Navbar.Toggle aria-controls="basic-navbar-na " onClick={() => setShow(!show)}>{show ? <RxCross1 size={25} /> : <IoIosMenu size={25} />}</Navbar.Toggle>
          <Navbar.Collapse  id={"basic-navbar-nav"} className=" col-10 justify-content-md-end py-2" style={sessionStorage.getItem("token") ? { paddingRight: "0rem" } : { paddingRight: "4rem" }}>
            {<Nav className="d-flex gap-3" >
              {navArray?.map((v) => {
                return (
                  <NavLink className={({ isActive }) => (isActive ? " btn-printheader active   px-3 d-flex gap-1 " : "d-flex  gap-1  btn-printheader px-3   text-secondary ")}
                    to={v?.link}>
                    {v?.logo} {v?.name == "Chat" ? <span class=" position-relative ">
                      {v?.name}
                     { v?.count && allmsgcount(messages, sessionStorage.getItem("userId")) && <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success text-white">
                     {allmsgcount(messages, sessionStorage.getItem("userId"))}
                        <span class="visually-hidden">unread messages</span>
                      </span>}
                    </span>
                      : v?.name} {
                      
                    }
                  </NavLink>
                )
              })}
              {(sessionStorage.getItem("roleId") == "2" || sessionStorage?.getItem("guestUserId")) && <div className='btn-printheader px-3 d-flex gap-1  text-secondary' onClick={clearSession}><IoMdLogOut className="mt-1" /> Sign Out</div>}
              {sessionStorage.getItem("roleId") == "2" && headerPart()}
            </Nav>}
          </Navbar.Collapse>




        </Navbar>
       

      </Container>
      <div className="marginTop">
        <Outlet />
      </div>

      {patientEdit && (
          <PatientEditModal
            show={patientData}
            updateList={setUpdate}
            onHide={() => setPatientEdit(false)}
          />
        )}
    </div>
  );
};
export default Header;