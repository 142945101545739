import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import hide from "../../Image/hide.png";
import view from "../../Image/view.png";
import { urls } from "../../ConfigFiles/Urls";
import { useFormValidation } from "../../Validations/useFormValidations";
import {  save } from "../../ConfigFiles/SharedFunctions";

import signUp from "../../Image/login_page.png";
import { Col, Container, Row } from "react-bootstrap";
const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);

  const changingPasswordtype = () => {
    setPassword(!password);
  };

  const submit = async () => {
    data["roleId"] = 2;
    let jsonObjects = data;
    let res = await save(urls?.signup?.adminLogin, { jsonObjects });

    if (res.data?.status == true) {
      localStorage.removeItem("facilityId");
      sessionStorage.setItem("token", res?.data?.token);
      sessionStorage?.setItem("facility", res?.data?.data?.facilityId);
      sessionStorage.setItem("userId", res?.data?.data?.userId);
      sessionStorage.setItem("roleId", res?.data?.data?.roleId);
      sessionStorage.setItem("finalSubmit", res?.data?.data?.finalSubmit);
      sessionStorage.setItem("firstName", res?.data?.data?.firstName);
      sessionStorage.setItem("lastName", res?.data?.data?.lastName);
      sessionStorage.setItem("email", res?.data?.data?.email);
      sessionStorage.setItem("facilityName", res?.data?.data?.facilityName);
      sessionStorage.setItem("questionnaireAttempt", res?.data?.data?.questionnaireAttempt);
      { 
        sessionStorage.getItem("roleId") == "2" &&
        res?.data?.data?.questionnaireAttempt == "Yes"
          ? navigate("/dashboard")
          : navigate("/terms");
      }
    }
  };

  
  const { data, errors, handleChange, handleSubmit, setV } = useFormValidation({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: {
      email: {
        required: {
          value: true,
          message: "Please enter your email id",
        },
        pattern: {
          // value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
          value:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Please enter a valid email id",
        },
      },
      password: {
        required: {
          value: true,
          message: "Please enter your password",
        },
      },
    },
    submit: submit,
  });

  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "login-input1 bg-white border border-danger"
      : "login-input1 border bg-white ";
  };
  useEffect(()=>{
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("userId")
    sessionStorage.removeItem("allQuestion");
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("data");
  },[])
  
  return (
    <Container fluid style={{background:"#F7F7F7",height:"100vh"}}>
<Row >
  <Col lg={8} md={4} sm={4} className={"img_login"}><img src={signUp} className="img-fluid "/></Col>
  <Col  className="p-4" style={{marginTop:"6rem"}}>
  <div className="p-2 bg-white border_box  rounded " >
            <form onSubmit={handleSubmit} className="login-formpatient">
            
              <div className="signin-section">
                <h4>Sign In to Your Account</h4>
                <p>Take charge of your health</p>
                <div className=" mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  
                  <lable className for="email">
                    Email<span className="text-danger">*</span>
                  </lable>
                  <div className="icon-group">
                    <input
                      name="email"
                      id="email"
                      onChange={handleChange("email")}
                      placeholder="Email"
                      type="email"
                      className={emailErrorColor("email")}
                    />
                   
                  </div>
                 
                </div>

                <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <lable className for="password">
                    Password<span className="text-danger">*</span>
                  </lable>
                  <div className="icon-group">
                    <input
                      name="password"
                      id="password"
                      onChange={handleChange("password")}
                      placeholder="Password"
                      type={password?"password":"text"}
                      className={emailErrorColor("password")}
                    />
                       <span className="mt-4" style={{marginRight:"75px"}}>
                  <a
                          className="passwordshowbtn"
                          onClick={() => changingPasswordtype()}
                          >
                          <img
                              src={password ?  hide : view }
                              alt="hide"
                            
                              className="password-toggle-icon "
                          />
                          </a>
                  </span>
              
                  </div>
              
                </div>
                <button type="submit" class="button-solid-sigin  text-white mt-4" 
                
                  >
                  Sign In
                </button>
                <p class="text-center">
                  Not a member?&nbsp;
                  <a
                    className="link-hover"
                   
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    Sign up
                  </a>
                </p>
                
              </div>
            </form>
          </div></Col>
</Row>
    </Container>

  );
};

export default Login;
