import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormValidation } from "../../../Validations/useFormValidations";
import { GrClose } from "react-icons/gr";
import NotInterest from "./NotInterest";
import AppointmentSet from "./Appointmentset";
import CallBack from "./CallBack";
import ContactedInPerson from "./ContactedInPerson";
const DifferentLanguage = (props) => {
  const submit = () => {
    props?.submit(data);
  };
  const {
    data,
    handleChange,
    handleSubmit,

    addObject,
  } = useFormValidation({
    initialValues: {
      answertype: "",
      language: "",
    },
    validationSchema: {
      answertype: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
      language: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
    },
    submit: submit,
  });
  useEffect(() => {
    addObject({ answertype: props?.type?.answertype });
  }, [props?.type]);
  
  return (
    <Modal
      {...props}
      size="lg"
      //   backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Answered</Modal.Title>
        <div>
          <div className="pointer" onClick={props?.onHide}>
            <GrClose size={20} />
          </div>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              {(props?.type?.answertype != "Not Interested" &&
                props?.type?.answertype != "Appointment Set" &&
                props?.type?.answertype != "Call Back" &&
                props?.type?.answertype != "Contacted In Person") &&
                props?.type?.answertype != "Contacted by Phone" &&
                props?.type?.answertype != "Contacted by Email" &&
                props?.answerType()}
            </div>

            {props?.type?.answertype == "Different Language" && (
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <label>
                  Select Language <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  value={data?.language}
                  name="language"
                  onChange={handleChange("language")}
                >
                  <option>Select Language</option>
                  <option>Spanish</option>
                  <option>French</option>
                  <option>German</option>
                  <option>Russian</option>
                </select>
              </div>
            )}
          </div>
        </Modal.Body>

        {props?.type?.answertype == "Different Language" && (
          <Modal.Footer>
            <button className="float-right btn-save border border-white">
              Save
            </button>
          </Modal.Footer>
        )}
      </form>
      {props?.type?.answertype == "Not Interested" && (
        <NotInterest
          answerType={props?.answerType}
          type={props.type}
          submit={props?.submit}
          onHide={props?.closeModel}
        />
      )}

      {props?.type?.answertype == "Appointment Set" && (
        <AppointmentSet
          answerType={props?.answerType}
          type={props.type}
          submit={props?.submit}
          onHide={props?.closeModel}
        />
      )}
      {props?.type?.answertype == "Call Back" && (
        <CallBack
          answerType={props?.answerType}
          type={props.type}
          submit={props?.submit}
          onHide={props?.closeModel}
        />
      )}
      {/* 
{props?.type?.answertype == "Contacted In Person"  && (
        <ContactedInPerson
          answerType={props?.answerType}
          type={props.type}
          submit={props?.submit}
          onHide={props?.closeModel}
        />
      )} */}
      {(props?.type?.answertype === "Contacted In Person" ||
        props?.type?.answertype === "Contacted by Phone" ||
        props?.type?.answertype === "Contacted by Email") && (
        <ContactedInPerson
          answerType={props?.answerType}
          type={props.type}
          submit={props?.submit}
          onHide={props?.closeModel}
        />
      )}
    </Modal>
  );
};

export default DifferentLanguage;
