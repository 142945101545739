const initalState = {
    image:[],
    update:0,
    facilityList:[],
    messages:[],
    loggedInUserId: sessionStorage?.getItem("userId") || 0,
    selectedUserId:0,
    updateFacility:[],
    patientData:[],
    updateData:[]
    
  };
  export const ReducerdData= (state = initalState, action) => {
    switch (action.type) {
      case "IMAGEUPLOAD":return { ...state, image: action?.data };
      case "IMAGEUPDATE":return {...state, update: action?.data };
      case "FACILITY_LIST":return {...state, facilityList: action?.data };
      case "UPDATE_MESSAGES":
        return { ...state, messages: action?.data };
        case "UPDATE_FACILITY":
        return { ...state, updateFacility: action?.data };
        case "UPDATE_USERID":
        return { ...state, loggedInUserId: action?.data };
        case "PROFILE":
        return { ...state, patientData: action?.data };
        case "UPDATE":
          return { ...state, updateData: action?.data };
      default:
        return state;
    }
  };