import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import {
  disableFun,
  getList,
  mulitplePhoneNumberValue,
  save,
  sortingTable,
} from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";

import { BiEditAlt, BiSolidEdit } from "react-icons/bi";
import PatientsDetailsEditModal from "./PatientsModal";
import { useMediaQuery } from "react-responsive";
import { FaRegUserCircle } from "react-icons/fa";
import moment from "moment";
import PatientDispositionModal from "./PatientDispositionModal";
import AllPatientsTable from "../../SharedComponents/AllPatientsTable";
import MessagestopatientsModal from "./MessagestopatientsModal";
import {
  TbLetterD,
  TbLetterQ,
  TbSquareLetterA,
  TbSquareLetterC,
} from "react-icons/tb";

import { NavLink } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import PatientEditModal from "./PatientEditModal";

import AllPatient from "../../SharedComponents/AllPatient";
import { useDispatch, useSelector } from "react-redux";
import { PatientData } from "../../Redux/Action";
import AdminPasswordUpdateModal from "./AdminPasswordUpdateModal";
import { Dropdown } from "react-bootstrap";

const AllQuestions = () => {
  const navigate = useNavigate();
  const [update, setUpdate] = useState([]);
  const [allpatient, setAllPatient] = useState();
  const [filterlist, setFilterList] = useState([]);
  const [NCDate, setNCDate] = useState([]);
  const [patientId, setpatientId] = useState("");
  const [patientEdit, setPatientEdit] = useState("");
  const [adminPasswordEdit, setAdminPasswordEdit] = useState("");
  const [patientDispositionId, setPatientDispositionId] = useState("");
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);
  const [messagepatientId, setMessagepatientId] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const patientData = useSelector((state) => state.updateData);
  const isMobile = useMediaQuery({ maxWidth: "576px" });
  const dispatch = useDispatch();
  const getPatientsAnswersList = async () => {
    let body = {
      userId:
        sessionStorage.getItem("roleId") == 2
          ? sessionStorage.getItem("userId")
          : 0,
      facilityId: parseInt(sessionStorage.getItem("facility")),
    };
    let jsonObjects = body;
    let res = await getList(urls?.screening?.submitPatientDetails, {
      jsonObjects,
    });

    res?.data?.data?.map((v) => {
      v["submittedDate"] =
        v?.submittedDate != "-"
          ? moment(v?.submittedDate)?.format("MM/DD/YYYY hh:mm A")
          : "-";
      v["NCDate"] = v?.answers[0]?.NCDate
        ? moment(v?.answers[0]?.NCDate)?.format("YYYY MMM")
        : "-";
      v["selected"] = false;
    });
    setFilterList(res?.data?.data);

    setNCDate(
      res?.data?.data?.filter(
        (v) => v?.submittedDate != "-" && v?.answers[0]
      )[0]?.answers[0]
    );
    dispatch(PatientData(res?.data?.data[0]));
  };
  const handleSelectAll = () => {
    const patients = filterlist?.map((patient) => ({
      ...patient,
      selected: !selectAll,
    }));
    setFilterList(patients);
    setSelectAll(!selectAll);
  };
  const handleCheckboxChange = (id) => () => {
    const patients = filterlist?.map((patient) =>
      patient.screeningId === parseInt(id)
        ? { ...patient, selected: !patient.selected }
        : patient
    );
    setFilterList(patients);
  };
  const handleChangeStatus = (screeningId) => async (e) => {
    e.preventDefault();
    if (screeningId) {
      let body = {
        status: e.target.value,
        screeningId: screeningId,
      };
      let jsonObjects = body;
      let res = await save(urls?.screening?.updateStatus, { jsonObjects });
      if (res?.data?.status) {
        setUpdate(res);
      }
    }
  };
  const handleRowSelection = (selectedRows) => {
    const patientIds = selectedRows?.map((row) => row.screeningId);
    setSelectedPatientIds(patientIds);
  };

  const columns = [
    {
      name: sessionStorage.getItem("roleId") != 2 &&
        sessionStorage.getItem("roleId") != 1 && (
          <input
            type="checkbox"
            className="ms-1 mt-1  mb-1"
            style={{ transform: "scale(1.2)" }}
            checked={selectAll}
            onChange={handleSelectAll}
          />
        ),
      cell: (row) =>
        sessionStorage.getItem("roleId") != 2 &&
        sessionStorage.getItem("roleId") != 1 && (
          <input
            type="checkbox"
            checked={row?.selected}
            name="userId"
            className="ms-1"
            style={{ transform: "scale(1.2)" }}
            onChange={handleCheckboxChange(row?.screeningId)}
          />
        ),
      hide: "sm",

      width: sessionStorage.getItem("roleId") !== "2" ? "55px" : "0",
    },
    {
      name: (
        <div>
          Name /<br></br>Email
        </div>
      ),
      selector: (row) => (
        <div
          className=""
          title={`${
            row?.userName !== "" ? `Name: ${row?.userName}` : "-"
          } / Email: ${row?.email}`}
        >
          {row?.userName}
          <br />
          {row?.userName && row?.userName == "Guest" && row?.ipAddress}
          {row?.email !== "" ? row?.email : ""}
        </div>
      ),
      sortable: true,
      hide: "sm",
      width: sessionStorage.getItem("roleId") !== "2" ? "185px" : "0",
      sortFunction: (a, b) => sortingTable(a, b, "userName"),
    },

    {
      name: "Facility",
      selector: (row) => (
        <div className="text-wrap" title={`${row?.facilityName}`}>
          {row?.facilityName}
        </div>
      ),
      sortable: true,
      // width: "160px",
      width: sessionStorage.getItem("roleId") === "2" ? "200px" : "160px",
      hide: "sm",
      sortFunction: (a, b) => sortingTable(a, b, "facilityName"),
    },
    {
      name: (
        <div>
          Appointment Set /<br></br>Call Back
        </div>
      ),
      selector: (row) => (
        <div className="">
          {row?.callStatus?.appointmentDate &&
          row?.callStatus?.appointmentDate !== "" ? (
            <div>
              <span>
                {moment(row?.callStatus?.appointmentDate).format("MM/DD/YYYY")}{" "}
                <TbSquareLetterA
                  title="Appointment Set"
                  className="pointer"
                  size={18}
                />
              </span>
            </div>
          ) : row?.callStatus?.callBackDate &&
            row?.callStatus?.callBackDate !== "" ? (
            <div>
              <span>
                {moment(row?.callStatus?.callBackDate).format("MM/DD/YYYY")}{" "}
                <TbSquareLetterC
                  title="Call Back"
                  className="pointer"
                  size={18}
                />
              </span>
            </div>
          ) : (
            <div>
              <span>-</span>
            </div>
          )}

          {row?.callStatus?.time && row?.callStatus?.time !== "" ? (
            moment(row?.callStatus?.time, "HH:mm:ss").format("hh:mm A")
          ) : row?.callStatus?.callBackTime &&
            row?.callStatus?.callBackTime !== "" ? (
            moment(row?.callStatus?.callBackTime, "HH:mm:ss").format("hh:mm A")
          ) : (
            <span></span>
          )}
        </div>
      ),

      hide: "sm",

      //  width: "180px",
      width: sessionStorage.getItem("roleId") !== "2" ? "180px" : "0",
    },
    {
      name: (
        <div>
          Phone /<br></br>Zip
        </div>
      ),
      selector: (row) => (
        <div
          className="text-wrap"
          title={`${
            row?.phone !== ""
              ? `PH: ${mulitplePhoneNumberValue(row?.phone)}`
              : "-"
          } / ZIP: ${row?.zipCode}`}
        >
          {row?.phone != "" ? mulitplePhoneNumberValue(row?.phone) : "-"}
          {row?.zipCode && row?.zipCode != "" && " /"}
          <br></br>
          {row?.zipCode}
        </div>
      ),
      sortable: true,
      hide: "sm",
      width: sessionStorage.getItem("roleId") === "2" ? "200px" : "135px",
      sortFunction: (a, b) => sortingTable(a, b, "phone"),
    },

    isMobile
      ? {
          name: (
            <div>
              {sessionStorage.getItem("roleId") != 2 &&
                sessionStorage.getItem("roleId") != 1 && (
                  <input
                    type="checkbox"
                    className="ms-1 mt-1  mb-1"
                    style={{ transform: "scale(1.2)" }}
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                )}
            </div>
          ),
          selector: (row) => (
            <div className="row  w-100  ">
              <div>
                {sessionStorage.getItem("roleId") != 2 &&
                  sessionStorage.getItem("roleId") != 1 && (
                    <input
                      type="checkbox"
                      checked={row?.selected}
                      name="userId"
                      className="ms-1 mt-1"
                      style={{ transform: "scale(1.2)" }}
                      onChange={handleCheckboxChange(row?.screeningId)}
                    />
                  )}
              </div>

              {sessionStorage.getItem("roleId") !== "2" && (
                <div className="row">
                  <a className="accordcontent-header col-3">Name</a>{" "}
                  <div className="col-9">
                    : {row?.userName}
                    {row?.ipAddress != "" && <br />}
                    {row?.ipAddress}
                  </div>
                </div>
              )}

              <div className=" row">
                <a className="accordcontent-header col-3">Facility</a>{" "}
                <div className="col-9">: {row?.facilityName}</div>
              </div>

              <div className=" row">
                <a className="accordcontent-header col-3">
                  Appt Set/<br></br>Call Back
                </a>{" "}
                <div className="col-9">
                  :{" "}
                  {row?.callStatus?.appointmentDate &&
                  row?.callStatus?.appointmentDate !== "" ? (
                    <div>
                      <span>
                        {moment(row?.callStatus?.appointmentDate).format(
                          "MM/DD/YYYY"
                        )}{" "}
                        <TbSquareLetterA
                          title="Appointment Set"
                          className="pointer"
                          size={18}
                        />
                      </span>
                    </div>
                  ) : row?.callStatus?.callBackDate &&
                    row?.callStatus?.callBackDate !== "" ? (
                    <span>
                      {moment(row?.callStatus?.callBackDate).format(
                        "MM/DD/YYYY"
                      )}{" "}
                      <TbSquareLetterC
                        title="Call Back"
                        className="pointer"
                        size={18}
                      />
                    </span>
                  ) : (
                    <span>-</span>
                  )}
                  {row?.callStatus?.time && row?.callStatus?.time !== "" ? (
                    moment(row?.callStatus?.time, "HH:mm:ss").format("hh:mm A")
                  ) : row?.callStatus?.callBackTime &&
                    row?.callStatus?.callBackTime !== "" ? (
                    moment(row?.callStatus?.callBackTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>

              <div className=" row">
                <a className="accordcontent-header col-3">Email</a>{" "}
                <div className="col-9">: {row?.email}</div>
              </div>

              <div className=" row">
                <a className="accordcontent-header col-3">Phone</a>{" "}
                <div className="col-9">
                  {" "}
                  :{" "}
                  {row?.phone != ""
                    ? mulitplePhoneNumberValue(row?.phone)
                    : "-"}
                </div>
              </div>
              <div className=" row">
                <a className="accordcontent-header col-3">Zip</a>{" "}
                <div className="col-9">: {row?.zipCode}</div>
              </div>

              <div className="row">
                <a className="accordcontent-header col-3 ">Status</a>
                <div className="col-9">
                  {" "}
                  :{" "}
                  <select
                    disabled={disableFun("2")}
                    className={
                      disableFun("2")
                        ? "border-0 p-1  "
                        : row.status &&
                          "col-md-12 medium  border border-white light-blue text-white rounded p-1"
                    }
                    title={
                      row?.status === "Successfull" || row?.status === "Pending"
                        ? row?.status
                        : row?.callStatus?.answertype
                    }
                    value={row?.status}
                    onChange={handleChangeStatus(row?.screeningId)}
                    style={{ cursor: "pointer" }}
                  >
                    <option disabled>Appointment Set</option>
                    <option disabled>Contacted by Phone</option>
                    <option disabled>Contacted by Email</option>
                    <option disabled>Contacted In Person</option>
                    <option disabled>Call Back</option>
                    <option disabled>Different Language</option>
                    <option disabled>Not Answered</option>
                    <option disabled>Not Interested</option>
                    <option className="bg-blue text-black">Pending</option>
                    <option className="bg-blue text-black">Successfull</option>
                    <option disabled>Voice Mail</option>
                    <option disabled>Wrong Number</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <a className="accordcontent-header col-3">
                  Submit <br></br> Date
                </a>{" "}
                <div className="col-9">
                  :{" "}
                  {row?.submittedDate != "-"
                    ? moment(row?.submittedDate)?.format("MM/DD/YYYY")
                    : "-"}{" "}
                  {row?.submittedDate != "-" &&
                    moment(row?.submittedDate)?.format(", HH:mm A")}
                </div>
              </div>

              <div className="row">
                <a className="accordcontent-header col-3">
                  <div>
                    Next<br></br>Colonoscopy
                  </div>
                </a>{" "}
                <div className="col-9">
                  {!row?.answers[0]?.NCDate ||
                  row?.answers[0]?.NCDate === "-" ||
                  row?.answers[0]?.NCDate === "Invalid date" ? (
                    <span className="" title="No Date Available">
                      -
                    </span>
                  ) : (
                    <div
                      className=""
                      title={
                        row?.submittedDate !== "-" && row?.answers[0]?.NCDate
                      }
                    >
                      :{" "}
                      {row?.submittedDate !== "-"
                        ? moment(row?.answers[0]?.NCDate)?.format("YYYY MMM")
                        : "-"}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex row">
                <a className="accordcontent-header col-3">Action</a>
                <div className="col-9 ">
                  :
                  <TbLetterQ
                    title={"Edit"}
                    onClick={() => {
                      navigate(`detailsview/${row?.screeningId}`);
                    }}
                    color="#45A6DD"
                    size="1.70rem"
                  />
                  <BiSolidEdit
                    title={"QR Code"}
                    onClick={() => setpatientId(row)}
                    color="#45A6DD"
                    size="1.70rem"
                  />
                  {disableFun("3") && (
                    <TbLetterD
                      title="Disposition"
                      className=" "
                      style={{ cursor: "pointer" }}
                      onClick={() => setPatientDispositionId(row)}
                      size="1.70rem"
                      color="#45A6DD"
                    />
                  )}
                  {disableFun("2") && row?.submittedDate == "-" && (
                    <NavLink
                      to={`/allQuestionary/${row?.facilityId}/${row?.screeningId}`}
                    >
                      <FaQuestionCircle
                        size={18}
                        title={"Edit Questinarie"}
                        color="#45A6DD"
                      />
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          ),
        }
      : { width: "0px" },

    {
      name: "Connection Status",

      selector: (row) => (
        <select
          disabled={disableFun("2")}
          className={
            disableFun("2")
              ? "border-0 p-1"
              : row.status &&
                "col-md-12 medium  border border-white light-blue text-white rounded p-1"
          }
          title={
            row?.status === "Successfull" || row?.status === "Pending"
              ? row?.status
              : row?.callStatus?.answertype
          }
          value={row?.status}
          onChange={handleChangeStatus(row?.screeningId)}
          style={{ cursor: "pointer" }}
        >
          <option disabled>Appointment Set</option>
          <option disabled>Contacted by Phone</option>
          <option disabled>Contacted by Email</option>
          <option disabled>Contacted In Person</option>
          <option disabled>Call Back</option>
          <option disabled>Different Language</option>
          <option disabled>Not Answered</option>
          <option disabled>Not Interested</option>
          <option className="bg-blue text-black">Pending</option>
          <option className="bg-blue text-black">Successfull</option>
          <option disabled>Voice Mail</option>
          <option disabled>Wrong Number</option>
        </select>
      ),
      sortable: true,
      hide: "sm",
      width: sessionStorage.getItem("roleId") === "2" ? "174px" : "175px",
      sortFunction: (a, b) => sortingTable(a, b, "status"),
    },

    {
      name: "Submission Date",
      width: "160px",
      width: sessionStorage.getItem("roleId") === "2" ? "200px" : "160px",
      selector: (row) => (
        <div className="text-wrap" title={`${row?.submittedDate}`}>
          {row?.submittedDate != "-"
            ? moment(row?.submittedDate)?.format("MM/DD/YYYY")
            : "-"}
          <br />
          {row?.submittedDate != "-" &&
            moment(row?.submittedDate)?.format("hh:mm A")}
        </div>
      ),
      sortable: true,
      hide: "sm",
      sortFunction: (a, b) => sortingTable(a, b, "submittedDate"),
    },

    {
      name: (
        <div>
          {sessionStorage.getItem("roleId") === 2
            ? "Next Colonoscopy"
            : "Next Colonoscopy"}
          <br></br>
          {sessionStorage.getItem("roleId") === 2 && "Colonoscopy"}
        </div>
      ),

      selector: (row) => {
        const ncDate = row?.answers[0]?.NCDate;

        if (!ncDate || ncDate === "-" || ncDate == "Invalid date") {
          return (
            <div className="" title="No Date Available">
              -
            </div>
          );
        }

        return (
          <div className="" title={row?.submittedDate != "-" && ncDate}>
            {row?.submittedDate != "-" &&
            (row?.answers[0]?.cancerremoved != "No" ||
              row?.answers[0]?.cancerremoved?.length == "0")
              ? moment(ncDate)?.format("YYYY MMM")
              : "-"}
          </div>
        );
      },
      width: sessionStorage.getItem("roleId") === "2" ? "200px" : "134px",
      hide: "sm",
    },
    {
      name: "Type",
      selector: (row) => (
        <div className="" title="Colon">
          Colon
        </div>
      ),
      sortable: true,
      width: sessionStorage.getItem("roleId") === "2" ? "130px" : "82px",
      hide: "sm",
      sortFunction: (a, b) => sortingTable(a, b, "facilityName"),
    },

    {
      name: "Risk",
      selector: (row) => {
        if (row?.submittedDate !== "-") {
          if (
            row?.answers[0]?.Order === "13" ||
            row?.answers[0]?.Order === "14"
          ) {
            return <span title="AVERAGE">AVERAGE</span>;
          } else if (row?.answers[0]?.Order === "15") {
            return <span className="text-muted">-</span>;
          } else {
            return (
              <span className="text-danger" title="INCREASED">
                INCREASED
              </span>
            );
          }
        } else {
          return <span className="text-muted">-</span>;
        }
      },
      // sortable: true,
      hide: "sm",
      width: sessionStorage.getItem("roleId") === "2" ? "180px" : "119px",
      sortFunction: (a, b) => sortingTable(a, b, "facilityName"),
    },

    {
      name: "Action",
      hide: "sm",
      selector: (row) => (
        <div className="d-flex gap-1 align-items-center ">
          <div
            title="Questionnaire"
            className="text-info answers-view"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                disableFun("2")
                  ? `/questionnaire_view/${row?.screeningId}`
                  : `detailsview/${row?.screeningId}`
              );
            }}
          >
            <TbLetterQ size={20} color="#45A6DD" className="" />
          </div>

          <div
            title="Edit"
            className="text-info "
            style={{ cursor: "pointer" }}
            onClick={() => setpatientId(row)}
          >
            <BiSolidEdit size={20} color="#45A6DD" className=" " />
          </div>

          {disableFun("3") &&
            row?.status != "Successfull" &&
            row?.submittedDate !== "-" && (
              <div
                title="Disposition"
                className="text-info "
                style={{ cursor: "pointer" }}
                onClick={() => setPatientDispositionId(row)}
              >
                <TbLetterD size={20} color="#45A6DD" className=" " />
              </div>
            )}

          {disableFun("2") && row?.submittedDate == "-" && (
            <NavLink
              to={`/questionnaire_edit/${row?.facilityId}/${row?.screeningId}`}
            >
              <FaQuestionCircle
                size={18}
                title={"Edit Questinarie"}
                color="#45A6DD"
              />
            </NavLink>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const patients = filterlist?.some((patient) => !patient.selected);
    setSelectAll(!patients);
  }, [filterlist]);

  useEffect(() => {
    getPatientsAnswersList();
  }, [update, patientData]);
  const headerPart1 = () => {
    return (
      <>
        {filterlist && NCDate?.NCDate ? (
          disableFun("2") &&
          NCDate?.NCDate &&
          (NCDate?.cancerremoved != "No" ||
            NCDate?.cancerremoved?.length == "0") ? (
            <div className="text-center">
              <h4>
                Information for {filterlist[0]?.firstName} to review with a
                Physician:
              </h4>

              <h6>
                Your next colonoscopy should be no later than{" "}
                {moment(NCDate?.NCDate)?.format("MMMM YYYY")}. You have{" "}
                {NCDate?.Order == "14" || NCDate?.Order == "13" ? (
                  <span className={"font-weight-bold "}>AVERAGE</span>
                ) : (
                  <span className={"font-weight-bold text-danger"}>
                    INCREASED
                  </span>
                )}{" "}
                risk of colon cancer
              </h6>
            </div>
          ) : (
            <div className="text-center ">
              <h4>
                Your next colonoscopy should be no later than - Unable to
                calculate .
              </h4>
              <h6>
                You should consult with an expert in colon cancer care, as soon
                as possible
              </h6>
            </div>
          )
        ) : (
          <div className="text-center ">
            <h4>
              Your next colonoscopy should be no later than - Unable to
              calculate .
            </h4>
            <h6>
              You should consult with an expert in colon cancer care, as soon as
              possible
            </h6>
          </div>
        )}
      </>
    );
  };

  const headerPart = () => {
    let firstName = sessionStorage.getItem("firstName");
    let lastName = sessionStorage.getItem("lastName");
    let email = sessionStorage.getItem("email");
    const fullName = firstName + " " + lastName;
    const roleName = sessionStorage.getItem("roleName");
    let obj={
      firstName:sessionStorage.getItem("firstName"),
      lastName:sessionStorage.getItem("lastName"),
      phone:sessionStorage.getItem("phone"),
  zipCode:sessionStorage.getItem("zipCode")
    }
    return (
      <>
        <div className="row ">
          <div className=" col-10 d-inline-block text-start  ">
            <FaRegUserCircle className="spinprofile me-1" size={25} />
            {firstName !== "undefined" && firstName !== null && (
              <span>
                {fullName}{" "}
                {disableFun("2") && (
                  <BiEditAlt onClick={() => setPatientEdit(filterlist[0])} />
                )}
              </span>
            )}

            {roleName !== "undefined" && roleName !== null && (
              <span className="font-weight-bold mx-1  ">
                ({roleName})
                
           
              </span>
              
            )}
           
             
            
            <br />

            {disableFun("2") && email !== "undefined" && email !== null && (
              <span className="font-weight-bold mx-1 ">{email}</span>
            )}
          </div>
          <div className="col">
          {disableFun("1", "3") && (
                 <Dropdown>
                 <Dropdown.Toggle
                   className="text-dark border-0 bg-white"
                   id="dropdown-basic"
                  //  style={{ background: "#45a6dd" }}
                 ></Dropdown.Toggle>

                 <Dropdown.Menu>
                   <Dropdown.Item onClick={() => setPatientEdit(obj)}>
                     Profile
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => setAdminPasswordEdit(true)}>
                     Change Password.
                   </Dropdown.Item>
                 </Dropdown.Menu>
               </Dropdown>
              )}
          </div>
        </div>
      </>
    );
  };
  const message = () => {
    return (
      <div
        className="btn-save"
        onClick={() => setMessagepatientId(selectedPatientIds)}
      >
        Notes
      </div>
    );
  };
  return (
    <>
      <div className={disableFun("2") ? "img_login" : ""}>
        <div className="page-backgroundqa1 ">
          <AllPatientsTable
            onRowSelected={handleRowSelection}
            filyterKeys={[
              "userName",
              "status",
              "ipAddress",
              "phone",
              "status",
              "facilityName",
              "email",
              "submittedDate",
              "NCDate",
              "ncDate",
            ]}
            dataTable={filterlist}
            columns={
              sessionStorage.getItem("roleId") == "3" ||
              sessionStorage.getItem("roleId") == "1"
                ? columns?.filter(
                    (v) =>
                      v?.name != "Risk" && v?.name != "Type" && v?.name != false
                  )
                : columns
            }
            title={sessionStorage.getItem("roleId") != 2 ? "Patients" : ""}
            message={sessionStorage.getItem("roleId") != 1 ? message : ""}
            headerData={sessionStorage.getItem("roleId") != 2 && headerPart}
            // updatedHeader={headerPart1}
            updatedHeader={
              sessionStorage.getItem("roleId") != 3 &&
              sessionStorage.getItem("roleId") != 1 &&
              headerPart1
            }
          />
        </div>

        {patientId && (
          <PatientsDetailsEditModal
            show={patientId}
            updateList={setUpdate}
            onHide={() => setpatientId(false)}
          />
        )}

        {patientEdit && (
          <PatientEditModal
            show={patientEdit}
            updateList={setUpdate}
            onHide={() => setPatientEdit(false)}
            type="admin"
          />
        )}

        {adminPasswordEdit && (
          <AdminPasswordUpdateModal
            show={adminPasswordEdit}
            updateList={setUpdate}
            onHide={() => setAdminPasswordEdit(false)}
          />
        )}

        {messagepatientId && (
          <MessagestopatientsModal
            show={filterlist}
            updateList={setUpdate}
            onHide={() => {
              setSelectedPatientIds([]);
              setMessagepatientId(false);
            }}
          />
        )}

        {patientDispositionId && (
          <PatientDispositionModal
            show={patientDispositionId}
            updateList={setUpdate}
            onHide={() => setPatientDispositionId(false)}
          />
        )}
      </div>
      {disableFun("2") && (
        <div className={"menu"}>
          <AllPatient
            allList={filterlist}
            listP={allpatient}
            model={setpatientId}
            state={setFilterList}
            header={headerPart1}
          />
        </div>
      )}
    </>
  );
};

export default AllQuestions;
