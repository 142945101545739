import React from "react";

import { disableFun, mulitplePhoneNumberValue } from "../../ConfigFiles/SharedFunctions";
import moment from "moment";
const PatientsDetailsPrint = ({NCDate,objKeys,queKeys,patientsAnswersList,listQues}) => {

  return (
    <div id="div" className="px-2">
        <h5 className=" print">Questionnaire </h5>
        <div className="row  border rounded p-1">
        <div className="col-2 ">
                <h5 className="print">Patient</h5>
                <div className="print">{patientsAnswersList?.userName}</div>
              </div>
              <div className="col-3 print">
                <h5 className="print">Contact Details</h5>
                <div className=" text-break">
                  {patientsAnswersList?.email
                    ? patientsAnswersList?.email
                    : "-"}{" "}
                  <br></br>
                  {patientsAnswersList?.phone
                    ? mulitplePhoneNumberValue(patientsAnswersList?.phone)
                    : "-"}
                </div>
              </div>
              <div className="col-2 ">
                <h5 className="print">Submission Date</h5>
                <div className="print">
                  {patientsAnswersList?.submittedDate !== "-"
                    ? moment(patientsAnswersList?.submittedDate).format(
                        "MM/DD/YYYY hh:mm A"
                      )
                    : "-"}
                </div>
              </div>
              <div className="col-2">
                <h5 className="print">NC Date</h5>
                <div className="print">
                  {NCDate?.NCDate &&
                  patientsAnswersList?.submittedDate !== "-" &&
                  (patientsAnswersList?.answers[0]?.cancerremoved != "No" ||
                    patientsAnswersList?.answers[0]?.cancerremoved?.length ==
                      "0")
                    ? moment(NCDate?.NCDate)?.format("YYYY MMM")
                    : "-"}
                </div>
              </div>
              <div className="col-3">
                <h5 className="print">Connection Status</h5>
                <button
                  className={
                    "light-blue w-auto rounded border mb-3  px-4 text-white print"
                  }
                >
                  {patientsAnswersList?.status}
                </button>
              </div>
        </div>
        <div className="tabpage-backgroundqa mt-1 print ">
            <div className=" row ">
              {disableFun("2") &&
              patientsAnswersList?.answers &&
              patientsAnswersList?.submittedDate !== "-" &&
              patientsAnswersList?.answers[0]?.cancerremoved == "No" ? (
                <div className="text-center ">
                  <h4 className="print">
                    Your next colonoscopy should be no later than - Unable to
                    calculate -
                  </h4>
                  <h6 className="print">
                    You should consult with an expert in colon cancer care, as
                    soon as possible
                  </h6>
                </div>
              ) : (
                disableFun("2") &&
                NCDate?.NCDate &&
                patientsAnswersList?.submittedDate !== "-" && (
                  <div className="text-center ">
                    <h4 className="print">
                      Information for {patientsAnswersList?.firstName} to review
                      with a Physician:
                    </h4>
                    <h6 className="print">
                      Your next colonoscopy should be no later than{" "}
                      {moment(NCDate?.NCDate)?.format("MMMM YYYY")}. You have{" "}
                      {NCDate?.Order == "14" || NCDate?.Order == "13" ? (
                        <span className={"font-weight-bold "}>AVERAGE</span>
                      ) : (
                        <span className={"font-weight-bold text-danger"}>
                          INCREASED
                        </span>
                      )}{" "}
                      risk of colon cancer
                    </h6>
                  </div>
                )
              )}
              {objKeys &&
                objKeys?.length > 0 &&
                objKeys
                  ?.filter((val) => queKeys?.includes(val))
                  ?.map((v) => {
                    return <div>{listQues(v)}</div>;
                  })}
            </div>
        </div>
    </div>
  );
};

export default PatientsDetailsPrint;
