export const imageUpload = (payload) =>({

    type:"IMAGEUPLOAD",
    data:payload
})
export const imageUpdate= (update) =>({
 type:"IMAGEUPDATE",
    data:update
})
export const facilityList= (update) =>({
    type:"FACILITY_LIST",
       data:update
   })
   export const updateMessages = (payload) =>({
    type:"UPDATE_MESSAGES",
    data:payload
})
export const selectedUserId = (selectedId) =>({
    type:"UPDATE_USERID",
    data:selectedId
})
export const updateFacility = (payload) =>({
    type:"UPDATE_FACILITY",
    data:payload
})
export const PatientData=(payload)=>({
    type:"PROFILE",
    data:payload
})

export const updateData=(payload)=>({
    type:"UPDATE",
    data:payload
})
