import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import "react-datepicker/dist/react-datepicker.css";
import { useFormValidation } from "../../Validations/useFormValidations";
import { notifytwo, save } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";

const MessagestopatientsModal = (props) => {
  const userId=props?.show?.filter((v)=>v.selected==true)?.map((val)=>{return val?.screeningId})
  const submit = async () => {
   
    let body = {
      screeningId:userId,
      disposition: {
        type: "Notes",

        callStatus: { 
          firstName:sessionStorage.getItem("firstName"),
          lastName:sessionStorage.getItem("lastName"),
          
          message: data?.messagetopatient },
      },
    };
    let jsonObjects = body;
    let res = await save(urls?.screening?.saveMessage, { jsonObjects });
    if (res.data.status) {
      props?.updateList(res);
      props?.onHide();
    }
  };

  const { data, handleChange, handleSubmit, setV, errors } = useFormValidation({
    initialValues: {
      messagetopatient: "",
    },
    validationSchema: {
      messagetopatient: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
    },
    submit: submit,
  });
  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };
  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "form-control bg-white border border-danger"
      : "form-control border bg-white ";
  };


  useEffect(() => {
    if (props?.show) {
      setV({});
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">Notes</Modal.Title>
        <div>
          <div className="pointer" onClick={props?.onHide}>
            <GrClose size={20} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
         {userId?.length>0? <form onSubmit={handleSubmit}>
            <div className=" row ">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                
                <textarea
                  className={emailErrorColor("messagetopatient")}
                  placeholder="Notes"
                  rows={4}
                  value={dataValue(data.messagetopatient)}
                  name="messagetopatient"
                  onChange={handleChange("messagetopatient")}
                />
                
              </div>

              <hr />
            </div>

            <div className="d-flex justify-content-end mt-2  gap-2">
              <div
                className=" btn-save bg-danger"
                onClick={() => {
                  props.onHide();
                }}
              >
                Cancel
              </div>
              <button className=" btn-save border" title="Save">
                Save
              </button>
            </div>
          </form>:
          <div className="font-weight-bold py-4">Please select the patient and send the notes...</div>
          
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MessagestopatientsModal;
