import { lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogIn from "./AdminLogIn";

const AllQuestions = lazy(() => import("./AdminPage/AllQuestions"));
const QuestionsDetailsView = lazy(() =>
  import("./AdminPage/QuestionsDetailsView")
);
const FacilityGroupAdmin = lazy(() => import("./AdminPage/FacilityGroupAdmin"));
const FacilitygroupadminTwo = lazy(() => import("./AdminPage/FacilityAdmin"));
const Physicians = lazy(() => import("./AdminPage/AllPhysician "));
const Carriers = lazy(() => import("./AdminPage/AllCarriers"));
const Uploads = lazy(() => import("./AdminPage/PhotoUploads"));
const Chat = lazy(() => import("./AdminPage/Chat"));


const Print = lazy(() => import("./AdminPage/PatientsDetailsPrint"));
const RoutingAdmin = () => {
  return (
    <>
      <Routes>
        <Route path="patients" element={<AllQuestions />} />
        <Route
          path="patients/detailsview/:screeningId"
          element={<QuestionsDetailsView />}
        />
        <Route path="facility" element={<FacilityGroupAdmin />} />
        <Route path="facilityusers" element={<FacilitygroupadminTwo />} />
        <Route path="physicians" element={<Physicians />} />
        <Route path="carriers" element={<Carriers />} />
        <Route path="uploads" element={<Uploads />} />
        <Route path="chat" element={<Chat />} />
        <Route path="print" element={<Print />} />
       
      </Routes>
    </>
  );
};

export default RoutingAdmin;
