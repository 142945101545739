import React, { useEffect } from "react";
import Logo from "../../Image/landing_full-img.png";
import { NavLink, useParams } from "react-router-dom";


const WelcomeDashboard = () => {
  
  const {userId}=useParams()
 
  useEffect(()=>{
    localStorage.setItem("facilityId",userId)
    if(sessionStorage.getItem("userId")){
      sessionStorage.removeItem("token")
      sessionStorage.removeItem("userId")
      sessionStorage.removeItem("roleId")
      sessionStorage.removeItem("allQuestion");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("data");
      sessionStorage.removeItem("finalSubmit");
      sessionStorage.removeItem("signup");
    }
     
    
   
  },[])
 
  

  return (
    <>
     
    
      <div className="">
        <div className=" row  bgwhite p-2">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <img src={Logo} alt="Logo" className="logo-allistercare img-fluid" />
          </div>
        
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-center dashboard-textcenter">
            <label className="text-header">Get screened early !</label>

            <p className="smalltext-area ms-2 text-justify">
              Over 50,000 people in America will die from Colon Cancer this year.
              With early detection of polyps and lesions, 90% of these deaths
              are  preventable.
            </p>
            <p className="smalltext-area ms-2 text-justify">
              Screening and prevention save lives! The problem is that people
              don't know when to get screened. And sadly, they often don't.
            </p>

            <label className="text-header1 text-justify">
              Find out here when you should be screened.
            </label>

            <NavLink to={(sessionStorage.getItem("signAsGuest")=='true'||sessionStorage.getItem("token")!=null)?`/allQuestionary`:`/signup`}
              className="btn-print1 col-md-4 p-2 mt-5"
             
            >
           {sessionStorage.getItem("finalSubmit")=="Yes"?"Review Results":"Get Started"}   
            </NavLink >
          </div>
        </div>
        </div>
    </>
  );
};

export default WelcomeDashboard;
