import React, {useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useFormValidation } from '../../../Validations/useFormValidations';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getById } from '../../../ConfigFiles/SharedFunctions';
import { urls } from '../../../ConfigFiles/Urls';
const CallBack = (props) => {
    const [physicianList, setPhysicianList] = useState([]);
    const getPhysicianList = async () => {
      let jsonObjects = {
        facilityId: props?.type?.facilityId,
        type: "Physician",
      };
      let res = await getById(urls?.screening?.getFacilityDropDown, {jsonObjects});
      setPhysicianList(res);
    };
    const submit=()=>{
props?.submit(data)
    }
    const {data, handleChange,handleSubmit,handleDateChange, addObject} = useFormValidation({
        initialValues: {
          answertype: "",
          callBackDate:"",
          callBackTime:"",
          callBack:"",
        },
        validationSchema: {
          answertype: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          callBackDate: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          callBackTime: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
          callBack: {
            required: {
              value: true,
              message: "Please enter your firstName",
            },
          },
         
        },
        submit: submit,
      });
      useEffect(()=>{
addObject({answertype:props?.type?.answertype})
      },[props?.type])
      useEffect(()=>{
        if(props?.type?.facilityId){
            getPhysicianList()
        }
      },[props?.type?.facilityId])
      const dataValue=(value)=>{
return value ?value:""
      }
   
  return (
    
    <form onSubmit={handleSubmit} className='px-2'>
      <div className='row'>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        {props?.answerType()}
        </div>

        <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                <label>Call Back Date<span className='text-danger'>*</span></label>
                <br />
                <DatePicker
                  className="form-control"
                  selected={data?.callBackDate && new Date(data?.callBackDate)}
                  minDate={new Date()}
                  // maxDate={new Date()}
                  onChange={(e) => {
                    handleDateChange(e, "callBackDate");
                  }}
                  autoComplete="off"
                  name="callBackDate"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  popperClassName="react-datepicker-popper"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label>Time<span className='text-danger'>*</span> </label>
                <input
                  type="time"
                  placeholder="Time"
                  className="form-control"
                  value={dataValue(data.callBackTime)}
                  name="callBackTime"
                  onChange={handleChange("callBackTime")}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label>Call Back Reason <span className='text-danger'>*</span></label>
                <textarea
                  className="form-control bg-white"
                  placeholder="Call Back Reason"
                  name="callBack"
                  rows={2}
                  value={dataValue(data.callBack)}
                  onChange={handleChange("callBack")}
                />
              </div>
    </div>
    <Modal.Footer className='mt-2'>
        <button className='float-right btn-save border border-white '>Save</button>
        </Modal.Footer>
    </form>
    
  )
}

export default CallBack