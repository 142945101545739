import { useState } from "react";
import { notify } from "../ConfigFiles/SharedFunctions";

let isSubmitted = false;
let newErrors = {};
let hasErrors = false;
export const useFormValidation = (metadata) => {
  const [data, setData] = useState(metadata.initialValues || {});
  const [errors, setErrors] = useState({});
 

  const addItem = (key, item) => {
    if (data[key] && data[key]?.length > 0) {
      setData({ ...data, [key]: [...data?.[key], item || {}] });
    } else {
      setData({ ...data, [key]: [item || {}] });
    }
  };

  const removeItem = (name, index) => {
    setData({ ...data, [name]: data[name]?.filter((v, i) => i != index) });
  };

  const resetFormData = (name) => {
    setData({ ...data, [name]: [{}] });
  };

  const returningData = (value, type) => {
    console.log(value, "value")
    if (value != undefined && value != null && value != '') {
      switch (type) {
        case "Alphabet":
          return (
            value
              ?.replace(/(^\s*)|(\s*$)/, "")
              .replace(/[ ]{2,}/gi, " ")
              .replace(/\n +/, "\n")
              .replace(/[^a-zA-Z ]/g, "")
              .charAt(0)
              .toUpperCase() + value.slice(1)
          );
        case "AlphaNumaric":
          return (
            value
              .replace(/(^\s*)|(\s*$)/, "")
              .replace(/[ ]{2,}/gi, " ")
              .replace(/\n +/, "\n")
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .charAt(0)
              .toUpperCase() + value.slice(1)
          );
        case "Number":
          return parseInt(value?.replace(/[^0-9\.]/g, ""));
        case "NumberString":
          return value?.replace(/[^0-9\.]/g, "");
        case "Phone":
          let str = value.replace(/[^0-9]/g, "");
          if (str.length >= 10) {
            return str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            return str

          } else {
            return str;
          }
        case "Email":
          return (
            value?.replace(/(^\s*)|(\s*$)/, "")
              .replace(/[ ]{1,}/gi, "")
              .replace(/\n +/, "\n")
          )
        case "":
          return value?.replace(/(^\s*)|(\s*$)/, "")
            .replace(/[ ]{2,}/gi, " ")
            .replace(/\n +/, "\n");
        default:
          return value?.replace(/(^\s*)|(\s*$)/, "")
            .replace(/[ ]{2,}/gi, " ")
            .replace(/\n +/, "\n");
      }
    } else {
      return ""
    }
  };

  const formChange = (name, type) => (e) => {
    let d = returningData(e?.target?.value, type);
    setData({ ...data, [name]: d })
    submittingData(name, d);
  };
  const handleUpload = (name) => (e) => {
    console?.log(e.target.files[0],"ee")
    e.preventDefault();
    const file = e.target.files[0];
    const fileType = file["type"];
 const validImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    if (!validImageTypes.includes(fileType)) {
      notify(false, "Please select image or pdf files!!!");
    } else {
      setData({
        ...data,
        [name]: file,
      });
      submittingData(name, file);
    }
  };
  const writeMultiSelect = (index, name, key) => (selectedValues) => {
    let fd = data[name];
    let present = fd[index];
    present[key] = selectedValues;
    fd[index] = present;
    setData({ ...data, [name]: fd });
  };

  const handleSelectMultiple = (name) => (selectedValues) => {
    setData({
      ...data,
      [name]: selectedValues,
    });
  };

  const handleChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        // .replace(/(^\s*)|(\s*$)/, "")
        // .replace(/[ ]{2,}/gi, " ")
        // .replace(/\n +/, "\n");
      setData({
        ...data,
        [name]: str,
      });

      submittingData(name, str);
    }
  };

  const handleCapitalChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        // .replace(/(^\s*)|(\s*$)/, "")
        // .replace(/[ ]{2,}/gi, " ")
        // .replace(/\n +/, "\n");
      str = str.charAt(0).toUpperCase() + str.slice(1);
      
      setData({
        ...data,
        [name]: str,
      });
  
      submittingData(name, str);
    }
  };

  const writeData = (index, name, key, type) => (e) => {
    e.preventDefault();
    let d = returningData(e.target.value, type);
    if (name) {
      let fd = data[name];
      console.log(data[name],"data")
      let present = fd[index];
      console.log(present,"present")
      present[key] = d;
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
    submittingData(name,"")
  };

  const writeAddObject = (index, name, obj) => {
    if (name) {
      let fd = data[name];
      let present = fd[index];
      // present[key] = e.target.value
      present = { ...present, ...obj };
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
  };

  const writeDate = (index, name, key) => (e) => {
    let str;
    if (name) {
      if (e != null) {
        e?.setMinutes(0);
        e?.setHours(0);
        str = new Date(
          e?.setMinutes(
            e?.getTimezoneOffset() < 0
              ? -e?.getTimezoneOffset()
              : e?.getTimezoneOffset() || 0
          )
        );
      } else {
        str = "";
      }
    }
    if (name) {
      console.log(index, name, key,e,"ee")
      let fd = data[name];
      let present = fd[index];
      present[key] = str;
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
  };
  const writeDataCheckbox = (index,index2, name,name2, key) => (e) => {
    let fd = data[name];
    let fd2=fd[index]
    let f3=fd2[name2]
    let f4=f3[index2]
   f4[key]=e.target.checked ? e.target.value : "No";
setData({ ...data, [name]: fd });
  
  };
  const handleSelectAll = (array,name) =>(e)=> {
    let str=e.target.checked ? e.target.value : "No"
    if(str=="Yes"){
      
    }
    
   };
  const handleEmailChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        // .replace(/[ ]{1,}/gi, "")
        .replace(/\n +/, "\n");
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handleDateChange = (e, name) => {
    let str;
    if (name) {
      if (e != null) {
        e?.setMinutes(0);
        e?.setHours(0);
        str = new Date(
          e?.setMinutes(
            e?.getTimezoneOffset() < 0
              ? -e?.getTimezoneOffset()
              : e?.getTimezoneOffset()
          )
        );
      } else {
        str = "";
      }
      console.log(str, name,"eeeeee");
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const setV = (v) => {
    setData(v);
  };

  const addObject = (u) => {
    setData({
      ...data,
      ...u,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isSubmitted = true;
    validateForm();
    if (!hasErrors) {
      metadata.submit();
    }
    else {
      if(!metadata?.initialValues?.notShowMsg)
      notify(false, "Please enter the mandatory fields");
    }
  };

  const validateForm = () => {
    for (const key in metadata.validationSchema) {
      validateFormControl(key, data?.[key]);
    }
    setErrors(newErrors);
    var size = Object.keys(newErrors).length;
    if (size) {
      hasErrors = true;
    } else {
      hasErrors = false;
    }
    newErrors = {};
  };

  const submittingData = (name, value) => {
    if (isSubmitted) {
      validateFormControl(name, value);

      if (errors && errors[name] && !newErrors[name]) {
        delete errors?.[name]
        setErrors({
          ...errors,
        });
      } else {
        setErrors({
          ...errors,
          ...newErrors,
        });
      }
      newErrors = {};
    }
  };

  const validateInnerSchema = (parentKey, key, value) => {
    const schema = metadata.validationSchema;
    const validationFormControl = schema?.[parentKey]?.innerSchema?.[key];
    let innerErrors = {}
    if (validationFormControl?.required && !value) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.required?.message,
      };
    } else if (
      validationFormControl?.minlength &&
      value.length < validationFormControl?.minlength?.value
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.minlength?.message,
      };
    } else if (
      validationFormControl?.maxlength &&
      value.length > validationFormControl?.maxlength?.value
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.maxlength?.message,
      };
    } else if (
      validationFormControl?.pattern &&
      !validationFormControl.pattern?.value?.test(value)
    ) {
      innerErrors = {
        ...innerErrors,
        [key]: validationFormControl?.pattern?.message,
      };
    }
    return innerErrors;
  };

  const validateFormControl = (key, value) => {
    const schema = metadata.validationSchema;
    const validationFormControl = schema?.[key];
    if (validationFormControl?.required && !value) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.required?.message,
      };
    } else if (validationFormControl?.minlength &&value.length < validationFormControl?.minlength?.value
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.minlength?.message,
      };
    } else if (
      validationFormControl?.maxlength &&
      value.length > validationFormControl?.maxlength?.value
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.maxlength?.message,
      };
    } else if (
      validationFormControl?.pattern &&
      !validationFormControl.pattern?.value?.test(value)
    ) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.pattern?.message,
      };
    } else if (validationFormControl?.isArray && validationFormControl?.isArray?.value) {
      if (data?.[key] && data?.[key]?.length > 0) {
        let res = data?.[key]?.map((e, i) => {
          let innerErrors = {}
          for (const innerkey in validationFormControl?.innerSchema) {
            let res = validateInnerSchema(key, innerkey, e?.[innerkey] || "")
            innerErrors = { ...innerErrors, ...res }
          }
          return innerErrors
        })
        const allEmptyObjects = res.every(o => Object.keys(o).length === 0)
        if (!allEmptyObjects) {
          newErrors = {
            ...newErrors,
            [key]: res,
          };
        }
      }
    }
  };


  const handlePhoneChange = (name) => (e) => {

    e.preventDefault();
    if (name) {
      let str = e.target.value.replace(/[^0-9]/g, "")
     str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
     
    
      setData({
        ...data,
        [name]: str,
      });
      console?.log(str,"str")
      submittingData(name, str);
    }
  };


  


  const handleMBIChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value.replace(/(^\s*)|(\s*$)/, "")
      .replace(/[ ]{2,}/gi, " ")
      .replace(/\n +/, "\n");
      if (str.length >= 11) {
        str = str.replace(/(\d{4})(\d{3})(\d{4})/, "($1) ($2) ($3)");
      }
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };
  const handleNumberChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value.replace(/[^0-9\.]/g, "");
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

 
  const handleCheckbox = (name) => (e) => {
    // e.preventDefault();
    setData({
      ...data,
      [name]: e.target.checked ? e.target.value : "",
    });
  };

  const handleChangeSearch = (name) => (selectedOption) => {
    if (name) {
      let str = selectedOption.value;
      console?.log(str,"str1")
      setData({
        ...data,
        [name]: str,
      });

      submittingData(name, str);
    }
  };
  

  return {
    data,
    errors,
    isSubmitted,
    // addValues,
    formChange,
    addObject,
    setV,
    handleCheckbox,
    handleDateChange,
    handleEmailChange,
    handleSelectMultiple,
    handleChange,
    handleCapitalChange,
    addItem,
    removeItem,
    writeData,
    writeDate,
    writeMultiSelect,
    writeAddObject,
    resetFormData,
    handlePhoneChange,
    handleSubmit,
    handleChangeSearch,
    handleNumberChange,
    handleMBIChange,
    handleSelectAll,
    writeDataCheckbox,
    handleUpload,

  };
};
