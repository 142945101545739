import React, { useEffect, useState } from "react";
import {
  disableFun,
  getList,
  mulitplePhoneNumberValue,
} from "../ConfigFiles/SharedFunctions";
import { urls } from "../ConfigFiles/Urls";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { CgEyeAlt } from "react-icons/cg";
import { FaQuestionCircle } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { TbSquareLetterA, TbSquareLetterC } from "react-icons/tb";
const AllPatient = ({ allList, model, state, listP,header }) => {
  const [search, setSearch] = useState("");
  const date = (value) => {
    return value != "-" && value
      ? moment(value)?.format("MM/DD/YYYY,hh:mm A")
      : "";
  };
  useEffect(() => {
    if (search != "" && search?.trim()) {
      let res = allList?.filter((v) => {
        return (
          v?.email?.toString()?.toLowerCase().includes(search?.toLowerCase()) ||
          v?.userName
            ?.toLowerCase()
            .includes(search?.toString()?.toLowerCase()) ||
          v?.phone?.toString().toLowerCase().includes(search?.toLowerCase()) ||
          v?.callStatus?.appointmentDate
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          v?.facilityName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          v?.status.toLowerCase().includes(search?.toLowerCase()) ||
          moment(v.appointmentDate)
            ?.format("MM/DD/YYYY")
            ?.toString()
            ?.toLowerCase()
            ?.includes(search?.toString()?.toLowerCase())
        );
      });
      state(res);
    } else {
      state(listP);
    }
  }, [search]);
  return (
    <>
      <div className="col-12 p-1 marginTop py-3">
        <input
          className="form-control bg-white"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {allList&&<div>{header()}</div>}

      {allList?.map((v) => {
        return (
          <div className="m-1 card">
            <div class="card-body">
              <div className="row ">
                <div className="col-6 ">Facility</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  : {v?.facilityName}
                </div>
              </div>
          {  disableFun("3") && ( <div className="row ">
                <div className="col-6">
                  Appointment Set/
                  <br /> Call-back
                </div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                   {v?.callStatus?.appointmentDate &&
                    v?.callStatus?.appointmentDate !== "" ? (
                      <div>
                      :  <span>
                          {moment(v?.callStatus?.appointmentDate).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <TbSquareLetterA
                            title="Appointment Set"
                            className="pointer"
                            size={18}
                          />
                        </span>
                      </div>
                    ) : v?.callStatus?.callBackDate &&
                      v?.callStatus?.callBackDate !== "" ? (
                      <div>
                      :  <span>
                          {moment(v?.callStatus?.callBackDate).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          <TbSquareLetterC
                            title="Call Back"
                            className="pointer"
                            size={18}
                          />
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span>: -</span>
                      </div>
                    )}

                    {v?.callStatus?.time && v?.callStatus?.time !== "" ? (
                      moment(v?.callStatus?.time, "HH:mm:ss").format("hh:mm A")
                    ) : v?.callStatus?.callBackTime &&
                      v?.callStatus?.callBackTime !== "" ? (
                      moment(v?.callStatus?.callBackTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )
                    ) : (
                      <span></span>
                    )}
                  
                  <br />
                </div>
              </div>)}
              <div className="row ">
                <div className="col-6">Phone</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  : {v?.phone != "" ? mulitplePhoneNumberValue(v?.phone) : "-"}{" "}
                 
                </div>
              </div>
              <div className="row ">
                <div className="col-6">Zip</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  : {v?.zipCode}
                </div>
              </div>
              <div className="row ">
                <div className="col-6 ">Connection Status</div>
               
                  <div
                    className="col-6 fw-bolder"
                    style={{ fontSize: "small" }}
                  >
                    : {v?.status}
                  </div>
                
              </div>
              <div className="row ">
                <div className="col-6">Submission Date</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  : {date(v?.submittedDate)}
                  {/* : {v?.submittedDate&&v?.submittedDate!="-"&&moment(v?.submittedDate)?.format("MM/DD/YYYY ,hh:mm A")}
                    {v?.submittedDate&&v?.submittedDate!="-"&&moment(v?.submittedDate)?.format("hh:mm A")} */}
                </div>
              </div>

              <div className="row ">
                <div className="col-6 ">Next Colonoscopy</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  {!v?.answers[0]?.NCDate ||
                  v?.answers[0]?.NCDate === "-" ||
                  v?.answers[0]?.NCDate === "Invalid date" ? (
                    <div className="" title="No Date Available">
                      : -
                    </div>
                  ) : (
                    <div
                      className=""
                      title={v?.submittedDate !== "-" && v?.answers[0]?.NCDate}
                    >
                      :{" "}
                      {v?.submittedDate !== "-"
                        ? moment(v?.answers[0]?.NCDate)?.format("YYYY MMM")
                        : "-"}
                    </div>
                  )}
                </div>
              </div>

              <div className="row ">
                <div className="col-6">Type</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  : Colon
                  <br />
                </div>
              </div>

              {/* <div className='row '>
         <div className='col-6' >
          Risk
         </div>
         <div className='col-6 fw-bolder' style={{ fontSize: "small" }}>
         {date(v?.callStatus?.appointmentDate)} 
           <br /> 
         </div>
       </div> */}

              <div className="row ">
                <div className="col-6">Risk</div>
                <div className="col-6 fw-bolder" style={{ fontSize: "small" }}>
                  :{" "}
                  {v?.submittedDate !== "-" ? (
                    v?.answers[0]?.Order === "13" ||
                    v?.answers[0]?.Order === "14" ? (
                      <span title="AVERAGE">AVERAGE</span>
                    ) : v?.answers[0]?.Order === "15" ? (
                      <span className="text-muted">-</span>
                    ) : (
                      <span className="text-danger" title="INCREASED">
                        INCREASED
                      </span>
                    )
                  ) : (
                    <span className="text-muted">-</span>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer d-flex gap-1 justify-content-center">
              <NavLink
                to={`/questionnaire_view/${v?.screeningId}`}
                className="mobile_btn px-3 d-flex gap-2 bg-white"
              >
                <CgEyeAlt className="mt-1" /> View
              </NavLink>
              <div
                className="mobile_btn px-3 d-flex gap-2 bg-white"
                onClick={() => model(v)}
              >
                <FaUserEdit className="mt-1" />My  Details
              </div>
              {v?.submittedDate == "-" && (
                <NavLink
                  to={`/questionnaire_edit/${v?.facilityId}/${v?.screeningId}`}
                  className="mobile_btn  px-3 d-flex gap-2 bg-white"
                >
                  <FaQuestionCircle className="mt-1" /> Edit
                </NavLink>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AllPatient;
