import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFormValidation } from "../../Validations/useFormValidations";
import {
  disableFun,
  getById,
  getList,
  mulitplePhoneNumberValue,
  save,
} from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import PatientAnsweredModal from "./PatientAnsweredModal";
import PatientNotAnswerModal from "./PatientNotAnswerModal";
import PatientVoicemailModal from "./PatientVoicemailModal";
import PatientWrongNumber from "./PatientWrongNumber";
import DifferentLanguage from "./Models/DifferentLanguage";
import NotInterest from "./Models/NotInterest";

const PatientDispositionModal = (props) => {
  const answers = props?.show?.answers[0];

  const [answeredModal, setAnsweredModal] = React.useState(false);
  const [detailsState, setDetailsState] = useState("History");
  const [notAnsweredModal, setNotAnsweredModal] = React.useState(false);
  const [patientVoiceMailModal, setPatientVoiceMailModal] =
    React.useState(false);
  const [patientWrongNumber, setPatientWrongNumber] = React.useState(false);
  const [update, setUpdate] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const getDispotionData = async () => {
    let jsonObjects = {
      screeningId: props?.show?.screeningId,
    };
    let res = await getList(urls?.screening?.getDisposition, { jsonObjects });
    res?.data?.data && setHistoryData(res?.data?.data);
  };
  const submit = async (object) => {
    let body ={
      screeningId:props?.show?.screeningId,
     
      disposition:{
        type:"Answered",
      
      callStatus:{answertype:object?.answertype,
        date:new Date(),
        firstName:sessionStorage.getItem("firstName"),
        lastName:sessionStorage.getItem("lastName"),
      language:object?.language,
      appointmentDate:object?.appointmentDate,
      physicianId:object?.physicianId,
      time:object?.time,
      physicianName: object?.physicianName,
      phone: object?.phone,
      notInterestedReason:object?.notInterestedReason,
      medicareDate:object?.medicareDate,
      medicareNotes:object?.medicareNotes,
      callBackDate:object?.callBackDate,
      callBackTime:object?.callBackTime,
      callBack: object?.callBack,}
      }
    }
    let jsonObjects = body
    let res = await save(urls?.screening?.saveDisposition, { jsonObjects });
if(res?.data?.status){
  closeModel()
  setUpdate(res)
  props?.updateList(res)
}
    
  };

  const { data, handleChange, setV,addObject } = useFormValidation({});

  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };

  const renderHistoryData = () => {
    if (!Array.isArray(historyData) || historyData?.length === 0) {
      return <div className="text-center"> No history data available.</div>;
    }

    return (
      historyData &&
      historyData?.map((dataItem, index) => (
        <div>
          <div className=" wrap px-2" key={index}>
            <div className="leadfont1">
              Date: {moment(dataItem?.callStatus?.date)?.format("MM/DD/YYYY")}{" "}
              {moment(dataItem?.callStatus?.date)?.format("hh:mm A")}
            </div>
            <div className="d-flex ">
              <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12">User</div>
              <div className="col-9">
                {" "}
                :{" "}
                {dataItem?.callStatus?.firstName +
                  " " +
                  dataItem?.callStatus?.lastName}
              </div>
            </div>
            <div className="d-flex">
              <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 ">
                Disposition
              </div>
              <div className="col-9 ">
                {" "}
                :{" "}
                {dataItem?.callStatus?.answertype
                  ? dataItem?.callStatus?.answertype
                  : dataItem?.type}
              </div>
            </div>
            <div className=" d-flex">
              <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12">
              
                Comments 
              </div>

              <div className="col-9 comment1">: {dataItem?.callStatus?.physicianName && (
                 <> {dataItem?.callStatus?.physicianName},</>
                )}
                {dataItem?.callStatus?.phone && (
                  <> {mulitplePhoneNumberValue(dataItem?.callStatus?.phone)}</>
                )}
             
                {dataItem?.callStatus?.message && (
                  <> {dataItem?.callStatus?.message}</>
                )}
                {dataItem?.callStatus?.appointmentDate && (
                  <> {moment(dataItem?.callStatus?.appointmentDate)?.format(
                      "MM/DD/YYYY"
                    )}
                    ,
                  </>
                )}
                {dataItem?.callStatus?.time && (
                  <> {moment(dataItem?.callStatus?.time, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  </>
                )}
                {dataItem?.callStatus?.callBack && (
                  <> {dataItem?.callStatus?.callBack},</>
                )}
                {dataItem?.callStatus?.callBackDate && (
                  <> {moment(dataItem?.callStatus?.callBackDate)?.format(
                      "MM/DD/YYYY"
                    )}
                    ,
                  </>
                )}
                {dataItem?.callStatus?.callBackTime && (
                  <>
                   {/* {dataItem?.callStatus?.callBackTime} */}
                  
                  {moment(dataItem?.callStatus?.callBackTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                  
                  </>
                )}
                {dataItem?.callStatus?.notInterestedReason && (
                  <> {dataItem?.callStatus?.notInterestedReason}</>
                )}
                {dataItem?.callStatus?.language && (
                  <> {dataItem?.callStatus?.language}</>
                )}
                {dataItem?.callStatus?.callingDate && (
                  <>
                    {" "}
                    {moment(dataItem?.callStatus?.callingDate)?.format(
                      "MM/DD/YYYY"
                    )}
                    ,
                  </>
                )}
                {dataItem?.callStatus?.callingTime && (
                  <>
                    {" "}
                    {moment(
                      dataItem?.callStatus?.callingTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  </>
                )}
                {/* {dataItem?.dataItem?.callingTime && (<>{moment(dataItem?.callStatus?.callingTime, 'hh:mm').format('hh:mm A')}</>)} */}
                {dataItem?.callStatus?.notAnswerDescription && (
                  <> {dataItem?.callStatus?.notAnswerDescription},</>
                )}
                {dataItem?.callStatus?.notAnswercallingDate && (
                  <>
                    {" "}
                    {moment(dataItem?.callStatus?.notAnswercallingDate)?.format(
                      "MM/DD/YYYY"
                    )}
                    ,
                  </>
                )}
                {dataItem?.callStatus?.notAnswercallingTime && (
                  <>
                    {" "}
                    {moment(
                      dataItem?.callStatus?.notAnswercallingTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  </>
                )}
                {dataItem?.callStatus?.wrongCallingDescription && (
                  <> {dataItem?.callStatus?.wrongCallingDescription},</>
                )}
                {dataItem?.callStatus?.wrongCallingDate && (
                  <>
                    {" "}
                    {moment(dataItem?.callStatus?.wrongCallingDate)?.format(
                      "MM/DD/YYYY"
                    )}
                    ,
                  </>
                )}
                {dataItem?.callStatus?.wrongCallingTime && (
                  <>
                    {" "}
                    {moment(
                      dataItem?.callStatus?.wrongCallingTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  </>
                )}
              </div>
            </div>

            <hr style={{ border: "1px solid" }} />
          </div>
        </div>
      ))
    );
  };

  useEffect(() => {
    if (props?.show?.screeningId) {
      getDispotionData();
      props?.updateList(update)
    }
  }, [props?.show?.screeningId, update]);

  useEffect(() => {
    setV(props?.show);
  }, [props?.show]);

  const history = () => {
    return (
      <>
        <div className="vertical-scrolbar">
          <div>{renderHistoryData()}</div>
        </div>
      </>
    );
  };
  const settingDetailstate = (name) => {
    setDetailsState(name);
  };
  const tabs = () => {
    switch (detailsState) {
      case "History":
        return history();
      default:
        return <></>;
    }
  };
  const closeModel=()=>{
    addObject({answertype:""})
    setAnsweredModal(false)
  }
  const answerstype=()=>{
    return (
      <div>
        <label>
              Answer Type <span className="text-danger">*</span>
            </label>
            <select
             className="form-control"
              value={dataValue(data?.answertype)}
              name="answertype"
              onChange={handleChange("answertype")}
            >
              <option value={""}>Please Select</option>
              <option>Different Language</option>
              <option>Not Interested</option>
              <option>Appointment Set</option>
              <option>Call Back</option>
              <option>Contacted In Person</option>
              <option>Contacted by Phone</option>
              <option>Contacted by Email </option>
            </select>
      </div>
    )
  }
  const close=()=>{
    props?.onHide()
    
  }
  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Disposition
        </Modal.Title>
        <div className="d-flex gap-2">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              disabled={disableFun("2")}
              style={{ background: "#45a6dd", border: "#45a6dd" }}
            >
              Log
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                onClick={() => setAnsweredModal(true)}
              >
                Answered
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={() => setPatientVoiceMailModal(true)}
              >
                {" "}
                Voice Mail
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-3"
                onClick={() => setNotAnsweredModal(true)}
              >
                Not Answered
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-3"
                onClick={() => setPatientWrongNumber(true)}
              >
                Wrong Number
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div
            className="float-right btn bg-danger text-white"
            onClick={close}
          >
            Close
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="card p-2 ">
                <div className="d-flex mt-1 ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label className="">First Name</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder="First Name"
                      name="firstName"
                      disabled="true"
                      // onChange={handleChange("firstName")}
                      value={dataValue(data?.firstName)}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ms-1">
                    <label className="">Last Name</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder="Last Name"
                      name="lastName"
                      disabled="true"
                      // onChange={handleChange("lastName")}
                      value={dataValue(data.lastName)}
                    />
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label className="">DOB</label>
                    <input
                      className="form-control bg-white"
                      placeholder="YYYY/MM"
                      name="born"
                      disabled="true"
                      value={
                        answers && answers?.born && answers?.months
                          ? dataValue(answers.born + " " + answers.months)
                          : ""
                      }
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ms-1">
                    <label className="">Email</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange("email")}
                      value={dataValue(data.email)}
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label className="">Phone</label>
                    <input
                      typeof="text"
                      name="phone"
                      type="text"
                      maxLength={10}
                      disabled="true"
                      className="form-control bg-white"
                      placeholder="Phone"
                      value={mulitplePhoneNumberValue(data.phone)}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 ms-1">
                    <label className="">Zip</label>

                    <input
                      typeof="text"
                      value={dataValue(data.zipCode)}
                      name="zipCode"
                      type="text"
                      maxLength={5}
                      disabled="true"
                      className="form-control bg-white"
                      placeholder="Zip"
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <label className="">Last Colonoscopy</label>
                    <input
                      className="form-control bg-white"
                      placeholder="Next Colonoscopy"
                      name="NCDate"
                      disabled="true"
                      // value={dataValue(answers?.completecolonoscopyperformed+" "+answers?.month1)}
                      value={
                        answers &&
                        answers.completecolonoscopyperformed &&
                        answers.month1
                          ? dataValue(
                              answers.completecolonoscopyperformed +
                                "-" +
                                answers.month1
                            )
                          : "-"
                      }
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ms-1">
                    <label className="">Next Colonoscopy</label>
                    <input
                      className="form-control bg-white"
                      placeholder="Next Colonoscopy"
                      name="NCDate"
                      disabled="true"
                      value={dataValue(
                        moment(answers?.NCDate).format("YYYY-MMMM ")
                      )}
                    />
                  </div>
                </div>
              
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <label className="">Facility</label>

                    <select
                      className="form-control"
                      value={data?.facilityName}
                      name="FacilityName"
                      disabled="true"
                    >
                      <option value={data?.facilityName}>
                        {data?.facilityName}
                      </option>
                    </select>
                  </div>
                
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="card" style={{ height: "378px" }}>
                <div
                  className=" d-flex justify-content-center"
                  // style={{ height: "45px" }}
                >
                  {["History"]?.map((e, i) => (
                    <div
                      // className={
                      //   detailsState === e
                      //     ? "active-tabs  col-md-auto"
                      //     : "col-md-auto  pointer inactive-tabs"
                      // }
                      className="active-tabs"
                      onClick={() => {
                        settingDetailstate(e);
                      }}
                      key={i}
                    >
                      {e} - ({historyData?.length})
                    </div>
                  ))}
                </div>
                {tabs()}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

    
      {notAnsweredModal && (
        <PatientNotAnswerModal
          show={notAnsweredModal}
          userdata={props?.show}
          updateList={setUpdate}
          onHide={() => setNotAnsweredModal(false)}
        />
      )}

      {patientVoiceMailModal && (
        <PatientVoicemailModal
          show={patientVoiceMailModal}
          userdata={props?.show}
          updateList={setUpdate}
          onHide={() => setPatientVoiceMailModal(false)}
        />
      )}
      {answeredModal&&<DifferentLanguage show={answeredModal} 
      answerType={answerstype} 
      type={data}
      updateList={setUpdate}
      submit={submit}
       onHide={closeModel}/>}
       
      {patientWrongNumber && (
        <PatientWrongNumber
          show={patientWrongNumber}
          userdata={props?.show}
          updateList={setUpdate}
          onHide={() => setPatientWrongNumber(false)}
        />
      )}
    </Modal>
  );
};

export default PatientDispositionModal;
