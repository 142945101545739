
import React, {  useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormValidation } from "../../Validations/useFormValidations";
import { getById,save } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import moment from "moment/moment";

const PatientNotAnswerModal = (props) => {
  const [historyData, setHistoryData] = useState([]);

  const submit = async () => {
    let body ={
      screeningId:props?.userdata?.screeningId,
      disposition:{
        type:"NotAnswer",
      callStatus:{answertype:"Not Answered",
        date:new Date(),
        firstName:sessionStorage.getItem("firstName"),
        lastName:sessionStorage.getItem("lastName"),
        notAnswerDescription:data?.notAnswerDescription,
        notAnswercallingTime:data?.notAnswercallingTime,
        notAnswercallingDate:data?.notAnswercallingDate
      }
      }
      
    }
    let jsonObjects = body
    let res = await save(urls?.screening?.saveDisposition, { jsonObjects });
    if (res.data.status) {
      props?.updateList(res);
      props?.onHide();
    }
    }

  const getHistoryData = async () => {
    let jsonObjects = {
      patientId: props?.patientId,
      answerType:"Not Answered"
    }
    let res = await getById(urls?.leads?.getHistoryDetails, { jsonObjects })
    setHistoryData(res);
  }

  const {
    data,
    handleChange,
    handleSubmit,
    handleDateChange,
    setV,
    errors
  } = useFormValidation({
    initialValues: {
      notAnswerDescription: "",
      notAnswercallingDate:"",
      notAnswercallingTime:"",
    },
    validationSchema: {
      notAnswerDescription: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
      notAnswercallingDate: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
      notAnswercallingTime: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
    },
    submit: submit,
  });

  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };
  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "form-control bg-white border border-danger"
      : "form-control border bg-white ";
  };
  
 
  useEffect(() => {
    if(props?.show){
      setV({})
    } 
  }, [props.show]) 

  useEffect(() => {
    if (props?.leadId) {
      getHistoryData()
    }
  }, [props?.leadId])

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">
        Not Answered
        </Modal.Title>
        <div>
          <div className="pointer" onClick={props?.onHide}>
            <GrClose size={20} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form onSubmit={handleSubmit}>
          
            <div className=" row ">
            
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <label>Date <span className="text-danger">*</span></label><br/>
                  <DatePicker
                 className={emailErrorColor("notAnswercallingDate")}
                  selected={data?.notAnswercallingDate && new Date(data?.notAnswercallingDate)}
                  minDate={new Date()}
                  // maxDate={new Date()}
                  onChange={(e) => {
                  handleDateChange(e, "notAnswercallingDate");
                  }}
                  autoComplete="off"
                  name="notAnswercallingDate"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  popperClassName="react-datepicker-popper"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  />
                </div>
                <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12">
                    <label>Time <span className="text-danger">*</span></label>
                    <input type="time" placeholder="Time"
                    value={dataValue(data.notAnswercallingTime)}
                    className={emailErrorColor("notAnswercallingTime")}
                    name="notAnswercallingTime"
                    onChange={handleChange("notAnswercallingTime")}
                    />
                </div>  

                <label>Notes <span className="text-danger">*</span></label>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <textarea
                    className={emailErrorColor("notAnswerDescription")}
                    placeholder="Notes"
                    rows={4}
                    value={dataValue(data.notAnswerDescription)}
                    name="notAnswerDescription"
                    onChange={handleChange("notAnswerDescription")}
                  />
                </div>
              
            </div>
  
         
            <div className="d-flex justify-content-end mt-2  gap-2">
            <div className=" btn-save bg-danger" 
              onClick={() => {
                props.onHide();
              }}
                >
            Cancel
            </div>
            <button className=" btn-save border" title="Save"
             
            >
              Save
            </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PatientNotAnswerModal;
