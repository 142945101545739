import React, { useState, useEffect, useRef } from "react";
import Logo from "../Image/logo-c0d9bcbe312d8af35fbe10e083e8d653.png";
import Logo1 from "../Image/favicon1.png";
import { RiShutDownLine } from "react-icons/ri";
import { MdAddAPhoto } from "react-icons/md";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineDashboardCustomize,
} from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import {
  allmsgcount,
  chatServices,
  countmsg,
  getById,
  getList,
  logOutFunction,
} from "../ConfigFiles/SharedFunctions";
import { FaClinicMedical, FaHospitalUser } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { FaUserDoctor } from "react-icons/fa6";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { Carousel } from "react-bootstrap";
import { baseURL, basefileURL, urls } from "../ConfigFiles/Urls";
import { useDispatch, useSelector } from "react-redux";
import { BsQrCode } from "react-icons/bs";
import { facilityList, imageUpload, updateMessages } from "../Redux/Action";
import QRCodeModal from "../Components/AdminPage/QRCodeModal";
import { BiChat } from "react-icons/bi";
import { TbPencilQuestion } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";

const Navbar = ({ children }) => {
  const navigate = useNavigate();
  const [navList, setNavList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const toggle = () => setIsOpen(!isOpen);
  const state = useSelector((state) => state);
  const [img, setImg] = useState(false);
  const listOfImage = useRef([]);
  listOfImage.current = state.image;
  const linkTextElements = document.querySelectorAll(".childItems .link_text");
  linkTextElements.forEach((element) => {
    element.style.border = "none";
  });
  const dispatch = useDispatch();

  let facilityId = sessionStorage?.getItem("facility");
  const obj = {
    facilityId: facilityId,
    facilityName: sessionStorage?.getItem("facilityName"),
  };

  const getFacilityImages = async () => {
    let body = {
      facilityId: parseInt(facilityId),
    };
    let jsonObjects = body;
    let res = await getList(urls?.facility?.getFacilityImageList, {
      jsonObjects,
    });
    dispatch(imageUpload(res?.data?.data));
  };
  const getFacilityList = async () => {
    let jsonObjects = {
      type: "Facility",
      facilityId: sessionStorage?.getItem("roleId") == "1" ? "0" : facilityId,
    };
    let res = await getById(urls?.screening?.getFacilityDropDown, {
      jsonObjects,
    });
    dispatch(facilityList(res));
  };

  const handleMenuItemClick = () => {
    if (windowWidth < 768) {
      setIsOpen(false);
    }
  };



  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getFacilityImages();
  }, [facilityId, state?.update]);
  useEffect(() => {
    getFacilityList();
  }, [facilityId, state?.updateFacility]);

  const menuItem = [
    {
      name: "Allistercare",
      goto: "/admin/all/patients",
      logo: <MdOutlineDashboardCustomize title="Dashboard" size={20} />,
      tooltip: "View Allistercare",
    },
    {
      name: "Facility",
      goto: "/admin/all/facility",
      logo: <FaClinicMedical title="Facility" size={20} />,
      tooltip: "Facility",
    },

    {
      name: "Facility Users",
      goto: "/admin/all/facilityusers",
      logo: <FaHospitalUser title="Facility Users" size={20} />,
      tooltip: "Users",
    },
    {
      name: "Carriers",
      goto: "/admin/all/carriers",
      logo: <HiOutlineBuildingLibrary title="Carriers" size={20} />,
      tooltip: "Carriers",
    },
    {
      name: "Physicians",
      goto: "/admin/all/physicians",
      logo: <FaUserDoctor title="Physician" size={20} />,
      tooltip: "Physician",
    },
    {
      name: "Questionnaire",
      goto: "/terms",
      logo: <IoNewspaperOutline title="Questionnaire" size={20} />,
      tooltip: "Questionnaire",
    },
    {
      name: "Chat",
      goto: "/admin/all/chat",
      logo: <BiChat title="Chat" size={20} />,
      tooltip: "Chat",
    },
    {
      name: "Uploads",
      goto: "/admin/all/uploads",
      logo: <MdAddAPhoto title="Images" size={20} />,
    },
  ];
  useEffect(() => {
    switch (sessionStorage.getItem("roleId")) {
      case "1":
        return setNavList(
          menuItem?.filter(
            (v) => v?.name != "Chat" && v?.name != "Questionnaire"
          )
        ); //super admin
      case "2":
        return setNavList(
          menuItem?.filter(
            (v) =>
              v?.name == "Allistercare" ||
              v?.name == "Chat" ||
              v?.name == "Questionnaire"
          )
        ); //patient
      case "3":
        return setNavList(
          menuItem?.filter(
            (v) =>
              v?.name == "Allistercare" ||
              v.name == "Carriers" ||
              v.name == "Physicians" ||
              v.name == "Uploads" ||
              v.name == "Chat"
          )
        ); //facility admin
      default:
        return menuItem;
    }
  }, [sessionStorage.getItem("roleId")]);

  return (
    <div className="container2 ">
      <div
        style={{
          width: isOpen
            ? window.innerWidth >= 768
              ? "240px"
              : "240px"
            : window.innerWidth < 768
            ? "0px"
            : "75px",
          height: "100vh",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <h1
            style={{ display: isOpen ? "block" : "none" }}
            className="logo pointer"
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: "55px",
                width: "220px",
                padding: "5px",
              }}
              onClick={() => {
                navigate("/admin/all/patients");
              }}
            />
            <div
              style={{
                borderTop: "1px solid #ccc",
                width: "100%",
                margin: "5px 0",
              }}
            ></div>
          </h1>
          {!isOpen && (
            <>
              <img
                src={Logo1}
                className="pointer"
                alt="Login"
                style={{
                  objectFit: "fill",
                  height: "45px",
                  width: "45px",
                  display: window.innerWidth < 768 ? "none" : "",
                }}
              />
            </>
          )}
        </div>
        <div className="mobiletogle ">
          {isOpen ? (
            <MdArrowBackIos
              style={{ marginLeft: "230px", marginTop: "-79px" }}
              size={25}
              onClick={toggle}
            />
          ) : window.innerWidth < 768 ? (
            <IoMdMenu
              size={25}
              onClick={toggle}
              className="mt-2"
              style={{ marginLeft: "9px" }}
            />
          ) : (
            <MdArrowForwardIos size={20} onClick={toggle} />
          )}
        </div>
        <div
          className="overflow "
          style={{
            display: !isOpen
              ? window.innerWidth < 768
                ? "none"
                : "block"
              : "block",
          }}
        >
          {navList?.map((items, index) => {
            return (
              <>
                {
                  <div key={index} className="mb-2">
                    <div>
                      <NavLink
                        to={items?.goto}
                        className={({ isActive }) =>
                          isActive && items?.name
                            ? "childItems  border py-1 my-2"
                            : "childItems py-1 my-2"
                        }
                        style={{ textDecoration: "none" }}
                        onClick={() => {
                          handleMenuItemClick(); // Close Navbar on mobile
                        }}
                      >
                        <div className="icon">{items.logo}</div>
                        <div
                          style={{ display: isOpen ? "block" : "none" }}
                          className="link_text"
                        >
                          {items?.name && items?.name == "Chat" ? (
                            <div className="d-flex">
                              {items?.name}
                              {allmsgcount(
                                state?.messages,
                                sessionStorage?.getItem("userId")
                              ) != "" && (
                                <span
                                  className="bg-success text-white rounded-circle d-flex align-items-center justify-content-center"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <span style={{ fontSize: "10px" }}>
                                    {allmsgcount(
                                      state?.messages,
                                      sessionStorage?.getItem("userId")
                                    )}
                                  </span>
                                </span>
                              )}
                            </div>
                          ) : (
                            items?.name
                          )}
                        </div>
                      </NavLink>
                    </div>
                  </div>
                }
              </>
            );
          })}
        </div>

        {sessionStorage.getItem("roleId") == 3 && (
          <div
            className=" pointer  childItems py-1 my-2"
            style={{
              display: !isOpen
                ? window.innerWidth < 768
                  ? "none"
                  : "block"
                : "block",
            }}
            onClick={() => setImg(obj)}
          >
            <BsQrCode title="QR Code" className=" mb-1" size={20} />

            <span className="link_text ms-4 ">QR Code</span>
          </div>
        )}
        <div
          className="logout"
          style={{
            display: !isOpen
              ? window.innerWidth < 768
                ? "none"
                : "block"
              : "block",
          }}
        >
          <div className="logout">
            <div style={{ display: isOpen ? "block" : "none" }} className="">
              <div
                onClick={logOutFunction}
                className="d-flex logout-link px-3 mx-1 text-secondary gap-4 pointer"
              >
                <RiShutDownLine size={25} className="mt-1" />
                <div className="">Log out</div>
              </div>
            </div>
            <div style={{ display: !isOpen ? "block" : "none" }}>
              <NavLink onClick={logOutFunction} title="Logout">
                <RiShutDownLine style={{ marginLeft: "-5px" }} size={20} />
              </NavLink>
            </div>
          </div>
        </div>

        {listOfImage?.current && listOfImage?.current.length > 0 && (
          <Carousel
            interval={12000}
            className="my-4"
            style={{
              display: !isOpen
                ? window.innerWidth < 768
                  ? "none"
                  : "block"
                : "block",
            }}
          >
            {listOfImage.current.map((image, index) => (
              <Carousel.Item
                key={index}
                style={{
                  maxHeight: "180px",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
          
                <img
                  src={`${basefileURL}/FacilityImages/${image?.fileName}`}
                  alt={`Slide ${index}`}
                  className="w-100"
                  style={{
                    objectFit: "cover",
                    height: "180px",
                   
                  }}
                />
      
              </Carousel.Item>
            ))}
          </Carousel>
        )}

        {img && <QRCodeModal show={img} onHide={() => setImg(false)} />}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Navbar;
