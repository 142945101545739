import React, { useState, useEffect } from "react";
import { useFormValidation } from "../Validations/useFormValidations";
import hide from "../Image/hide.png";
import view from "../Image/view.png";
import Logo from "../Image/logo-c0d9bcbe312d8af35fbe10e083e8d653.png";
import { useNavigate } from "react-router";
import { notify, save } from "../ConfigFiles/SharedFunctions";
import { urls } from "../ConfigFiles/Urls";

const AdminLogIn = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);

  const changingPasswordtype = () => {
    setPassword(!password);
  };

  const submit = async () => {
    let jsonObjects = data;
    let res = await save(urls?.signup?.adminLogin, { jsonObjects });
    sessionStorage.setItem("facility", res?.data?.data?.facilityId);
    sessionStorage.setItem("firstName", res?.data?.data?.firstName);
    sessionStorage.setItem("lastName", res?.data?.data?.lastName);
    sessionStorage.setItem("email", res?.data?.data?.email);
    sessionStorage.setItem("phone", res?.data?.data?.phone);
    sessionStorage.setItem("zipCode", res?.data?.data?.zipCode);
    sessionStorage.setItem("roleId", res?.data?.data?.roleId);
    sessionStorage.setItem("facilityName", res?.data?.data?.facilityName);
    sessionStorage.setItem("roleName", res?.data?.data?.roleName);
    sessionStorage.setItem("userId", res?.data?.data?.userId);
    sessionStorage.setItem("roleId", res?.data?.data?.roleId);
    sessionStorage.setItem("facilityName", res?.data?.data?.facilityName);
    sessionStorage.setItem("token", res?.data?.token);

    if (res.data?.status == true) {
      navigate("/admin/all/patients");
    }
  };

  useEffect(() => {
    sessionStorage?.clear();
  }, []);
  const { data, errors, handleChange, handleSubmit, setV } = useFormValidation({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: {
      email: {
        required: {
          value: true,
          message: "Please enter your email id",
        },
        pattern: {
          // value:
          //   /(?=(.{1,64}@.{1,255}))((?!.*?[._]{2})[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}(?<!\.)){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|((?!-)(?=.*[a-zA-Z])((?:.*[a-zA-Z0-9]))[a-zA-Z0-9-]{0,63}(?<!-)(\.(?!-)[a-zA-Z0-9-]{1,63}(?<!-)){1,}))$/,
          value:/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Please enter a valid email id",
        },
      },
      password: {
        required: {
          value: true,
          message: "Please enter your password",
        },
      },
    },
    submit: submit,
  });
  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "login-input1 bg-white border border-danger"
      : "login-input1 border bg-white ";
  };
  return (
    <div className="login-containeradmin">
      <div className="login-boxadmin bg-white  d-flex flex-column align-items-center justify-content-center">
        <div className="ms-4">
          <a href="/">
            <img src={Logo} alt="Logo" className="login-img" />
          </a>
        </div>

        <div className="centering p-3">
          <form onSubmit={handleSubmit} className="login-form login-formmobile">
            <div className=" main-header  mb-4">
              {props?.title}

              <div className="full-width-line mt-4 "></div>
            </div>
            <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <label className="login-label">
                Email<span className="text-danger">*</span>
              </label>
              <input
                className={emailErrorColor("email")}
                name="email"
                onChange={handleChange("email")}
                placeholder="Enter Email"
                id="floatingInput"
              />
            </div>

            <br />
            <div className="" style={{ marginBottom: "-10px" }}>
              <div className="form-floating col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label className="login-label password-label">
                  Password<span className="text-danger">*</span>
                </label>
                <input
                  type={password ? "password" : "text"}
                  className={emailErrorColor("password")}
                  name="password"
                  minlength="6"
                  onChange={handleChange("password")}
                  placeholder="Enter Password"
                  id="floatingInput"
                />

                <a
                  className="passwordshowbtn mx-2"
                  onClick={() => changingPasswordtype()}
                >
                  <img
                    src={password ? hide : view}
                    alt="hide"
                    className="password-toggle-icon"
                  />
                </a>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {/* <p className="">
                            <duv >
                                <a
                                onClick={() => {
                                  forgotPassword();
                                }}
                                className="text-danger pointer"
                                >
                                Forgot Password ?
                                </a>
                            </duv>
                            </p> */}
              </div>
            </div>
            <div className="d-flex   justify-content-center">
              <button
                className="login-btn  border border-info"
                type="submit"

                // onClick={() => {
                //     navigate("/b/allquestions");
                //   }}
              >
                Login
              </button>{" "}
              {/* &nbsp;&nbsp;&nbsp;
                            <div className="login-btn loginBack border border-info text-center">
                                Back
                            </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogIn;
