import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
const Story = () => {
  useEffect(()=>{
    if(sessionStorage.getItem("finalSubmit") == null&&sessionStorage.getItem("signup")==null){
      sessionStorage.removeItem("token")
      sessionStorage.removeItem("userId")
      sessionStorage.removeItem("allQuestion");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("data");
    }
  },[])
  return (
    <>
      <Container>
        <div className="row py-2 text-justify">
          <div className="col-md-1"></div>
          <div className="text-center col-md-10 col-sm-12">
            <div>
              <h5 className="text-info">What is Allistercare?</h5>
            </div>
            <p className="text-start text-justify">
              Allistercare is a new concept in healthcare. More and more people
              are taking an active role in their health, and using Allistercare
              to do it. Allistercare is an automated, intelligent,
              computer-based tool to be used by anyone, anytime, anywhere,
              including physicians, to ensure quality cancer screening.
            </p>
            <p className="text-start text-justify">
              There are multiple organizations that publish cancer screening
              guidelines. These multiple guidelines contain differences and are
              difficult to understand. Using them can be complex and confusing
              even for health care providers. Allistercare has embedded trusted
              guidelines into interactive computer based modules. Within a few
              minutes you can confirm if, and when a screening intervention
              would be recommended by most physicians. Guidelines are general.
              Allistercare makes them personal. Doctors want what is best for
              their patients, and Allistercare is one way to help your doctor
              ensure you are not overlooked regarding health screening.
            </p>
            <div>
              <h5 className="text-info">Who should use Allistercare?</h5>
            </div>
            <p className="text-start text-justify">
              Everyone. The very healthy... and the not so healthy. Those who
              see their doctors regularly... and those who avoid going to the
              doctor at all costs. Use it before you schedule an appointment
              with a doctor and you’ll be prepared to ask all the right
              questions. Update your profile after a doctor visit so you never
              miss a screening in the future. Give your physician access to your
              profile through Allistercare.
            </p>
            <div>
              <h5 className="text-info"> How do I use Allistercare?</h5>
            </div>
            <p className="text-start text-justify">
              Our automated interview is a quiz about you! Your answers to the
              questions will guide an intelligent recommendation of when to
              schedule your next screening exam. Use Allistercare to connect to
              local providers of cancer screening services. Learn more about
              cancer and screening on this website. Learn what are the important
              factors that your doctor considers when recommending a screening
              test. Allistercare facilitates better communication between you
              and your doctor. Allistercare is ready when you are. Use it in the
              doctor’s office, or at home. No appointments needed. No co-pays.
              No parking fees. You will need an internet connection and a
              browser like Safari, Chrome or Firefox.
            </p>
            <div>
              <h5 className="text-info">Why does Allistercare exist?</h5>
            </div>

            <p className="text-start text-justify">
              Allistercare is for people who are not afraid to participate in
              their own healthcare decision making. It is for people who want to
              help their doctor ensure that cancer screening is being done, and
              done efficiently. Quality care does not mean more care. It means
              better care. Smarter care. Allistercare is designed to decrease
              overutilization of screening services. The main goal of
              Allistercare is to prevent cancers. Allistercare... saving lives,
              one engaged patient at a time.
            </p>
            <div>
              <h5 className="text-info">
                What are the features of Allistercare?
              </h5>
            </div>

            <p className="text-start text-justify ">
              - Automated, personalized, intelligent cancer screening
              recommendations based on nationally recognized guidelines
            </p>
            <p className="text-start text-justify">
              - Connects people to their local health care services
            </p>
            <p className="text-start text-justify">
              - Education<br></br>
              <p className="ms-4">
                {" "}
                Learn about cancer, and cancer screening<br></br>
              </p>
              <p className="ms-4">
                Learn about tests like colonoscopy<br></br>
              </p>
              <p className="ms-4">
                {" "}
                Learn what to ask your doctor about your colonoscopy.
              </p>
            </p>
            <p className="text-start text-justify">
              - Comparison of different colonoscopy preps (costs, instructions,
              etc)
            </p>
            <p className="text-start text-justify">
              - Automated email reminders about your cancer screening
            </p>
            <p className="text-start text-justify">
              - Organized information to help your physician with your care
            </p>
            <p className="text-start text-justify">
              - Convenience, accessible anytime
            </p>
          </div>
          
          
        </div>
      </Container>
    </>
  );
};

export default Story;
