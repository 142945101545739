import { lazy, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Authentication from "./PrivetRouting/Authentication";


import Terms from "./LandingPage/Terms";

const AllQuestions = lazy(() => import("./AdminPage/AllQuestions"))
const QuestionaryList = lazy(() => import("./Questionnair/QuestionsList"))




const RoutingConfig = () => {
   
   
    return (
        <>
       
        <Routes>
        
           
            
       
           <Route path="newQuestinaries" element={<QuestionaryList />} />
            <Route path="allQuestionary" element={<Authentication><QuestionaryList /></Authentication> } />
            <Route path="terms" element={<Authentication><Terms/></Authentication> } />
            <Route path="allQuestionary/:facilityId/:screeningId" element={<Authentication><QuestionaryList /></Authentication> } />
            <Route path="allQuestionary/:facilityId" element={<Authentication><QuestionaryList type={"new"}/></Authentication> } />
            <Route path="allquestions" element={<Authentication><AllQuestions /></Authentication>} />
           
        </Routes>   
        </>
    )
}

export default RoutingConfig