
const Quiz = () => {
 
  const born = {
    question: "When were you born?",
    type: "Date1",
    name: "born",
    months:"months",
    childKeys:['evaluatedbyacolonoscopy',"formula1X","formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  const evaluatedbyacolonoscopy = {
    question:
      "Have you ever experienced any of these symptoms that have yet to be evaluated by a ",
    type: "CheckBox",
    subString: "colonoscopy?",
    name: "evaluatedbyacolonoscopy",
    toolTip:
      "a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    optionsToolTip: [
      {
        label:
          "difficult, incomplete, or infrequent evacuation of dry hardened feces from the bowels.",
      },
      {
        label:
          "acute or chronic, localized or diffuse pain in the abdominal cavity.",
      },
      { label: "evidence of rectal bleeding" },
      {
        label:
          "Your body weight can regularly fluctuate, but the persistent, unintentional loss of more than 5% of your weight over 6 to 12 months is usually a cause for concern.",
      },
      {
        label:
          " is diagnosed when a blood test shows a hemoglobin value of less than 13.5 gm/dl in a man or less than 12.0 gm/dl in a woman.",
      },
    ],
    option: [
      "Constipation",
      "Abdominal Pain",
      "Blood in stool, on toilet paper, and/or bowl?",
      "Weight loss",
      "Anemia",
      "None of the above"
    ],
    childKeys:['completecolonoscopy',"formula1X",,"formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  const completecolonoscopy =  {
    question: "Have you ever had a ",
    type: "Radio",
    subString: "complete colonoscopy?",
    toolTip:
      "a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    name: "completecolonoscopy",
    option: ["Yes", "No"],
    childKeys:['completecolonoscopyperformed',"colonorrectalcancerorpolyps","month1",,"formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  const completecolonoscopyperformed = {
    question: "When was your last  ",
    type: "Date",
    subString: "complete colonoscopy",
    subString2: " performed?",
    toolTip:
      "a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    name: "completecolonoscopyperformed",
    months:"month1",
    childKeys:['colonorrectalcancerorpolyps',,"formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  const colonorrectalcancerorpolyps = {
    question: "Have any of your blood relatives been diagnosed with ",
    type: "Radio",
    subString: "colon or rectal cancer or polyps?",
    toolTip: "cancer that starts in the colon or the rectum",
    name: "colonorrectalcancerorpolyps",
    option: ["Yes", "No"],
    childKeys:['polypsorcancerofthecolonorrectum','eitherpolypsorcancerofthecolonorrectum',"formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  // -----------------------------------------------------------------------------------------------------------------------------
  const polypsorcancerofthecolonorrectum = {
    //1A first Question
    question: "Do you have any ",
    type: "Radio",
    subString: "first-degree blood relatives",
    subString3:
      " who have been diagnosed with polyps or cancer of the colon or rectum?",
    toolTip:
      "a father, mother, brother, sister, son or daughter related to you by birth rather than marriage",
    name: "polypsorcancerofthecolonorrectum",
    option: ["Yes", "No", "Unsure"],
    childKeys:["morethanonefirstdegreebloodrelative", "leaveblank","orethanoneseconddegreebloodrelative",
    // -------------------------------------
    "eitherpolypsorcancerofthecolonorrectum","cancerremoved","cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease",,"formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  }
  const morethanonefirstdegreebloodrelative = {
    question: "Do you have more than one ",
    type: "Radio",
    subString: "first-degree blood relatives",
    subString4:
      " who has been diagnosed with polyps or cancer of the colon or rectum?",
    toolTip:
      "a father, mother, brother, sister, son or daughter related to you by birth rather than marriage",
    name: "morethanonefirstdegreebloodrelative",
    option: ["Yes", "No", "Unsure"],
    childKeys:["leaveblank","orethanoneseconddegreebloodrelative",
    // -------------------------------------
    "eitherpolypsorcancerofthecolonorrectum","cancerremoved","cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula1A","formula1B","formula1c","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['leaveblank',"formula1A","formula1B","formula1c",'eitherpolypsorcancerofthecolonorrectum']
    //1A Second Question Click [Yes] Option goto positive flow
  }
  const leaveblank = {
    question:
      "Enter age of relative when diagnosed. If more than one, enter the age of the youngest diagnosed. If unknown, leave blank.",
    type: "input",
    name: "leaveblank",
    childKeys:["orethanoneseconddegreebloodrelative",
    // -------------------------------------
    "eitherpolypsorcancerofthecolonorrectum","cancerremoved","cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['eitherpolypsorcancerofthecolonorrectum']
  }
  const orethanoneseconddegreebloodrelative = {
    question: "Do you have more than one  ",
    type: "Radio",
    subString: "second-degree blood relative",
    subString5:
      " who has been diagnosed with polyps or cancer of the colon or rectum?",
    toolTip:
      " a grandfather, grandmother, uncle, aunt, nephew, niece, or first cousin related to you by birth rather than marriage",
    name: "orethanoneseconddegreebloodrelative",
    option: ["Yes", "No", "Unsure"],
    childKeys:["eitherpolypsorcancerofthecolonorrectum","cancerremoved","cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['eitherpolypsorcancerofthecolonorrectum']
  }
  // ----------------------------------------------------------------------------------------------------------------------------------
  // const eitherpolypsorcancerofthecolonorrectum = {
  //   question:
  //     "Have you ever been diagnosed with either polyps or cancer of the colon or rectum?",
  //   type: "CheckBox",
  //   name: "eitherpolypsorcancerofthecolonorrectum",
  //   option: ["Polyps", "Cancer", "Neither"],
  //   childKeys:['cancerremoved','polypscompletelycompletelyremoved','diagnosedwithanyofthefollowingconditions',]
  // }
  const eitherpolypsorcancerofthecolonorrectum = {
    question:
      "Have you ever been diagnosed with either polyps or cancer of the colon or rectum?",
    type: "CheckBox",
    name: "eitherpolypsorcancerofthecolonorrectum",
    option: ["Polyps", "Cancer", "Neither"],
    childKeys:["cancerremoved","cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","month2",,"formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
  //   ['cancerremoved','polypscompletelycompletelyremoved','diagnosedwithanyofthefollowingconditions',
  //   "removethecancer","month2","duringoraftersurgery",'growthsortumorsatyourlastcompletecolonoscopy','cancerinvolvetherectum',
  //   "completecolonoscopyaftercancerremoval",'diagnosedwithanyofthefollowingconditions','finalsubmit',"UlcerativeColitisorChrohnsDisease",
  //   "formula4D","formula5A5","formula5B5","formula5C5","morethan10",'describeyourpolyps'
  // ]
  }
  const cancerremoved = {
    question: "Was the cancer removed?",
    type: "Radio",
    name: "cancerremoved",
    option: ["Yes", "No"],
    childKeys:["cancerremovedno","polypscompletelycompletelyremoved","removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","month2","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['removethecancer',"month2"]
  }
  const cancerremovedno = {
    question:
  " ",
    name: "cancerremovedno",
    type:"cancerremovedno"
  }
  const polypscompletelycompletelyremoved = {
    question: "Were all polyps completely removed?",
    type: "Radio",
    name: "polypscompletelycompletelyremoved",
    option: ["Yes", "No", "Unsure"],
    childKeys:["removethecancer",
    // ------------------------------------
    "duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['morethan10',"formula3C"]
  }
  const removethecancer = {
    question:
      "What was the date of your procedure to remove the cancer?",
    type: "Date",
    name: "removethecancer",
    months:"month2",
    childKeys:["duringoraftersurgery", "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3A","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['duringoraftersurgery',"formula3A"]
  }
  // ---------------------------------------------------------------------------------------------------------------------------------
  // Did you have a complete colonoscopy before, during or after surgery?
  const duringoraftersurgery = {
    question: "Did you have a  ",
    type: "Radio",
    subString: "complete colonoscopy",
    subString5:
      " before, during or after surgery?",
     toolTip:"a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    name: "duringoraftersurgery",
    option: ["Yes", "No"],
    childKeys:[ "growthsortumorsatyourlastcompletecolonoscopy","cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3B","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['growthsortumorsatyourlastcompletecolonoscopy','cancerinvolvetherectum',"formula3B",]
  }
  // Did you have any polyps, or any other abnormal growths or tumors at your last complete colonoscopy?
  const growthsortumorsatyourlastcompletecolonoscopy = {
    question: "Did you have any polyps, or any other abnormal growths or tumors at your last",
    type: "Radio",
    subString: "complete colonoscopy ?",
    toolTip:"a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    name: "growthsortumorsatyourlastcompletecolonoscopy",
    option: ["Yes", "No"],
    childKeys:["cancerinvolvetherectum","completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['cancerinvolvetherectum']
  }
  // Did your cancer involve the rectum?
  const cancerinvolvetherectum = {
    question: "Did your cancer involve the rectum?",
    type: "Radio",
    name: "cancerinvolvetherectum",
    option: ["Yes", "No"],
    childKeys:["completecolonoscopyaftercancerremoval",
    // ------------------------------------
    "morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3C","formula3D","formula5A","formula5A5","formula4D","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','completecolonoscopyaftercancerremoval',"formula3C","formula3D","formula5A5","formula5B5","formula5C5"]
  }
  // Have you had more than one normal complete colonoscopy after cancer removal?
  const completecolonoscopyaftercancerremoval = {
    question: "Have you had more than one normal",
    type: "Radio",
    subString: "complete colonoscopy    ",
    subString5:
      "     after cancer removal?",
    toolTip:"a medical procedure where a long, flexible, tubular instrument called the colonoscope is used to view the entire inner lining of the colon (large intestine) and the rectum.",
    name: "completecolonoscopyaftercancerremoval",
    option: ["Yes", "No"],
    childKeys:["morethan10","describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3C","formula3D","formula5A","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions',"formula5A5","formula5B5","formula5C5"]
  }
  // ----------------------------------------------------------------------------------------------------------------------------------
  // Were more than 10 adenomatous polyps found?
  const morethan10 = {
    question:
      "Were more than 10 adenomatous polyps found?",
    type: "Radio",
    option: ["Yes", "No","Unsure"],
    name: "morethan10",
    childKeys:["describeyourpolyps","adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3C","formula3D","formula5A","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','describeyourpolyps',"formula3C","formula5A5","formula5B5","formula5C5"]
  }
  const describeyourpolyps = {
    question:
      "Which statements describe your polyps?",
    type: "CheckBox",
    option: [
      "3 or more small (less than 1 cm) adenomatous polyps",
      "Any large polyp (over 1 cm)",
      "Any single villous adenoma",
      "None of the above",
      "I don't know what types or number of polyps were found",
    ],
    name: "describeyourpolyps",
    childKeys:["adenomatouspolypsfound","hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula3D","formula5A","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','adenomatouspolypsfound',"formula3D",,"formula5A5","formula5B5","formula5C5"]
  }
  const adenomatouspolypsfound = {
    question:
      "Were 1 or 2 small (less than 1 cm) adenomatous polyps found?",
    type: "Radio",
    option: ["Yes", "No","Unsure"],
    name: "adenomatouspolypsfound",
    childKeys:["hyperplasticpolyps","morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula5A","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','hyperplasticpolyps',"formula5A","formula5A5","formula5B5","formula5C5"]
  }
  const hyperplasticpolyps = {
    question:
      "Did you have 1-5 hyperplastic polyps?",
    type: "Radio",
    option: ["Yes", "No","Unsure"],
    name: "hyperplasticpolyps",
    childKeys:["morehyperplasticpolyps","removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','morehyperplasticpolyps',"formula5A5","formula5B5","formula5C5"]
  }
  const morehyperplasticpolyps = {
    question:
      "Did you have 5 or more hyperplastic polyps?",
    type: "Radio",
    option: ["Yes", "No"],
    name: "morehyperplasticpolyps",
    childKeys:["removalofasessileadenoma",
    // -------------------------------------
    "diagnosedwithanyofthefollowingconditions","UlcerativeColitisorChrohnsDisease","formula4D","formula5A5","formula5B5","formula5C5","formula5D5"]
    // ['diagnosedwithanyofthefollowingconditions','removalofasessileadenoma',"formula4D","formula5A5","formula5B5","formula5C5"]
  }
  const removalofasessileadenoma = {
    question:
      "Did you have partial or piecemeal removal of a sessile adenoma?",
    type: "Radio",
    name: "removalofasessileadenoma",
    option: ["Yes", "No"],
    childKeys:['diagnosedwithanyofthefollowingconditions',"UlcerativeColitisorChrohnsDisease","formula5A5","formula5B5","formula5C5","formula5D5"]
  }
  // ---------------------------------------------------------------------------------------------------------------------------------
  const diagnosedwithanyofthefollowingconditions = {
    question:
      "Have you been diagnosed with any of the following conditions?",
    type: "CheckBox",
    name: "diagnosedwithanyofthefollowingconditions",
    option: [
      "Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease",
      "Familial Adenomatous Polyposis Syndrome (FAP)",
      "Hereditary Nonpolyposis Colon Cancer (HNPCC)",
      "None of the above",
    ],
    optionsToolTip: [
      {
        label:
          " Diseases of chronic inflammation of the GI tract, this term includes Crohn’s disease and ulcerative colitis",
      },
      {
        label:
          "A genetic condition where patients will develop an enormous number of pre-cancerous polyps in the colon, rectum and other parts of the GI tract.",
      },
      {
        label:
          "The most common genetic condition related to hereditary colon cancer. Also associated with cancers of other organs",
      },
    ],
    childKeys:['finalsubmit',"formula5D5","UlcerativeColitisorChrohnsDisease"]

  }
  const  UlcerativeColitisorChrohnsDisease= {
    question:"How old were you when diagnosed with Inflammatory Bowel Disease, Ulcerative Colitis or Chrohn's Disease?",
    name: "UlcerativeColitisorChrohnsDisease",
    type:"input"
  }
  const finalsubmit = {
    question:
  " ",
    name: "finalsubmit",
    type:"finalsubmit"
  }
  return {
    born,
    evaluatedbyacolonoscopy,
    completecolonoscopy,
    completecolonoscopyperformed,
    colonorrectalcancerorpolyps,
    // ---------------------------------
    polypsorcancerofthecolonorrectum,
    morethanonefirstdegreebloodrelative,
    leaveblank,
    orethanoneseconddegreebloodrelative,
    // -------------------------------------
    eitherpolypsorcancerofthecolonorrectum,
    cancerremoved,
    cancerremovedno,
    polypscompletelycompletelyremoved,
    removethecancer,
    // ------------------------------------
    duringoraftersurgery,
    growthsortumorsatyourlastcompletecolonoscopy,
    cancerinvolvetherectum,
    completecolonoscopyaftercancerremoval,
    // ------------------------------------
    morethan10,
    describeyourpolyps,
    adenomatouspolypsfound,
    hyperplasticpolyps,
    morehyperplasticpolyps,
    removalofasessileadenoma,
    // -------------------------------------
    diagnosedwithanyofthefollowingconditions,
    UlcerativeColitisorChrohnsDisease,
    finalsubmit,
  }
}
export default Quiz