

import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { useFormValidation } from "../../Validations/useFormValidations";
import {getList, mulitplePhoneNumberValue,notify,save,} from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {  updateData } from "../../Redux/Action";
import moment from "moment";

 
const AdminPasswordUpdateModal = (props) => {

  const [confirmNewPassword, setConfirmPassword] = useState(true);

  const submit = async () => {
    data["userId"] = props?.show;
  let body = {
      userId: sessionStorage.getItem("roleId") == 1 || sessionStorage.getItem("roleId") == 3 ? sessionStorage.getItem("userId") : 0,
      password: data?.password,
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      phone: sessionStorage.getItem("phone"),
      zipCode: sessionStorage.getItem("zipode") !== null ?sessionStorage.getItem("zipode"):"",
      type:"changepassword"
    };
    let jsonObjects = body;
    if ( data?.password == data?.confirmNewPassword) {
    let res = await save(urls?.signup?.changePassword, { jsonObjects });

        if(res?.data?.status){
      props?.onHide();

        }
        
      
    }
    else {

      notify(false,"Password and Confirm Password should be same!!")
    }
   
  };

 


  const {data,errors,setValues,handleChange,handleSubmit,handleEmailChange}=useFormValidation({
    initialValues: {
       
      password: "",
      confirmNewPassword:"",
        notShowMsg:true
      },
      validationSchema: {
        
      
        confirmNewPassword: {
            required: {
              value: true,
              message: "Please enter confirm password",
            },
            
          },
          password: {
            required: {
              value: true,
              message: "Please enter new password",
            },
            minlength:{
              value:8,
              message: "Password should contain one uppercase,one lowercase, one special character, one number and minimum 8 characters.",
             
            }
            ,pattern:{
              value:/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/,
              message: "Password should contain one uppercase,one lowercase, one special character, one number and minimum 8 characters. ",

            }
           
          },
      },
    submit:submit
})

  console.log(data?.password == data?.confirmNewPassword,"utywrtyry",data?.password ,data?.confirmNewPassword)
  const returnErrorCss=(key)=>{
    return errors && errors?.[key]&&errors?.[key] ?<p className='text-danger'>{errors?.[key]}</p>:""
        }
 

  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "form-control bg-white border border-danger"
      : "form-control border bg-white ";
  };
  

  
  

 

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
     
      <Modal.Header    style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Password Details
        </Modal.Title>
       
        <div className="d-flex">
      
          <div className="pointer" onClick={props?.onHide}>
            <RxCross2 size={25} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form  onSubmit={handleSubmit}  >
         
          <div className="row m-1">
          
       

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <lable className for="password">
                    New  Password<span className="text-danger">*</span>
                    </lable>
                    <div className="icon-group">
                      <input
                        name="password"
                        id="password"
                        onChange={handleChange("password")}
                        placeholder="Password"
                        autoComplete="of"
                        type="text"
                        className={emailErrorColor("password")}
                      />
                    </div>
                    {returnErrorCss("password")}
                  </div>


                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <lable className for="confirmPassword">
                      Confirm Password<span className="text-danger">*</span>
                    </lable>
                    <div className="icon-group">
                      <input
                       autoComplete="of"
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        onChange={handleChange("confirmNewPassword")}
                        placeholder="Confirm Password"
                        type={confirmNewPassword ? "password" : "text"}
                        className={emailErrorColor("confirmNewPassword")}
                      />

               
                    </div>
                    {returnErrorCss("confirmNewPassword")}
                  </div>
           
                  <span className='fornt-weight-bold'>Note</span>
     <p className='text-danger'>
     Password should contain one uppercase,one lowercase, one special character, one number and minimum 8 characters.
     </p>

            


          </div>
          <hr className="col-md-12 border" />
          <div className="d-flex justify-content-end">
          <button button className="float-right border border-white btn-save">
            Update
          </button>
          </div>
         
        
        </form>
        
      </Modal.Body>
    
  
    </Modal>
  );
};

export default AdminPasswordUpdateModal;
