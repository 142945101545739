import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from "react-icons/fa";

const Terms = () => {
  const [isChecked, setChecked] = useState(false);
 const navigate=useNavigate()
  // const goTop = () => {
  //   window?.scrollTo({ top: "0", behavior: "smooth" });
   
  // };

  // const goBottom1 = () => {
  //   window?.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
   
  // };
  const goto=()=>{
    sessionStorage.getItem("userId")&&sessionStorage.setItem("new", "addQuestion")
    sessionStorage.setItem("terms","Yes")
    navigate( sessionStorage?.getItem("userId")
    ? `/new_questionnaire`
    : `/allQuestionary `)
   
  }
  return (
    <div>
      <div class="container  font-bold  text-start p-0  col-10 border mt-1 text-justify ">
        <h4 className="text-center text-info  header-sticky  ">Terms</h4>
        
        <div
          className="p-4"
          style={{
            color: "#3e3a88",
          }}
        >
         
          <p>
            THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR
            RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS AND
            EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY.
          </p>
          <h3 className=" text-info">Introduction</h3>
          <p>
            These Terms of Use are entered into by and between you and Automated
            Intelligent Quality Health Solutions, LLC, an Alabama limited
            liability company (referred to in these Terms of Use as “we,” “us,”
            “our” or “Allister”). The following terms and conditions, together
            with any documents they expressly incorporate by reference
            (collectively, these “Terms of Use”), govern your access to and use
            of this website, including any content, functionality, and services
            delivered through the domain allistercare.com (collectively, the
            “Site”). By using or accessing the Site, registering with the Site,
            or by clicking to accept or agree to these Terms of Use when this
            option is made available to you, you signify your agreement to be
            bound by these Terms of Use and Allister’s Privacy Notice, which can
            be found at [URL for website privacy notice] and is incorporated
            herein by reference.
          </p>

          <h3 className="text-info"> Changes to These Terms of Use </h3>
          <p>
            We may modify these Terms of Use from time to time in our sole
            discretion. We will provide notice by, at a minimum, updating this
            posting. You are expected to carefully review these Terms of Use
            from time to time so you are aware of any changes. However, if we
            make material changes to these Terms of Use, we will notify you
            either through the email address you have provided us or by means of
            a prominent notice on the Site before the change becomes effective.
            You are responsible for ensuring we have an up-to-date active and
            deliverable email address for you.
          </p>

          <p>
            Any changes to these Terms of Use&nbsp;will be in effect
            as&nbsp;of&nbsp;the “Last Updated” date referenced in these Terms of
            Use. Your continued use of the Site after such changes become
            effective will constitute your binding acceptance to these Terms of
            Use, including any changes or modifications that we may make. If any
            part of these Terms of Use or any future changes to these Terms of
            Use are not acceptable to you, do not use or access the Site.
          </p>

          <p>
            We may also, in the future, offer new services and/or features
            through the Site. Such new features and/or services shall be subject
            to the terms and conditions of these Terms of Use.
          </p>

          <h3 className="text-info"> Eligibility </h3>

          <p class="font-bold">
            THIS SITE IS OFFERED AND AVAILABLE TO USERS WHO ARE 18 YEARS OF AGE
            OR OLDER.
          </p>
          <p class="font-bold">
            YOU MAY NOT USE, REGISTER WITH, OR OBTAIN SERVICES FROM THE SITE IF
            YOU (A) DO NOT AGREE TO THESE TERMS OF USE, (B) ARE NOT AT LEAST 18
            YEARS OF AGE, OR (C) ARE PROHIBITED FROM ACCESSING OR USING THE SITE
            OR ANY OF THE SITE'S CONTENTS OR SERVICES BY APPLICABLE LAW.
          </p>
          <p class="font-bold">
            IF YOU USE, REGISTER WITH, OR OBTAIN SERVICES FROM THE SITE ON
            BEHALF OF OTHER INDIVIDUALS, A COMPANY, GROUP, OR OTHER
            ORGANIZATION, YOU AFFIRM YOU HAVE THE LEGAL AUTHORITY TO BIND ANY
            SUCH INDIVIDUALS, COMPANY, GROUP, OR OTHER ORGANIZATION TO THESE
            TERMS OF USE AND THAT YOU WILL CONVEY THE CONTENTS OF THESE TERMS OF
            USE TO SUCH INDIVIDUALS, COMPANY, GROUP, OR OTHER ORGANIZATION.
          </p>

          <p class="font-bold">
            BY USING, REGISTERING WITH, OR OBTAINING SERVICES FROM THE SITE, YOU
            REPRESENT AND WARRANT THAT YOU MEET ALL OF THE FOREGOING ELIGIBILITY
            REQUIREMENTS AND YOU AFFIRM THAT YOU ACCEPT AND ARE BOUND BY THESE
            TERMS OF USE. IF YOU DO NOT MEET ALL OF THESE REQUIREMENTS, DO NOT
            USE OR ACCESS THE SITE.
          </p>
          <h3 className="text-info"> Informational Content </h3>
          <p>
            The information presented on or through the Site is made available
            solely for informational purposes or obtaining services. Allister
            uses reasonable efforts to update the information on the Site.
            However, the contents of the Site are subject to change without
            notice. By operating the Site, Allister does not represent or imply
            that it endorses the Site’s content. You are responsible for taking
            precautions as necessary to protect yourself and your computer
            systems from viruses, worms, Trojan horses, and other harmful or
            destructive content. Allister makes no representations or warranties
            as to the accuracy, reliability or completeness of any content on
            the Site. Any reliance you place on such information is strictly at
            your own risk. We disclaim all liability and responsibility arising
            from any reliance placed on such materials by you or any other
            visitor to the Site, or by anyone who may be informed of any of the
            Site’s content.
          </p>

          <p>
            This Site may include content provided by third parties. All
            statements and/or opinions expressed in these materials, and all
            content other than the content provided by Allister, are solely the
            opinions and the responsibility of the person or entity providing
            those materials. These materials do not necessarily reflect the
            opinion of Allister. We are not responsible, or liable to you or any
            third party, for the content or accuracy of any materials provided
            by any third parties.
          </p>

          <p>
            Please contact us at
            <a class="font-accent1" href="mailto:schewlett@yahoo.com">
              {" "}
              schewlett@yahoo.com{" "}
            </a>
            if you have questions about the information presented on the Site.
          </p>

          <h3 className="text-info"> Third Party Sites </h3>
          <p>
            This Site may contain links to other Internet sites that our
            business partners and other third parties own or operate. Your use
            of each of those sites is subject to the terms and conditions, if
            any, that each of those sites have posted. We have no control over
            third party sites and we are not responsible for any changes to or
            content on them. Our inclusion of any content is not an endorsement
            of that material or link or the companies that own or operate the
            material or linked sites.
          </p>

          <h3 className="text-info">
            {" "}
            Allister Does Not Provide Medical Advice{" "}
          </h3>

          <p>
            The Site’s content is not intended to be a substitute for
            professional medical advice, diagnosis, evaluation, or treatment.
            The Site’s content may include pre-recorded computer-generated video
            clips (“Video Content”). The Video Content is not live and you are
            not interacting with a live doctor. Access to or use of the Site, or
            communication within, from or to the Site, does not create a
            doctor-patient relationship. Always seek the advice of your
            physician or other qualified health provider with any questions you
            may have regarding a medical or health condition. Never disregard
            professional medical advice or delay in seeking it because of
            something you have read on the Site.
          </p>

          <h3 className="text-info"> Intellectual Property </h3>

          <p>
            These Terms of Use permit you to use the Site exclusively for your
            personal and other non-commercial use. The Site and all information
            and content contained therein is protected by contract law and
            various intellectual property laws, including domestic and
            international copyright laws. You must not reproduce, distribute,
            modify, create derivative works of, publicly display, publicly
            perform, republish, download, store or transmit any of the material
            on the Site, except that your computer or other device may
            temporarily store copies of such materials in RAM incidental to your
            accessing and viewing those materials, and you may store files that
            are automatically cached by your browser for display enhancement
            purposes. Subject to the restrictions set forth in these Terms of
            Use, and except for content specifically and expressly made
            available for redistribution, you may print or download information
            from the Site only for your personal and other non-commercial use,
            and not for further reproduction, publication or distribution,
            provided you keep intact all copyright and other proprietary
            notices.
          </p>
          <p>
            These Terms of Use do not grant you any right, title, interest,
            license (express or implied) to any patent, trademark, service mark,
            copyright, trade secret or other intellectual property right of
            Allister. Modification of any content on the Site is explicitly
            prohibited. You are also prohibited from utilizing this Site in any
            way that would damage its content or visibility for other visitors.
            As between Allister and you, Allister has and retains, as
            applicable, exclusive and valid ownership of the Site, the names and
            marks thereof, and all intellectual property, proprietary rights and
            documentation therein, and you acknowledge that the foregoing
            constitute valuable assets and may constitute trade secrets of
            Allister. Allister, and its associated logos, and all page headers,
            custom graphics, and other icons are service marks, trademarks,
            registered service marks, or registered trademarks of Allister. All
            other product names and company logos mentioned on the Site or in
            the information or content contained therein are trademarks of their
            respective owners. In addition to complying with all applicable
            laws, you agree that you will not use any such trademarks, service
            marks, trade dress, or other logos from this Site without the prior
            written authorization of Allister. You agree that you will not
            remove, alter or obscure any copyright, legal or proprietary notices
            in or on any portions of the Site or the information and content
            contained therein.
          </p>
          <p>
            Allister accommodates and does not interfere with standard technical
            measures used by copyright owners to protect their materials.
            Pursuant to 17 U.S.C. Section 512 as amended by Title II of the
            Digital Millennium Copyright Act (the "Act"), Allister reserves the
            right to terminate your use of the Site or the information and
            content contained therein if it determines in its sole and absolute
            discretion that you are involved in infringing activity, regardless
            of whether such alleged infringement is a first-time or repeat
            occurrence and/or whether the material or activity is ultimately
            determined to be infringing.
          </p>
          <p>
            If you print, copy, modify, download or otherwise use or provide any
            other person with access to any part of the Site in breach of these
            Terms of Use, your right to use the Site will cease immediately and
            you must, at our option, return or destroy any copies of the Site’s
            content you have made. Any use of the Site not expressly permitted
            by these Terms of Use is a breach of these Terms of Use and may
            violate copyright, trademark, and other laws. All rights not
            expressly granted herein are reserved to Allister.
          </p>
          <h3 className="text-info"> Prohibited Uses </h3>
          <p>
            You may use the Site only for lawful purposes and in accordance with
            these Terms of Use. Specifically, you agree:
          </p>

          <ul class="terms-ul">
            <li>
              not to use the Site in any way that violates federal, state, local
              or international law or regulation, or generally accepted
              practices or guidelines in relevant jurisdictions (including any
              laws or regulations regarding the export of data to and from the
              United States or other relevant countries);
            </li>
            <li>
              that you will not use the Site to transmit or send unsolicited
              commercial communications;
            </li>
            <li>
              not to access (or attempt to access) the Site by any means other
              than through the interface that is provided by Allister;
            </li>
            <li>
              that you will not attempt to gain unauthorized access to,
              interfere with, damage, disrupt or circumvent any of the security
              features of any part of the Site (or the servers, networks, and
              databases which are connected to the Site);
            </li>
            <li>
              not to access (or attempt to access) the Site through any
              automated means (including use of scripts or web crawlers);
            </li>
            <li>
              not to conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to the Site
              without Allister’s express written consent;
            </li>
            <li>
              not to introduce any spyware, viruses, Trojan horses, worms,
              keystroke loggers, rootkits, logic bombs or other material which
              is malicious or technologically harmful to the Site (or the
              servers, networks, and databases which are connected to the Site);
            </li>
            <li>
              not to use the Site to copy, store, host, transmit, send, use,
              publish or distribute any material which consists of (or is linked
              to) any spyware, virus, Trojan horse, worm, keystroke logger,
              rootkit or other malicious computer software;
            </li>
            <li>
              that you will not edit or otherwise modify any content on the Site
              that is not owned by you or another person or entity for whom you
              act as their agent;
            </li>
            <li>
              that you will not engage in any activity that interferes with or
              disrupts the Site (or the servers, networks, and databases which
              are connected to the Site);
            </li>
            <li>
              that you will not impersonate or attempt to impersonate Allister,
              a Allister employee, another user or any other person or entity
              (including, without limitation, by using email addresses
              associated with any of the foregoing);
            </li>
            <li>
              that you will not reproduce, redistribute, republish, duplicate,
              copy, display, sell, rent, sub-license, trade or resell any
              content or other aspect of the Site for any commercial purpose
              (except for content specifically and expressly made available for
              redistribution);
            </li>
            <li>
              that you are solely responsible for (and that Allister has no
              responsibility to you or to any third party for) any breach of
              your obligations under these Terms of Use and for the consequences
              (including any loss or damage which you may suffer) of any such
              breach.
            </li>
          </ul>
          <h3 className="text-info">
            {" "}
            Linking to the Site and Social Media Features{" "}
          </h3>
          <p>
            You may link to our homepage at www.allistercare.com, provided you
            do so in a way that is fair and legal and does not damage our
            reputation or take advantage of it, but you must not establish a
            link in such a way as to suggest any form of association, approval
            or endorsement on our part without our express written consent.
          </p>
          <p>
            This Site may provide certain social media features that enable you
            to:
          </p>
          <ul class="terms-ul">
            <li>
              Link from your own or certain third-party websites to certain
              content on this Site.
            </li>
            <li>
              Send e-mails or other communications with certain content, or
              links to certain content, on this Site.
            </li>
            <li>
              Cause limited portions of content on this Site to be displayed or
              appear to be displayed on your own or certain third-party
              websites.
            </li>
          </ul>
          <p>
            You may use these features solely as they are provided by us and
            solely with respect to the content they are displayed with. Subject
            to the foregoing, you must not:
          </p>
          <ul class="terms-ul">
            <li>Establish a link from any website that is not owned by you.</li>
            <li>
              Cause the Site or portions of it to be displayed, or appear to be
              displayed by, for example, framing, deep linking or in-line
              linking, on any other site.
            </li>
            <li>
              Link to any part of the Site other than the homepage at
              www.allistercare.com.
            </li>
            <li>
              Otherwise take any action with respect to the materials on the
              Site that is inconsistent with any other provision of these Terms
              of Use.
            </li>
          </ul>
          <p>
            You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to cease. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <p>
            We may disable all or any social media features and any links at any
            time without notice in our discretion.
          </p>
          <h3 className="text-info"> Fees and Purchase Terms </h3>
          <p>
            Certain features of the Site require payment[./ and, therefore,
            require setting up a billing account. You will be required to
            provide Allister with a valid payment card or other method of
            payment accepted by us when you set up your billing account within
            the Site (“Your Account”). By providing Allister with your payment
            card information and associated payment information, you agree to
            the terms, conditions, and privacy policies of the authorized
            third-party payment service providers, and you agree that Allister
            and its authorized third-party payment service providers are
            authorized to bill Your Account at the time any payments are due and
            to charge your designated payment card account. If any charges are
            subject to sales tax in the applicable jurisdiction, you also agree
            to pay the required sales tax and any related penalties or interest.
            You are responsible for all fees and charges associated with Your
            Account.] [Online payment for such features are made using a
            third-party payment service provider (“Payment Service Provider”).
            You may only use the acceptable payment methods listed on the
            Payment Service Provider’s website. By making such online payments,
            you agree to the terms, conditions, and privacy policies of the
            Payment Service Provider. When you submit a payment using the
            Payment Service Provider, you are authorizing the Payment Service
            Provider to process your payment and complete the transaction. You
            are responsible for confirming the accuracy of the information
            provided with such payment.]
          </p>
          <p>
            [If you use a payment card, y/Y]ou represent and warrant that (i)
            the payment card information you supply is true, correct and
            complete, (ii) you are duly authorized to use such payment card for
            the purchase, (iii) charges incurred by you will be honored by your
            payment card company, and (iv) you will pay charges incurred by you
            at the posted prices, including all applicable taxes, if any.
          </p>
          <p>
            All prices posted on the Site are subject to change without notice.
          </p>

          <p>
            You must promptly review all charges and immediately notify Allister
            if you have any questions, concerns, or disputes with respect to
            Allister's charges. We are not responsible for any errors by
            third-party payment service providers. [The applicable third-party
            payment service provider is solely responsible for processing any
            refunds./To request a refund of all or a portion of any payments you
            make to Allister, you must email [Insert Email Address]. Refunds are
            processed within approximately [9] business days of our receipt of
            your refund request. Your refund will be credited back to the same
            payment method used to make the original payment.] You agree that
            Allister will not be liable for any reversals, chargebacks, claims,
            fees, fines or penalties related to payments made using a
            third-party payment service provider.
          </p>

          <h3 className="text-info"> Risk Allocation </h3>
          <h4 className="text-info"> No Warranties </h4>
          <p>
            USE OF THE SITE, ITS CONTENT, AND ANY SERVICES OR OTHER ITEMS
            OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT
            AND ANY ADVICE, SERVICES, OR OTHER ITEMS OBTAINED THROUGH THE SITE
            ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR
            OTHERWISE, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR
            IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
            PURPOSE. NEITHER Allister NOR ANY PERSON ASSOCIATED WITH Allister
            MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
            COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
            AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER
            Allister NOR ANYONE ASSOCIATED WITH Allister REPRESENTS OR WARRANTS
            THAT THE SITE, ITS CONTENT OR ANY ADVICE, SERVICES OR OTHER ITEMS
            OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SITE OR THE
            SERVER(S) THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS OR THAT THE SITE OR ANY ADVICE, SERVICES, OR
            OTHER ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS
            OR EXPECTATIONS OR CONSTITUTE AN ACCURATE DIAGNOSIS OF ANY MEDICAL
            ISSUE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <h4 className="text-info"> Limitation of Liability </h4>
          <p>
            IN NO EVENT WILL Allister, ITS PARENTS, SUBSIDIARIES, AFFILIATES,
            RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            REPRESENTATIVES, PARTNERS, SERVICE PROVIDERS, OR LICENSORS BE LIABLE
            FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR
            IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY
            WEBSITES LINKED TO THE SITE, ANY CONTENT ON THE SITE OR SUCH OTHER
            WEBSITES, OR ANY SERVICES OR OTHER ITEMS OBTAINED THROUGH THE SITE
            OR SUCH OTHER WEBSITES, INCLUDING, WITHOUT LIMITATION, ANY DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
            INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY/WRONGFUL DEATH, PAIN
            AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
            LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
            GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE OR
            IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <h3 className="text-info">
            {" "}
            Modifications, Interruption of Service, and Account Security{" "}
          </h3>
          <p>
            Allister reserves the right to modify or discontinue this Site with
            or without notice to you. Allister shall not be liable to you or any
            third party should Allister exercise its right to modify or
            discontinue the Site. We do not guarantee continuous, uninterrupted
            or secure access to our Site. The operation of our Site may be
            interfered with or adversely affected by numerous factors or
            circumstances outside of our control or through acts of God.
          </p>
          <p>
            To access the Site or some of the resources it offers, you may be
            asked to provide certain registration details or other information.
            It is a condition of your use of the Site that all the information
            you provide on the Site is correct, current and complete. You agree
            that all information you provide to register with this Site or
            otherwise is governed by our Privacy Notice.
          </p>
          <p>
            Access to certain areas of the Site is restricted. Allister reserves
            the right to restrict access to other areas of the Site, or the
            entire Site, at Allister's sole discretion. If you choose, or are
            provided with, a user name, password or any other piece of
            information to enable you to access restricted areas of the Site or
            as part of our security procedures, you must treat such information
            as confidential, and you must not disclose it to any other person or
            entity. You also acknowledge that your account is personal to you
            and agree not to provide any other person with access to the Site or
            portions of the Site using your user name, password or other
            security information. You agree to notify us immediately of any
            unauthorized access to or use of your user name or password or any
            other breach of security. We have the right to disable any user
            name, password or other identifier, whether chosen by you or
            provided by us, at any time in our sole discretion for any or no
            reason without notice or explanation, including if, in our opinion
            you have violated any provision of these Terms of Use.
          </p>
          <h3 className="text-info"> User Content </h3>
          <p>
            In these Terms of Use, your "User Content" means material (including
            without limitation text, images, audio material, video material and
            audio-visual material) that you submit to the Site for any purpose.
            All personal information you submit to the Site is governed by our
            Privacy Notice. To the extent there is an inconsistency between
            these Terms of Use and the Privacy Notice regarding the personal
            information you submit to the Site, the Privacy Notice shall govern.
          </p>
          <p>
            Any User Content you submit, directly or indirectly, to the Site or
            to Allister will be considered non-confidential and non-proprietary.
            By providing any User Content on the Site, you grant to Allister and
            our licensees, successors, and assigns a worldwide, irrevocable,
            non-exclusive, royalty-free license to use, reproduce, modify,
            perform, display, adapt, publish, translate, distribute and
            otherwise disclose your User Content to third parties and in any
            existing or future media. You also grant to Allister the right to
            sub-license these rights, and the right to bring an action for
            infringement of these rights.
          </p>
          <p>
            You represent and warrant that (i) you own or control all rights in
            and to the User Content and have the right to grant the license
            granted above to us and our licensees, successors and assigns, and
            (ii) all of your User Content does and will comply with these Terms
            of Use.
          </p>
          <p>
            Your User Content must not be illegal or unlawful, must not infringe
            any third party's legal rights, and must not be capable of giving
            rise to legal action whether against you or Allister or a third
            party (in each case under any applicable law). You must not submit
            any User Content to the Site that is or has ever been the subject of
            any threatened or actual legal proceedings or other similar
            complaint.
          </p>
          <p>
            You understand and acknowledge that you are responsible for any User
            Content you submit or contribute, and you, not Allister, have fully
            responsibility for such content, including its legality,
            reliability, accuracy and appropriateness. We are not responsible,
            or liable to any third-party, for the content or accuracy of any
            User Content posted by you or any other user of the Site.
          </p>
          <p>
            Allister reserves the right to edit or remove any material submitted
            to the Site, or stored on Allister's servers, or hosted or published
            on the Site. Notwithstanding the foregoing, Allister does not
            undertake to monitor the submission of User Content to, or the
            publication of User Content on, the Site.
          </p>
          <h3 className="text-info">
            {" "}
            Information About You and Your Visits to the Website{" "}
          </h3>
          <p>
            {" "}
            All information we collect on this Site is subject to our Privacy
            Notice.{" "}
          </p>
          <h3 className="text-info"> Geographic Restrictions </h3>
          <p>
            Allister makes no representations that the information on the Site
            is appropriate or available for use in all locations. Use of or
            access to the Site may not be legal by certain persons or in certain
            countries. By using or accessing this Site, you do so on your own
            initiative and are responsible for compliance with applicable local
            laws.
          </p>
          <p>
            By using or accessing the Site, you consent to the transmission of
            your personal information, including your name, email address, zip
            code, user id, password, and any other personal or non-personal
            information that you may provide to use or that we may collect from
            you, to the United States, and to the extent permitted by applicable
            law, you accept all liability and responsibility arising from the
            transmission of such information to the United States.
          </p>
          <h3 className="text-info"> General Representation and Warranty </h3>

          <p>
            You represent and warrant that (i) your use of the Site will be in
            strict accordance with our Privacy Notice, these Terms of Use, and
            with all applicable laws and regulations (including without
            limitation any local laws or regulations in your country, state,
            city, or other governmental area, regarding online conduct and
            acceptable content, and including all applicable laws regarding the
            transmission of technical data exported from the United States or
            the country in which you reside), and (ii) your use of the Site will
            not infringe or misappropriate the intellectual property rights of
            any third party.
          </p>
          <h3 className="text-info"> Indemnification </h3>
          <p>
            You agree to defend, indemnify and hold harmless Allister and its
            affiliates, and their respective officers, directors, employees,
            agents, representatives, partners, service providers, licensors, and
            successors and assigns from and against any claims, liabilities,
            damages, judgments, awards, losses, costs, expenses or fees
            (including reasonable attorneys’ fees) arising out of or relating to
            your violation of these Terms of Use or your use of the Site,
            including, but not limited to, any use of the Site’s content or
            services other than as expressly authorized in these Terms of Use or
            your use of any information obtained from the Site.
          </p>
          <h3 className="text-info"> Termination and Survival </h3>
          <p>
            Allister may terminate your access to all or any part of the Site at
            any time, with or without cause, with or without notice, effective
            immediately. All provisions of these Terms of Use, which by their
            nature should survive termination, shall survive termination,
            including, without limitation, ownership provisions, warranty
            disclaimers, indemnity and limitations of liability.
          </p>
          <h3 className="text-info"> General </h3>
          <h4 className="text-info"> Governing Law </h4>
          <p>
            These Terms of Use and all matters arising from it are governed by
            and construed in accordance with the laws of the State of Alabama,
            exclusive of its choice of law rules. Nothing in these Terms of Use
            limits either party's ability to seek equitable relief. Allister
            shall be entitled to reasonable attorneys’ fees and costs in
            connection with enforcing any provision of these Terms of Use.
          </p>
          <h4 className="text-info"> No Waivers </h4>
          <p>
            No waiver by Allister of any term or condition set forth in these
            Terms of Use shall be deemed a further or continuing waiver of such
            term or condition, and any failure by Allister to assert a right or
            provision under these Terms of Use shall not constitute a waiver of
            such right or provision.
          </p>
          <h4 className="text-info"> Assignment </h4>
          <p>
            You may assign any of your rights or delegate any of your
            obligations under these Terms of Use&nbsp;to any party that consents
            to, and agrees to be bound by, the terms and conditions of these
            Terms of Use. Allister may assign its rights or delegate its
            obligations under these Terms of Use without condition. These Terms
            of Use will be binding upon and will inure to the benefit of the
            parties, their successors and permitted assigns. No assignment or
            delegation relieves you of any of your obligations under these Terms
            of Use.
          </p>
          <h4 className="text-info"> Notices </h4>
          <p>
            We may provide any notice to you under these&nbsp;Terms of
            Use&nbsp;by: (i) sending a message to the email address you provide
            or (ii) by posting to the Site. Notices sent by email will be
            effective when we send the email&nbsp;and&nbsp;notices we provide by
            posting will be effective upon posting. It is your responsibility to
            keep your email address current. To give us notice under
            these&nbsp;Terms of Use, you must contact us by sending a message to
          </p>
          <h4 className="text-info"> Entire Agreement </h4>
          <p>
            These Terms of Use, together with any documents expressly referred
            to in them, constitute the entire agreement between you and Allister
            with respect to the Site, and supersedes all previous written or
            oral agreements.
          </p>
          <h4 className="text-info"> Reformation/Blue-Pencil </h4>
          <p>
            If any part of these Terms of Use is determined to be invalid or
            unenforceable pursuant to applicable law, then the invalid or
            unenforceable provision will be deemed superseded by a valid,
            enforceable provision that most closely matches the intent of the
            original provision and the remainder of the Terms of Use shall
            continue in effect.
          </p>
          <h4 className="text-info">
            {" "}
            Limitations or Exclusions Not Applicable{" "}
          </h4>
          <p>
            Some states do not allow exclusion of implied warranties or
            limitation of liability for incidental or consequential damages, so
            the above limitations or exclusions may not apply to you. In such
            states, our liability and that of our third party content providers
            and their respective agents shall be limited to the greatest extent
            permitted by law.
          </p>
          <h3 className="text-info"> Contact Information </h3>
          <p>
            All feedback, comments, requests for technical support and other
            communications relating to the Site should be directed to Allister
            at
            <a class="font-accent1" href="mailto:schewlett@yahoo.com">
              {" "}
              schewlett@yahoo.com.{" "}
            </a>
          </p>
          <h3 className="text-info"> EMERGENCY </h3>
          <p class="font-bold">
            IF YOU BELIEVE YOU HAVE A MEDICAL EMERGENCY, CALL
            <a class="font-accent1" href="tel:911">
              {" "}
              911{" "}
            </a>
            IMMEDIATELY AND SEEK APPROPRIATE PROFESSIONAL MEDICAL TREATMENT.
          </p>

          {/* <div className="form-check d-flex justify-content-between gap-5">
          <input
            type="checkbox"
            className="form-check-input mt-0"
            id="termsCheckbox"
            checked={isChecked}
            onChange={() => setChecked(!isChecked)}
          />
          <label className="form-check-label mt-0" htmlFor="termsCheckbox">
            I agree to the terms
          </label>

          {isChecked && (
            <NavLink to={sessionStorage?.getItem("guestUserId")?`/allQuestionary`:`/allQuestionary/${facilityId}`} className="justify-content-center  btn-save">
              Continue
            </NavLink>
          )}
        </div> */}
         
          <div className="form-check d-flex justify-content-start gap-5">
            <input
              type="checkbox"
              className="form-check-input mt-1"
              id="termsCheckbox"
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
            />
            <label className="form-check-label mt-0" htmlFor="termsCheckbox">
              I agree to the terms
            </label>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-4">
            {isChecked && (
              <button 
                onClick={ goto}
                className="btn-save border"
              >
                Continue
              </button >
            )}
          </div>
       
        </div>
       
      </div>
    </div>
  );
};

export default Terms;
