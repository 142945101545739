import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import { useFormValidation } from "../../Validations/useFormValidations";
import { BiSend } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { urls } from "../../ConfigFiles/Urls";
import { chatServices, getList, notify, countmsg, disableFun } from "../../ConfigFiles/SharedFunctions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectedUserId, updateMessages } from "../../Redux/Action";
import MobileChat from "../../SharedComponents/MobileChat";
const Chat = () => {
  const dispatch = useDispatch();
  let messages = useSelector((state) => state.messages);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [chatUsers, setChatUsers] = useState([])
  const [filterAll, setFilterAll] = useState([]);
 
  const chatContainerRef = useRef(null);

  const [localTime, setLocalTime] = useState(new Date());
  const dateTime=useRef()
  dateTime.current=localTime
  useEffect(() => {
    const interval = setInterval(() => {
      setLocalTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  
  const ChatUsers = async () => {
    let jsonObjects = {
      facilityId: sessionStorage?.getItem("facility"),
      roleId: sessionStorage?.getItem("roleId")
    }
    let res = await getList(urls?.chat?.getChatUsers, {
      jsonObjects,
    });
    setChatUsers(res?.data?.data)
    setFilterAll(res?.data?.data)
  }
  const getchatMessageCount = async (id) => {
    let jsonObjects = { fromUser: id, isRead: "Yes" };
    let res = await chatServices(urls?.chat?.messageCount, jsonObjects);
    if (res.data?.status == true) {
      const updatedChat = [
        ...messages?.map((e) => ({
          ...e,
          isRead: `${id == e?.fromUser &&
            sessionStorage.getItem("userId") == e?.toUser
            ? "Yes"
            : e?.isRead
            }`,
        })),
      ];
      dispatch(updateMessages(updatedChat));
    }
   
  };

  const sentMessage = async () => {
    let jsonObjects = {
      chatDescription: data?.message,
      fromUser: sessionStorage.getItem("userId"),
      toUser: userId,
      createdDate:moment(localTime).format("MM/DD/YYYY hh:mm A")
    };
    
    if (userId && userId != undefined) {
      try {
        if (data?.message != undefined && data?.message != "") {
          let res = await chatServices(urls?.chat?.saveChat, jsonObjects);
          if (res?.data?.status == true) {
            const updatedChat = [
              ...messages?.map((e) => ({
                ...e,
                isRead: `${userId == e?.fromUser &&
                  sessionStorage.getItem("userId") == e?.toUser
                  ? "Yes"
                  : e?.isRead
                  }`,
              })),
            ];
            updatedChat.push(jsonObjects);
            dispatch(updateMessages(updatedChat));
            setV({ message: "" });
          }
        } else {
          return [];
        }
      } catch (e) {
        return [];
      }
    } else {
     
      const roleId = sessionStorage.getItem("roleId");
      if (roleId === "2") {
        notify(false, "Please select a facility admin");
      } else if (roleId === "3") {
        notify(false, "Please select a patient");
      } else {
        notify(false, "Please select patient or facility admin");
      }
    }
  }
 
  const { data, handleChange, handleSubmit, setV } = useFormValidation({
    
    submit: sentMessage
  });
  const selectClient = (value) => {
    setUserName(value?.userName);
    setUserId(value?.userId);
   
  };
  const cssToggole = (name) => {
    return userId == name
      ? "d-flex gap-4 mt-1 rounded p-1 pointer border btn-chat "
      : "d-flex gap-4 mt-1 rounded p-1 pointer border ";
  };
  useEffect(() => {
    if (search != "") {
      let res = filterAll?.filter(
        (v) =>
          v.userName?.toLowerCase().includes(search.toLowerCase())
      );
      setChatUsers(res);
    } else {
      setChatUsers(filterAll);
    }
  }, [search]);



  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    ChatUsers()
  }, [])
  useEffect(() => {
    if (userId > 0) {
      getchatMessageCount(userId)
    }
  }, [userId])
  const headerData = () => {
    let firstName = sessionStorage.getItem("firstName");
    let lastName = sessionStorage.getItem("lastName");
    const fullName = firstName + " " + lastName;
    const roleName = sessionStorage.getItem("roleName");
    return (
      <>
        <div>
          <FaRegUserCircle className="spinprofile me-1" size={25} />
          {firstName != "undefined" && firstName != null && (
            <span>{fullName}</span>
          )}
          {roleName != "undefined" && roleName != null && (
            <span className="font-weight-bold mx-1">({roleName})</span>
          )}
        </div>
      </>
    );
  };
  const sortedArray = messages?.filter((a) =>a.isRead === "No" )
 
  let orders= sortedArray.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
 const listids=orders?.map((v)=>{
    
    return v?.fromUser
  })
  return (
    <div>
      
      <div className="bg-light rounded img_login ">
        {!disableFun("2")&&<div className="row col-xl-12 p-2 align-items-center" style={{ marginRight: "20px" }}>
          <div className="col-xl-8 col-lg-7 col-md-5 font-weight-bold mt-2" style={{ textAlign: "start" }}>
            {sessionStorage.getItem("roleId") != 2 ? "Patients" : "Patient"}
          </div>
          <div className="col-md-7 col-xl-4 col-lg-5  mt-2 d-flex align-items-center justify-content-end">
            { headerData && headerData()}
          </div>
        </div>}
        <div className="px-2">
          <div className="pt-1">
            <div
              className="d-flex"
              style={{
                background: "#479FD0",
                height: "45px",
                paddingTop: "5px",
              }}
            >
              <h4 className="d-flex ms-2 text-white">Chat History- <h5 className="mt-1 ms-2">Click any name below to send a message</h5></h4>
            </div>
          </div>
          <div
            className="p-4 bg-white"
            style={{
              background: "#FFF",
              borderRadius: "10px",
              height: "600px",
            }}
          >
            <div className="row ">
              <div className="col-md-4  ">
                <div
                  style={{ height: "550px", overflowY: "scroll", }}
                >
                  <input
                    type="search"
                    className="col-md-12   form-control bg-white position-sticky fixed-top bg-white " style={{ zIndex: "10" }}
                    placeholder={disableFun("3") ? "Search Patients" : "Search Facility"}
                    onChange={(e) => setSearch(e.target?.value)}
                  />
                  {chatUsers&&chatUsers?.sort((a,b)=>{
      if (listids?.includes(String(a?.userId)) && !listids?.includes(String(b?.userId)) ) {
        
        return -1;
      }
    return 0;
     })?.map((v) => {
                    return (
                      <div
                        className={cssToggole(v?.userId)}
                        onClick={() => selectClient(v)}
                      >
                        <div className="col-md-11 d-flex align-items-center pointer ">
                          <span style={{ fontWeight: "600" }}>
                         { v?.userName}
                          </span>
                          {countmsg(messages, v?.userId) && <span className="bg-success text-white rounded-circle d-flex align-items-center justify-content-center" style={{ width: "25px", height: "25px", marginLeft: "5px" }}>
                            <span style={{ fontSize: "10px" }}>{countmsg(messages, v?.userId)}</span>
                          </span>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {userName && userName != ""&&<div className="col-md-8 p-0 rounded" style={{ background: "#E7EAEA" }}>
                <div
                  className="d-flex rounded"
                  style={{
                    background: "#B1BCC4",
                    height: "45px",
                    paddingTop: "5px",
                  }}
                >
                  <span className="px-1 pt-1 font-weight-bold text-black">
                    <FaRegUserCircle className="spinprofile me-1" size={30} /> {userName && userName != "" ? userName : ""}
                  </span>
                </div>
                <ScrollToBottom className="" >
                  <div className=" d-flex" style={{ height: "460px", overflowY:"auto"}}>
                    <div className="col-md-12 ">
                      <div  ref={chatContainerRef}
                     
                        style={{ height: "460px", overflowY:"auto" }}
                        >
                        {userId && messages?.filter((v) =>
                          v?.fromUser == userId || v?.toUser == userId
                        )
                          ?.map((v, index) => {
                            return (
                              <>
                                <div
                                  className={` d-flex gap-2 m-1 ${v?.fromUser == userId
                                    ? "justify-content-start"
                                    : " justify-content-end "
                                    } `}
                                >
                                  <div
                                    key={index}
                                    className={
                                      sessionStorage.getItem("userId") !=
                                        v?.fromUser
                                        ? " border bg-white px-1 "
                                        : "btn-chat   px-1"
                                    }
                                    style={{
                                      borderRadius: "5px",
                                      wordWrap: "break-word",
                                      wordBreak: "break-all"
                                    }}
                                  >
                                    {v?.chatDescription != "" && v?.chatDescription}
                                    <span className="text-secondary " style={{ fontSize: "small", display: "block" }}>
                                      {moment(v?.createdDate).format("MM/DD/YYYY, hh:mm A")}


                                    </span>



                  
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </ScrollToBottom>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex   gap-2 px-3 pb-2">
                    <div
                      className={ "col-md-10 " }
                      style={{ color: "#C6CACA" }}
                    >
                      <input
                        className="form-control bg-white border py-3"
                        name="message"
                        placeholder="Message..."
                        value={data?.message}
                        onChange={handleChange("message")}
                      ></input>
                    </div>
                    {<button
                    disabled={data?.message?false:true}
                      className="chat-btn col-md-2 my-2 border pointer  border-info py-2 rounded chart-bg h-25"

                      style={{
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <BiSend size={20} className="" />
                      <span className="px-1">Send</span>
                    </button>}
                    
                  </div>
                </form>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <div className="menu">
      
      <MobileChat chatuser={chatUsers} submit={sentMessage} setSearch={setSearch} userId={userId} userName={userName} client={selectClient} setid={setUserId}/>
      </div>
    </div>
  );
};
export default Chat;