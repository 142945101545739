import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { useFormValidation } from "../../Validations/useFormValidations";
import {getList, mulitplePhoneNumberValue,save,} from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Urls";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {  updateData } from "../../Redux/Action";
import moment from "moment";

 
const PatientEditModal = (props) => {

const dispatch=useDispatch()

  const submit = async () => {
    data["userId"] = props?.show;

    let body = {
      userId: sessionStorage.getItem("userId"),
      
      roleId: sessionStorage.getItem("roleId"),
      facilityId: data.facilityId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      zipCode: data?.zipCode,
      email: data?.email,
      phone: data?.phone,
      type:"adminprofile"
    };
    let jsonObjects = body;
    let url=props?.type?urls?.signup?.changePassword:urls?.signup?.updateUserDetails
    let res = await save(url, { jsonObjects });
    dispatch(updateData(body))
    if (res?.data?.status) {
      sessionStorage.setItem("firstName",data?.firstName)
      sessionStorage.setItem("lastName",data?.lastName)
      sessionStorage.setItem("phone",data?.phone)
      sessionStorage.setItem("zipCode",data?.zipCode)
      props?.onHide();
     
      
    }
  };



  const {
    data,
    errors,
    handleSubmit,
    handlePhoneChange,
    handleCapitalChange,
    handleNumberChange,
    setV,
  } = useFormValidation({
    initialValues: {
     
      firstName:"",
      lastName:"",
    },
    validationSchema: {
      
      firstName: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
      lastName: {
        required: {
          value: true,
          message: "Please enter your firstName",
        },
      },
    },
    submit: submit,
  });
  const dataValue = (value) => {
    return value && value != "" ? value : "";
  };

  const emailErrorColor = (key) => {
    return errors && errors?.[key]
      ? "form-control bg-white border border-danger"
      : "form-control border bg-white ";
  };
  

  
  useEffect(() => {
   
     setV(props?.show)
   
  }, [props.show]);

 
useEffect(()=>{
if(!props.type){
  // getPatientsAnswersList()
}
},[props.show])
  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div id={"div"} style={{marginBottom: "1rem"}}>
      <Modal.Header    style={{ background: "#F7F7F7" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Update  Details
        </Modal.Title>
       
        <div className="d-flex">
        {/* <div className="float-right   mr-4"  >
              <IoPrintOutline title="Download" color="#000000" size={30} />
            </div> */}
          <div className="pointer" onClick={props?.onHide}>
            <RxCross2 size={25} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form  onSubmit={handleSubmit}  >
         
          <div className="row m-1">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label className="">First Name<span className="text-danger">*</span></label>
              <input
                className={emailErrorColor("firstName")}
                placeholder="First Name"
                name="firstName"
                value={dataValue(data?.firstName)}
                onChange={handleCapitalChange("firstName")}
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label className="">Last Name<span className="text-danger">*</span></label>
              <input
                className={emailErrorColor("lastName")}
                placeholder="Last Name"
                name="lastName"
                value={dataValue(data?.lastName)}
                onChange={handleCapitalChange("lastName")}
              />
            </div>
            {/* <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12">
              <label className="">
                Email<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="mail"
                className={emailErrorColor("email")}
                placeholder="Email"
                name="email"
                onChange={handleEmailChange("email")}
                value={dataValue(data?.email)}
              />
            </div> */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label className="">Phone</label>
              <br />
              <input
                type="text"
                className="form-control bg-white"
                placeholder="Phone"
               
                name="phone"
                onChange={handlePhoneChange("phone")}
                // value={dataValue(data?.phone)}
                value={mulitplePhoneNumberValue(data?.phone)}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <label className="">Zip</label>
              <br />
              <input
                typeof="text"
                onChange={handleNumberChange("zipCode")}
                name="zipCode"
                value={dataValue(data.zipCode)}
                type="text"
                maxLength={5}
                className="form-control bg-white"
                placeholder="Zip"
              />
            </div>


          </div>
          <hr className="col-md-12 border" />
          <div className="d-flex justify-content-end">
          <button button className="float-right border border-white btn-save">
            Save
          </button>
          </div>
         
        
        </form>
        
      </Modal.Body>
      </div>
  
    </Modal>
  );
};

export default PatientEditModal;
