import React, { useEffect, useState } from "react";
import ReactCustomeTable from "react-data-table-component";
import { customStyles } from "../ConfigFiles/SharedFunctions";
import moment from "moment";

const AllPatientsTable = ({
  dataTable,
  columns,
  filyterKeys,
  title,
  headerData,
  updatedHeader,
  message,
  newquestionnair,
}) => {
  const [filterValues, setFilterValues] = useState([]);
  const filterFunction = (fil) => {
    let f = fil?.toLowerCase()?.split(" ")?.join("") || [fil];
    setFilterValues(
      dataTable?.filter((e) => {
        return filyterKeys?.some((a) => {
          let v =
            e[a] != null
              ? e[a].toString()?.toLowerCase()?.split(" ")?.join("")
              : e[a];
          return v?.includes(f);
        });
      })
    );
  };

  useEffect(() => {
    setFilterValues(dataTable);
  }, [dataTable]);
  return (
    <div 
    style={{background:"#F7F7F7",overflowX: 'auto', maxWidth: '100%',}}
    >
      <ReactCustomeTable
      data={filterValues || []}
      columns={columns || []}
      customStyles={customStyles}
      fixedHeader
      fixedHeaderScrollHeight="auto"
      selectableRowsHighlight
      pagination
     
      className=" px-2"
      subHeader
      subHeaderComponent={
      
        <div
          className="row col-xl-12 col-lg-12 col-md-12 col-sm-12"
          style={{ marginRight: "20px" }}
        >
          <div
            className="col-xl-2 col-lg-2 col-md-2  col-sm-2 font-weight-bold mt-2 "
            style={{ textAlign: "start",fontWeight:"bold" }}
          >
            {title}

            
          </div>
        
          {sessionStorage.getItem("roleId") !== "2" &&
            (
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 font-small-bold mt-2">
                {message && message()}
              </div>
            )}
            {sessionStorage.getItem("roleId") === "2" && (
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 font-small-bold mt-2">
              {newquestionnair && newquestionnair()}
            </div>
          )}
       
          <div
            className="col-xl-4  col-lg-4 col-md-4 col-sm-12 "
            style={{ background: "#F7F7F7" }}
          >
            <div className="col-md-12">
              {sessionStorage.getItem("roleId") !== "2" && (
                <input
                  type="search"
                  onChange={(e) => {
                    filterFunction(e.target.value);
                  }}
                  className="form-control my-1 search-control search-bg"
                  id
                  placeholder="Search ..."
                  style={{
                    backgroundImage:
                      "url('https://tse2.mm.bing.net/th?id=OIP.4a0TA-wGQra-URSbFAHBzQHaHN&pid=Api&P=0')",
                    backgroundPosition: "5px center",
                    backgroundRepeat: "no-repeat",
                    paddingLeft: "35px",
                    backgroundSize: "15px",
                    backgroundColor: "#F5F9FD",
                  }}
                />
              )}
            </div>
          </div>

          <div className="col-md-4  mt-2 ">{headerData && headerData()}</div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            {updatedHeader && updatedHeader()}
          </div>
        </div>
      }
    />
    </div>
  );
};
export default AllPatientsTable;
